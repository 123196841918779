import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutenticaService } from './../../../../../servicos/autentica.service';
import { DadosService } from './../../../../../servicos/dados.service';
import { CamadaTexto } from './../../../../cliente/cliente.component';
import { Talhao, Voo, Fazenda } from './../../../../../modelos/modelos';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import pointOnFeature from '@turf/point-on-feature';
import centerOfMass from '@turf/center-of-mass';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';

@Component({
  selector: 'app-talhao-dialog',
  templateUrl: './talhao-dialog.component.html',
  styleUrls: ['./talhao-dialog.component.scss']
})
export class TalhaoDialogComponent implements OnInit {

  enviando = false;
  talhaoForm: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    descri: new FormControl(''),
    arquivo: new FormControl('', Validators.required)
  });
  talhoes: Talhao[];
  camadasTalhaoTexto: Record<string, CamadaTexto> = {};
  mapa: google.maps.Map;
  bounds = new google.maps.LatLngBounds();
  camadasTalhao: Record<string, google.maps.Polygon> = {};
  voos: Voo[];
  fazendaSelecionada: Fazenda;
  talhaoSelecionado: Talhao;

  constructor(@Inject(MAT_DIALOG_DATA) data: {fazendaSelecionada: Fazenda, talhaoSelecionado: Talhao, 
              talhoes: Talhao[], mapa: google.maps.Map, bounds: google.maps.LatLngBounds},
              private dialogRef: MatDialogRef<any>,
              private dadosService: DadosService, 
              private autenticaService: AutenticaService, 
              public dialog: MatDialog
             ) { 
              this.fazendaSelecionada = data.fazendaSelecionada
              this.talhaoSelecionado = data.talhaoSelecionado
              this.talhoes = data.talhoes
              this.mapa = data.mapa
              this.bounds = data.bounds
             }

  ngOnInit(): void {
  }

  criarTalhao() {
    if (this.talhaoForm.valid) {
      this.enviando = true;
      const arquivo = this.talhaoForm.get('arquivo').value.files[0];
      const leitor = new FileReader();
      let kml: Document;
      leitor.readAsText(arquivo);
      leitor.onloadend = () => {
        kml = new DOMParser().parseFromString(leitor.result.toString(), "text/xml");
        let coords = kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => new google.maps.LatLng(+t.split(',')[1], +t.split(',')[0]));
        let area = google.maps.geometry.spherical.computeArea(coords) / 10000;

        let novo = Object.assign({}, this.talhaoForm.value);
        this.dadosService.salvarArquivo('kmls/' + new Date().getTime() + '.kml', arquivo).then(url => {
          novo['arquivo'] = url;
          novo['area'] = area;
          this.dadosService.criarTalhao(this.fazendaSelecionada, novo).then(a => {
            this.enviando = false;
            this.talhaoForm.reset();
            novo.id = a;
            this.talhoes.push(novo);
            this.renderizaTalhao(novo, kml);
            this.dialogRef.close();
          }
          );
        });
      }
    }
  }

  private renderizaTalhao(t: Talhao, kml: Document) {
    let poligono = polygon([kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => [+t.split(',')[0], +t.split(',')[1]])]);
    poligono.geometry.coordinates[0].forEach(p => this.bounds.extend(new google.maps.LatLng(p[1], p[0])));
    this.mapa.fitBounds(this.bounds);
    let ponto = centerOfMass(poligono);
    if (!booleanPointInPolygon(ponto, poligono)) {
      ponto = pointOnFeature(poligono);
    }
    let novo = new google.maps.Polygon({
      paths: poligono.geometry.coordinates[0].map(p => new google.maps.LatLng(p[1], p[0])),
      strokeColor: '#008800',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#11BB11',
      fillOpacity: 0.35
    });
    novo.setMap(this.mapa);
    novo.addListener('click', a => { this.selecionaTalhao(t) });
    this.camadasTalhao[t.id] = novo;
    if (this.talhaoSelecionado && this.talhaoSelecionado.id == t.id) {
      this.camadasTalhao[this.talhaoSelecionado.id].setOptions({ fillColor: '#FFBB11' })
    }
    this.camadasTalhaoTexto[t.id] = (new CamadaTexto(this.mapa, t.nome, new google.maps.LatLng(ponto.geometry.coordinates[1], ponto.geometry.coordinates[0])));
  }

  selecionaTalhao(talhao: Talhao) {
    if (this.talhaoSelecionado && this.camadasTalhao[this.talhaoSelecionado.id]) {
      this.camadasTalhao[this.talhaoSelecionado.id].setOptions({ fillColor: '#11BB11' })
    }
    this.talhaoSelecionado = talhao;
    if (!this.talhaoSelecionado) {
      this.voos = [];
      return;
    }
    this.dadosService.listarVoos(talhao).subscribe(v => {
      this.voos = v;
    });
    if (this.camadasTalhao[talhao.id]) {
      this.camadasTalhao[talhao.id].setOptions({ fillColor: '#FFBB11' })
    }
  }

}
