<mat-card>
  <mat-card-header>
    <mat-card-title>Debug</mat-card-title>
  </mat-card-header>
  <div></div>
  <table>
    <tr><th>ID</th><th>Piloto</th></tr>
    <tr *ngFor="let voo of voos; trackBy: tid "><td>{{voo.id}}</td><td>{{voo.piloto}} {{voo.piloto?.apelido}}</td></tr>
  </table>
  <button mat-raised-button (click)="logar()">Logar</button>
  <button mat-raised-button (click)="consertarPilotos()">Consertar Pilotos</button>
</mat-card>