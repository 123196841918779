<form [formGroup]="dadosVooForm"  (ngSubmit)="confirmarVoo()">
    <h1 class="titulo"> Detalhes do Voo </h1>
    <mat-form-field>
      <input type="text" matInput required placeholder="Nome do Voo" formControlName="nome">
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Embalagens requisitadas" formControlName="doseRequisitada" [imask]="{mask: Number, scale: 0}">
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Embalagens lançadas" formControlName="quant" [imask]="{mask: Number, scale: 0}">
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Área (ha)" formControlName="area" [imask]="{mask: Number, scale: 3}">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Piloto</mat-label>
      <mat-select matNativeControl required formControlName="piloto">
        <mat-option *ngFor="let piloto of pilotos; trackBy: tid" [value]="piloto.id" >{{piloto.apelido}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Operador</mat-label>
      <mat-select matNativeControl required formControlName="operador">
        <mat-option *ngFor="let piloto of pilotos; trackBy: tid" [value]="piloto.id" >{{piloto.apelido}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Cultura" formControlName="cultura">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Estado da Missão</mat-label>
      <mat-select matNativeControl required formControlName="status">
        <mat-option value="completada" >Completada</mat-option>
        <mat-option value="abortada" >Abortada</mat-option>
        <mat-option value="parcial" >Parcialmente Completada</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tipo de Voo</mat-label>
      <mat-select matNativeControl required formControlName="tipoVoo">
        <mat-option value="real" >Real</mat-option>
        <mat-option value="treinamento" >Treinamento</mat-option>
        <mat-option value="demonstração" >Demonstração</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tipo Missão</mat-label>
      <mat-select matNativeControl required formControlName="tipoMissao">
        <mat-option value="cotésia">Cotésia</mat-option>
        <mat-option value="trichogramma">Trichogramma</mat-option>
        <mat-option value="imagem">Imagem</mat-option>
        <mat-option value="outros">Outros</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Relatos de Segurança" formControlName="relatosSeguranca"></textarea>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Drone SN" formControlName="droneID">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Baterias</mat-label>
      <mat-select multiple [value]="dadosVooForm.get('baterias').value">
        <mat-select-trigger>
          {{dadosVooForm.get('baterias').value.length}} baterias
        </mat-select-trigger>
        <mat-option *ngFor="let bateria of dadosVooForm.get('baterias').value" [value]="bateria.id" disabled>{{bateria.id}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Umidade" formControlName="umidade">
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Vento" formControlName="vento">
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Data" [value]="dadosVooForm.get('horarioLiga').value | date" disabled>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Horário Decolagem" [value]="dadosVooForm.get('horarioVoo').value | date:'H:mm:ss z'" disabled>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Horário Fim" [value]="dadosVooForm.get('horarioFim').value | date:'H:mm:ss z'" disabled>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Temperatura (ºC)" [imask]="{mask: Number, scale: 3}" formControlName="temperaturaMedia">
    </mat-form-field>
    <div class="button">
    <button type="submit" mat-raised-button [disabled]="enviando">Confirmar dados</button>
    </div>
</form>