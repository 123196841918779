<mat-card style="padding-top: 0px; min-height: 600px;" *ngIf="usuario?.permissoes.includes(permissoes.Monitoramento)">
  <h1 *ngIf="carrega" class="titulo-sup">Sala de Monitoramento</h1>
  <div *ngIf="carrega" class="carrega">
    <div style="padding-top: 100px">
      <mat-spinner style="margin: auto"></mat-spinner>
    </div>
  </div>

    <div *ngIf="selecionado" style="text-align: center; padding-top: 5px;">
      <mat-form-field [formGroup]="empresaSelecGroup">
        <mat-label>Filtrar empresas</mat-label>
        <mat-select formControlName="empresasSelec" multiple (selectionChange)="selecionaEmpresas($event)">
          <mat-option *ngFor="let empresa of empresas" [value]="empresa">{{empresa.nome}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp; &nbsp;
      <mat-form-field [formGroup]="filtroDataGrafico">
        <mat-label>Filtrar por data</mat-label>
        <mat-date-range-input [rangePicker]="picker1">
          <input matStartDate formControlName="dataInicioFiltroGrafico" placeholder="Data Inicial" (dateChange)="filtraDataGrafico(0, $event)">>
          <input matEndDate formControlName="dataFimFiltroGrafico" placeholder="Data Final" (dateChange)="filtraDataGrafico(1, $event)">>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="filtraDataGrafico(3)">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker1></mat-date-range-picker>
        <mat-error *ngIf="filtroDataGrafico.controls.dataInicioFiltroGrafico.hasError('matStartDateInvalid')">Data inicial inválida
        </mat-error>
        <mat-error *ngIf="filtroDataGrafico.controls.dataFimFiltroGrafico.hasError('matEndDateInvalid')">Data final inválida</mat-error>
      </mat-form-field>
    </div>
    <mat-tab-group *ngIf="!carrega" (selectedIndexChange)="selecionado = $event.valueOf()">
        <mat-tab label="Clientes">
          <button *ngIf="inf == true && detalha==true" mat-icon-button (click)="inf = false">Ocultar Inf.</button>
          <button *ngIf="inf == false && detalha==true" mat-icon-button (click)="inf = true">Mostrar Inf.</button>
          <div class="centralizar" *ngIf="detalha==true && this.empresas && !carrega">
              <mat-card-title>Empresas</mat-card-title>
          
              <div 
                  [routerLink]="'/monitoramento/detalhamento'" 
                  (click)="detalhaEmpresa()"
                  [state]="{data: empresa.empresaDados}"
                  class="logos" 
                  *ngFor="let empresa of dadosEmpresa"
              >
                  <img class="imagem-fundo" *ngIf="empresa?.empresaDados?.logo" [src]="empresa.empresaDados?.logo">
                  <h3 style="margin-bottom: 0px;">{{empresa.empresaDados?.nome}}</h3>
                  <h2 *ngIf="inf == true">R$ {{empresa.valorAcumulado | number:'1.2-2':'pt-br'}}</h2>
              </div>
            </div>
          <button 
              *ngIf="detalha==false"
              mat-icon-button
              [routerLink]="'/monitoramento'"
              (click)="detalhaEmpresa()"
          >
              Voltar
          </button>
          <router-outlet></router-outlet>
        </mat-tab>




        <mat-tab label="Geral">
          

          <img class="imagem" src="assets/Logo-vazado.png">

          <div *ngIf="carrega" style="height: 200px;"><mat-spinner style="margin: auto;"></mat-spinner></div>
            
            
            <div *ngIf="!carrega" class="centralizar">
              

              <h1 *ngIf="mesSobrevoado && mesReaisSobrevoado" class="titulo1" >
                <b>{{mesAtual}}</b><br>
                <b>ÁREA VOADA: {{formataNumero(mesSobrevoado)}}</b><br>
                <b>MÉDIA DIÁRIA: {{formataNumero(mediaDiaria)}} </b><br>
                <b>VALOR MÉDIO/ha: R${{(mesReaisSobrevoado/mesSobrevoado) | number:'1.2-2':'pt-br'}}</b><br/><br/>
                <b *ngIf="datas[0] && datas[1]">{{datas[0].toLocaleDateString('pt-br')}} a {{datas[1].toLocaleDateString('pt-br')}}</b>
              </h1>
                
            
                <div *ngIf="dadosTortaValorTotal.values()" class="texto-torta">
                  
                  <h1 class="tituloTorta"><b>Total em Reais Acumulado</b></h1>
                  <div class="graficoTorta">
                    <ngx-charts-advanced-pie-chart 
                      [animations]="false" 
                      [valueFormatting]="formataReais" 
                      [scheme]="coresPizza"
                      [results]="dadosTortaValorTotal" 
                      gradient="true"
                      [view]="[600, 250]">
                    </ngx-charts-advanced-pie-chart>
                  </div>
                </div>
            
                <div *ngIf="dadosTortaQuantTotal.values()" class="texto-torta">
                  <h1 class="tituloTorta"><b>Area total dos Contratos</b></h1>
                  <div class="graficoTorta">
                    <ngx-charts-advanced-pie-chart 
                      [animations]="false" 
                      [valueFormatting]="formataNumero" 
                      [scheme]="coresPizza"
                      [results]="dadosTortaQuantTotal" 
                      gradient="true"
                      [view]="[600, 250]">
                    </ngx-charts-advanced-pie-chart>
                  </div>
                </div>
            
                
            
                <div class="graficoBarras" *ngIf="dadosMes.values()">
                  <h1 class="titulo"><b>Área de voo por mês</b></h1>
                  <div class="grafico">
                    <div #containerRef class="spectre-line-chart">
                      <ngx-charts-bar-vertical-2d 
                        [results]="dadosMes" 
                        xAxis="true" yAxis="true" 
                        yAxisLabel="Quantidade (ha)"
                        showYAxisLabel="true" 
                        [scheme]="coresBarra" 
                        [view]="[containerRef.offsetWidth, 400]"
                        >
                      </ngx-charts-bar-vertical-2d>
                    </div>
                  </div>
                  <div class="legenda">
                    <b class="colorLegenda">⠀</b>
                    <b class="colorLegenda">⠀</b>
                    <b class="colorLegenda">⠀</b>
                    <b>⠀Área Sobrevoada ⠀</b>
                  </div>
                </div>

                <div class="graficoBarras" *ngIf="dadosMes.values()">
                  <h1 class="titulo"><b>FATURAMENTO</b></h1>
                  <div class="grafico">
                    <div #containerRef class="spectre-line-chart">
                      <ngx-charts-bar-vertical-2d 
                        [results]="dadosReaisMes" 
                        xAxis="true" yAxis="true" 
                        yAxisLabel="Valor(R$)"
                        showYAxisLabel="true" 
                        [scheme]="coresBarra2" 
                        [view]="[containerRef.offsetWidth, 400]"
                        >
                      </ngx-charts-bar-vertical-2d>
                    </div>
                  </div>
                  <div class="legenda">
                    <b class="colorLegenda2">⠀</b>
                    <b class="colorLegenda2">⠀</b>
                    <b class="colorLegenda2">⠀</b>
                    <b>⠀FATURAMENTO⠀</b>
                  </div>
                </div>
            </div>






        </mat-tab>
        <mat-tab label="Dados">
          <div *ngIf="dadosEmpresa" class="tabela-container mat-elevation-z8">
            <table #tabDados mat-table [dataSource]="dataSource" matSort>
    
              <ng-container matColumnDef="cliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header><b>CLIENTE</b></th>
                <td mat-cell class="mat-tab-row" *matCellDef="let dadoEmpresa" (click)="showDadosDialog(dadoEmpresa.empresaDados)">{{dadoEmpresa.empresaDados.nome}}</td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
              </ng-container>
  
              <ng-container matColumnDef="area">
                <th mat-header-cell *matHeaderCellDef><b>AREA ACUMULADA</b></th>
                <td mat-cell *matCellDef="let dadoEmpresa">{{formataNumero(dadoEmpresa.areaAcumulada)}}</td>
                <td mat-footer-cell *matFooterCellDef> {{formataNumero(totalArea)}} </td>
              </ng-container>
  
              <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef><b>VALOR ACUMULADO</b></th>
                <td mat-cell *matCellDef="let dadoEmpresa"><span *ngIf="dadoEmpresa.valorAcumulado">R$</span>{{dadoEmpresa.valorAcumulado | number:'1.2-2':'pt-br'}}</td>
                <td mat-footer-cell *matFooterCellDef> <span *ngIf="totalValor">R$</span> {{totalValor | number:'1.2-2':'pt-br'}} </td>
              </ng-container>

              <ng-container matColumnDef="atingimento">
                <th mat-header-cell *matHeaderCellDef><b>% ATINGIMENTO DO CONTRATO</b></th>
                <td mat-cell *matCellDef="let dadoEmpresa">{{dadoEmpresa.atingimento | number:'1.2-2':'pt-br'}}</td>
                <td mat-footer-cell *matFooterCellDef> {{totalAtingimento | number:'1.2-2':'pt-br'}} </td>
              </ng-container>
      
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
          </div>
          

        </mat-tab>
    </mat-tab-group>
</mat-card>
