<mat-card>

    <img class="imagem-fundo" *ngIf="empresa?.logo" [src]="empresa.logo">
    <h1 class="titulo"> {{empresa.nome}}</h1>
    <br><br>
    <h1 *ngIf="dadosG.length==0" class="titulo"> Não há dados de voo</h1>

    <div *ngIf="dadosG.length>0" class="tabela-container mat-elevation-z8">
        <table #tabDados mat-table [dataSource]="dataSource" >

          <ng-container matColumnDef="mes">
            <th mat-header-cell *matHeaderCellDef><b>MÊS</b></th>
            <td mat-cell class="mat-tab-row" *matCellDef="let dado">{{dado.mes}}</td>
          </ng-container>

          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef><b>AREA VOADA (ha)</b></th>
            <td mat-cell *matCellDef="let dado">{{dado.areaVoada | number:'1.2-2':'pt-br'}}</td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef><b>VALOR</b></th>
            <td mat-cell *matCellDef="let dado"><span *ngIf="dado.valor">R$</span>{{dado.valor | number:'1.2-2':'pt-br'}}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        </table>
      </div>

</mat-card>
