import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dispenser } from 'src/app/modelos/modelos';
import { DadosService } from 'src/app/servicos/dados.service';

@Component({
  selector: 'app-dispenser-dialog',
  templateUrl: './dispenser-dialog.component.html',
  styleUrls: ['./dispenser-dialog.component.scss']
})
export class DispenserDialogComponent implements OnInit {

  enviando = false;
  dispensers: Dispenser[] = [];
  Number = Number;


  formularioDispenser: FormGroup = new FormGroup({
    modelo: new FormControl('', Validators.required),
    doses: new FormControl('', Validators.min(0)),
    observacoes: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {dispensers: Dispenser[]},
    private dialogRef: MatDialogRef<any>,
    private dadosService: DadosService) { 
      this.dispensers = data.dispensers;
    }

  ngOnInit(): void {
  }

  criarDispenser() {
    if (this.formularioDispenser.valid) {
      this.enviando = true;
      this.dadosService.criarDispenser(this.formularioDispenser.value).then(dispenser => {
        this.enviando = false;
        this.formularioDispenser.reset();
        this.dispensers.push(dispenser);
        this.dialogRef.close(this.dispensers);
      });
    }
  }

}
