import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DadosService } from './../../../../../../../servicos/dados.service';
import { Voo, DadosUsuario } from './../../../../../../../modelos/modelos';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-detalhes-dialog',
  templateUrl: './detalhes-dialog.component.html',
  styleUrls: ['./detalhes-dialog.component.scss']
})
export class DetalhesDialogComponent implements OnInit {

  dadosVooForm: FormGroup = new FormGroup({
    nome: new FormControl({ value: '' }, Validators.required),
    piloto: new FormControl(''),
    operador: new FormControl(''),
    cultura: new FormControl(''),
    area: new FormControl(''),
    droneID: new FormControl({ value: '', disabled: true }),
    baterias: new FormControl({ value: '', disabled: true }),
    quant: new FormControl(''),
    doseRequisitada: new FormControl(''),
    horarioLiga: new FormControl({ value: '', disabled: true }),
    horarioVoo: new FormControl({ value: '', disabled: true }),
    horarioFim: new FormControl({ value: '', disabled: true }),
    temperaturaMedia: new FormControl({ value: ''}),
    umidade: new FormControl(),
    vento: new FormControl(),
    status: new FormControl(),
    tipoVoo: new FormControl(),
    tipoMissao: new FormControl(),
    observacoes: new FormControl(),
    relatosSeguranca: new FormControl()
  });

  vooConfirmando = null;
  pilotos: DadosUsuario[] = [];
  voos: Voo[];
  Number = Number;
  enviando = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: {pilotos: DadosUsuario[], voos: Voo[], 
              dadosVooForm: FormGroup, vooConfirmando},
              private dadosService: DadosService,
              private dialogRef: MatDialogRef<any>
              ) {
                this.pilotos = data.pilotos
                this.voos = data.voos
                this.dadosVooForm = data.dadosVooForm
                this.vooConfirmando = data.vooConfirmando
               }

  ngOnInit(): void {
  }

  questionarVoo(voo: Voo) {
    console.log(this.vooConfirmando);
    this.vooConfirmando = voo;
    this.dadosVooForm.patchValue(voo);
    this.dadosVooForm.patchValue({"piloto": voo.piloto.id, "operador": voo.operador.id});
  }

  confirmarVoo() {
    if (this.dadosVooForm.valid) {
      console.log(this.vooConfirmando);
      console.log(this.dadosVooForm.value);
      this.vooConfirmando = Object.assign(this.vooConfirmando, this.dadosVooForm.value);
      if (!this.vooConfirmando.piloto.id) {
        this.vooConfirmando.piloto = this.pilotos.filter(p => p.id === this.vooConfirmando.piloto)[0];
      }
      if (!this.vooConfirmando.operador.id) {
        this.vooConfirmando.operador = this.pilotos.filter(p => p.id === this.vooConfirmando.operador)[0];
      }
      this.vooConfirmando['dadosConfirmados'] = true;
      this.dadosService.atualizarVoo(this.vooConfirmando).then(a => {
        this.voos[this.voos.findIndex(v => v.id === this.vooConfirmando.id)] = this.vooConfirmando;
        this.vooConfirmando = null;
        this.dadosVooForm.reset();
        console.log(this.vooConfirmando);
        this.dialogRef.close();
      });
    }
  }

  tid (index, item) {
    if (!item) return null;
    return index.id;
  }

}
