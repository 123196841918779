import { AutenticaService } from 'src/app/servicos/autentica.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {v

  formulario: FormGroup = new FormGroup({
    email: new FormControl('', Validators.email),
  });

  constructor(
    public autenticaService: AutenticaService
  ) { }

  ngOnInit(): void {
  }
}
