import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'idPipe' })
export class IdPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    let s = "";
    value.forEach(v => s += v.id + ", ");
    return s.substring(0, s.length - 2);
  }
}
