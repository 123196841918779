import { Empresa, Fazenda, Voo, LimitesBateria, LimitesDrone } from './../../modelos/modelos';
import { OperadorDialogComponent } from './Dialogs/operador-dialog/operador-dialog/operador-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Drone, Dispenser, Bateria, DadosUsuario } from 'src/app/modelos/modelos';
import { DadosService } from 'src/app/servicos/dados.service';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { ExcluirPopUp } from '../perfil-usuario/perfil-usuario.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DroneDialogComponent } from './Dialogs/drone-dialog/drone-dialog.component';
import { BateriaDialogComponent } from './Dialogs/bateria-dialog/bateria-dialog.component';
import { DispenserDialogComponent } from './Dialogs/dispenser-dialog/dispenser-dialog.component';
import { ManutencaoDialogComponent } from './Dialogs/manutencao-dialog/manutencao-dialog.component';


interface OpMaster {operador: DadosUsuario, voos: Voo[], areaSobrevoada: number, horasSobrevoadas: number, nVoos?: number}
@Component({
  selector: 'app-logistica',
  templateUrl: './logistica.component.html',
  styleUrls: ['./logistica.component.scss']
})
export class LogisticaComponent implements OnInit {

    
  displayedColumnsDrone: string[] = ["sn", "nome", "modelo", "opcoes"];
  dataSourceDrone: MatTableDataSource<Drone>;
  @ViewChild(('tabDrone'), {read: MatSort,  static: false }) sortDrone: MatSort;

  displayedColumnsBateria: string[] = ["sn", "modelo", "soh", "opcoes"];
  dataSourceBateria: MatTableDataSource<Bateria>;
  @ViewChild(('tabBateria'), {read: MatSort,  static: false }) sortBateria: MatSort;

  displayedColumnsDispenser: string[] = ["modelo", "doses", "opcoes"];
  dataSourceDispenser: MatTableDataSource<Dispenser>;
  @ViewChild(('tabDispenser'), {read: MatSort,  static: false }) sortDispenser: MatSort;

  displayedColumnsOperador: string[] = ["nome", "apelido", "numeroPiloto", "horasVoo", "areaVoo", "quantVoo", "opcoes"];
  dataSourceOperador: MatTableDataSource<OpMaster>;
  @ViewChild(('tabOperador'), {read: MatSort,  static: false }) sortOperador: MatSort;


  limitesDrone: LimitesDrone[] = [];
  modelos: string[] = [];

  
  enviando = false;
  drones: Drone[] = [];
  baterias: Bateria[] = [];
  dispensers: Dispenser[] = [];
  operador: DadosUsuario = {
    id: '',
    email: '',
    nome: '',
    apelido: '',
    cpf: '',
    empresa: {
      id: '',
      nome: '',
    },
    fazendas: [] = [],
    permissoes: [] = [],
    horasIniciais: 0,
    numeroPiloto: 0,
    voosIndividuais: [] = [],
    area: '',
    numeroVoos: 0
  }  
  operadores: DadosUsuario[] = [];
  fazendas: Fazenda[];
  Number = Number;
  usuario: DadosUsuario;
  fazendasTotais: Fazenda[];
  empresas: Empresa[] = [];
  voosIndividuais: Voo[] = [];
  voosOperadores: Voo[] = [];
  vooValido: Voo[] = [];
  voosNomes: number[];
  operadoresMaster: OpMaster[] = [] ;
  limitesVoosBateria: LimitesBateria = {
    limVoosBateria : 0
  }
  

  formularioDispenser: FormGroup = new FormGroup({
    modelo: new FormControl('', Validators.required),
    doses: new FormControl('', Validators.min(0)),
    observacoes: new FormControl(''),
  });

  constructor(private dadosService: DadosService, private autenticador: AutenticaService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.dadosService.listarDrones().subscribe(drones =>{ 
      this.drones = drones;
      drones.forEach(drone=> {
        let index
        if(this.limitesDrone.find(lim => lim.modelo == drone.modelo)==undefined){
          this.limitesDrone.push({limHelice:undefined, limMotor:undefined, limHoras:undefined, limVoos:undefined, modelo:drone.modelo})
        }
        if(!this.modelos.includes(drone.modelo)){
          this.modelos.push(drone.modelo)
          console.log(this.limitesDrone)
        }
        
      })
      console.log(this.modelos)
      this.atualizaTabDrones(drones)
    });
    this.dadosService.listarBaterias().subscribe(baterias =>{
      this.baterias = baterias;
      this.atualizaTabBaterias(baterias)
    });
    this.dadosService.listarDispensers().subscribe(dispensers => {
      this.dispensers = dispensers;
      this.atualizaTabDispensers(dispensers)
    });
    this.dadosService.listarLimitesDrones().subscribe(limites => {
      limites.forEach(lim =>{
        this.limitesDrone[this.limitesDrone.findIndex(limd => limd.modelo == lim.modelo)] = lim
      })
    });
    this.dadosService.listarLimiteBateria().subscribe(limite => {
      if(limite){
        this.limitesVoosBateria = limite;
      }
    })
    this.autenticador.listarOperadores().subscribe(operadores => {
      this.operadores = operadores;
      console.log(this.operadores);
    });
    this.autenticador.usuarioAtual.then(u => {
      this.usuario = u;
        this.dadosService.listarEmpresasCompletas(true).subscribe(empresas => { 
          this.empresas = empresas
        }, err => console.log(err), () => this.processaDados());
    });
  
  }

  


  //Associação de operadores e voos ---------------------------------------------------
  processaDados() {
    let operadoresM: OpMaster[] = []
    console.log(this.operadores);
    console.log(this.empresas)
    this.empresas.forEach(empresa=>{
        empresa.fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach(voo => {
          if(voo.operador != undefined){
            if(voo.operador.nome != undefined){
              if(!voo.operador.horasIniciais){
                voo.operador.horasIniciais = 0
              }
              if(voo.area == undefined || !voo.area || voo.area==null){
                voo.area=0;
              }
              let aux: string = voo.area.toString()
              voo.area = +aux.replace(',', '.')

              if (!operadoresM[voo.operador.cpf]) {
                operadoresM[voo.operador.cpf] = {operador: voo.operador, voos: [voo], areaSobrevoada: +voo.area, horasSobrevoadas: +voo.operador.horasIniciais, nVoos: 1}
              } else {
                operadoresM[voo.operador.cpf].voos.push(voo)
                operadoresM[voo.operador.cpf].areaSobrevoada = +operadoresM[voo.operador.cpf].areaSobrevoada + +voo.area;  
                operadoresM[voo.operador.cpf].horasSobrevoadas = operadoresM[voo.operador.cpf].horasSobrevoadas + ((voo.horarioFim - voo.horarioVoo)/3600000)
                operadoresM[voo.operador.cpf].nVoos = operadoresM[voo.operador.cpf].nVoos + 1
              }
            }
        }})));
    })
    this.operadores.forEach(operador =>{
      if(!operador.horasIniciais){
        operador.horasIniciais = 0
      }
      if (!operadoresM[operador.cpf]) {
        operadoresM[operador.cpf] = {operador: operador, voos: [], areaSobrevoada: 0, horasSobrevoadas: +operador.horasIniciais, nVoos: 0}
      }
    })
    Object.keys(operadoresM).sort().forEach(operadorMaster =>{
      this.operadoresMaster.push(operadoresM[operadorMaster])
    })
    this.atualizaTabOperadores(this.operadoresMaster)
    console.log(this.operadoresMaster);
    console.log(this.operadores);
    
    this.dataSourceDrone.sort = this.sortDrone;
    this.dataSourceBateria.sort = this.sortBateria;
    this.dataSourceDispenser.sort = this.sortDispenser;
    this.dataSourceOperador.sort = this.sortOperador;
    
  }
  //Fim associação ----------------------------------------------------------------------

  atualizaTabDrones(drones: Drone[]) {
    console.log(drones)
      this.dataSourceDrone = new MatTableDataSource<Drone>(drones)
      this.dataSourceDrone.sortingDataAccessor = (item, property) => {
        if (property === 'sn') {
          return item.sn;
        } else if(property === 'nome') {
          return item.nome;
        }
          else if(property === 'modelo'){
            return item.modelo;
        }
      };
      this.dataSourceDrone.sort = this.sortDrone;
  }

  atualizaTabBaterias(baterias: Bateria[]) {
      this.dataSourceBateria = new MatTableDataSource<Bateria>(baterias)
      this.dataSourceBateria.sortingDataAccessor = (item, property) => {
        if (property === 'sn') {
          return item.sn;
        } else if(property === 'modelo') {
          return item.modelo;
        }
          else if(property === 'soh'){
            return item.soh;
        }
      };
      this.dataSourceBateria.sort = this.sortBateria;
  }

  atualizaTabDispensers(dispensers: Dispenser[]) {
    this.dataSourceDispenser = new MatTableDataSource<Dispenser>(dispensers)
    this.dataSourceDispenser.sortingDataAccessor = (item, property) => {
      if (property === 'modelo') {
        return item.modelo;
      } else if(property === 'doses') {
        return item.doses;
      }
    };
    this.dataSourceDispenser.sort = this.sortDispenser;
  }

  atualizaTabOperadores(operadores: OpMaster[]) {
    console.log(operadores)
    this.dataSourceOperador = new MatTableDataSource<OpMaster>(operadores)
    console.log(this.dataSourceOperador)
    this.dataSourceOperador.sortingDataAccessor = (item, property) => {
      if (property === 'nome') {
        return item.operador.nome;
      } else if(property === 'apelido') {
        return item.operador.apelido;
      } else if(property === 'numeroPiloto') {
        return item.operador.numeroPiloto;
      } else if(property === 'horasVoo') {
        return item.horasSobrevoadas;
      } else if(property === 'areaVoo') {
        return item.areaSobrevoada;
      } else if(property === 'quantVoo') {
        return item.nVoos;
      }
    };
    this.dataSourceOperador.sort = this.sortOperador;
  }


  showDroneDialog(drone?: Drone): void{
    const dialogRef = this.dialog.open(DroneDialogComponent,{data: {drones: this.drones, drone: drone},width: "750px"});

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.drones = result
        this.atualizaTabDrones(result)
      }
    })
  }

  showOperadorDialog(operador?: DadosUsuario): void{
    const dialogRef = this.dialog.open(OperadorDialogComponent,{data: {operadores: this.operadores, operador: operador},width: "750px"});

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.operadores = result
        this.atualizaTabOperadores(result)
      }
    })
  }

  showBateriaDialog(): void{
    const dialogRef = this.dialog.open(BateriaDialogComponent,{data: {baterias: this.baterias},width: "750px"});

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.baterias = result
        this.atualizaTabBaterias(result)
      }
    })
  }

  showDispenserDialog(): void{
    const dialogRef = this.dialog.open(DispenserDialogComponent,{data: {dispensers: this.dispensers},width: "750px"});

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.dispensers = result
        this.atualizaTabDispensers(result)
      }
    })
  }

  showManutencaoDialog(limitesD: LimitesDrone): void{
    const dialogRef = this.dialog.open(ManutencaoDialogComponent,{data: {limites:limitesD}, width: "750px"});

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.dispensers = result
        this.limitesDrone[this.limitesDrone.findIndex(limd => limd.modelo == result.modelo)] = result
      }
    })
  }


  criarDispenser() {
    if (this.formularioDispenser.valid) {
      this.enviando = true;
      this.dadosService.criarDispenser(this.formularioDispenser.value).then(dispenser => {
        this.enviando = false;
        this.formularioDispenser.reset();
        this.dispensers.push(dispenser);
        this.atualizaTabDispensers(this.dispensers)
      });
    }
  }

  excluirDrone(drone: Drone) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirDrone(drone).then(() => {
          this.drones = this.drones.filter(d => d.id !== drone.id)
          this.atualizaTabDrones(this.drones)
        });
      }
    });
  }

  excluirOperador(operador: DadosUsuario) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirOperador(operador).then(() => {
          this.operadoresMaster = this.operadoresMaster.filter(d => d.operador.id !== operador.id)
          this.atualizaTabOperadores(this.operadoresMaster)
        });
      }
    });
  }

  excluirBateria(bateria: Bateria) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirBateria(bateria).then(() => {
          this.baterias = this.baterias.filter(b => b.id !== bateria.id)
          this.atualizaTabBaterias(this.baterias)
        });
      }
    });
  }

  excluirDispenser(dispenser: Dispenser) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirDispenser(dispenser).then(() => {
          this.dispensers = this.dispensers.filter(d => d.id !== dispenser.id)
          this.atualizaTabDispensers(this.dispensers)
        });
      }
    });
  }

  createlimiteVoosBateria(limiteVoosBat: number){
    console.log(limiteVoosBat);
    this.limitesVoosBateria.limVoosBateria = limiteVoosBat;
    this.dadosService.criarLimitesBateria(this.limitesVoosBateria);
  }

}
