import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DadosUsuario, Empresa, Fazenda, Voo } from 'src/app/modelos/modelos';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { DadosService, numero } from 'src/app/servicos/dados.service';
import { DadosComponent } from './dados/dados.component';

  interface EmpresaDados {

    empresaDados : Empresa
    areaAcumulada: number
    valorAcumulado: number
    atingimento: number

  }
@Component({
  selector: 'app-monitoramento',
  templateUrl: './monitoramento.component.html',
  styleUrls: ['./monitoramento.component.scss']
})
export class MonitoramentoComponent implements OnInit {

  
  selecionado = 0;
  displayedColumns: string[] = ["cliente", "area", "valor", "atingimento"];
  dataSource: MatTableDataSource<EmpresaDados>;
  @ViewChild(('tabDados'), {read: MatSort,  static: false }) sortDados: MatSort;


  dadosEmpresa: EmpresaDados [] = [] 
  detalha:boolean = true
  carrega: boolean = true
  inf: boolean = true
  
  permissoes = DadosUsuario.Permissoes;
  usuario: DadosUsuario;


  totalValor = 0
  totalArea = 0
  totalAtingimento = 0


  empresas:Empresa[] = []
  empresasFiltro: Empresa[]
  voos: Voo[] = []
  meses =  ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET',  'OUT',  'NOV',  'DEZ']
  mesesCompleto =  ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',  'Outubro',  'Novembro',  'Dezembro']


  datas: Date[] = [null, null];

  mediaDiaria = 0;
  dadosMes = [];
  dadosReaisMes = [];
  dadosTortaQuantTotal = [];
  dadosTortaValorTotal = [];

  mesSobrevoado = 0;
  mesReaisSobrevoado = 0;
  mesAtual: string

  

  filtroDataGrafico: FormGroup = new FormGroup({
    dataInicioFiltroGrafico: new FormControl(),
    dataFimFiltroGrafico: new FormControl()
  })

  empresaSelecGroup: FormGroup = new FormGroup({
    empresasSelec: new FormControl()
  })

  
  empresasSelec: FormControl = new FormControl()

  view: any[] = [900,400];
  coresBarra = {
    domain: ['#004B51', '#F7F773', '#5F903F', '#ECEBEB']
  };
  coresBarra2 = {
    domain: ['#ADC737', '#004B51', '#5F903F', '#ECEBEB']
  };
  coresPizza = {
    domain: ['#F7F773', '#004B51', '#5F903F', '#AAAAAA']
  };



  constructor(private dadosService: DadosService, private autenticador: AutenticaService, private dialog: MatDialog, private _adapter: DateAdapter<any>) {
    this._adapter.setLocale('pt');
  }

  ngOnInit(): void {
    let date = new Date()
    this.datas[0] = new Date(date.getFullYear(), 0, 1, 0, 0, 0)
    this.datas[1] = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)
    this.filtroDataGrafico.patchValue({dataInicioFiltroGrafico: this.datas[0], dataFimFiltroGrafico:this.datas[1]})

    this.autenticador.usuarioAtual.then(u => {
      this.usuario = u;
      if(u?.permissoes.includes(this.permissoes.Monitoramento)){
        
        this.dadosService.listarEmpresasCompletas(true).subscribe(empresas => { 
          this.empresas = empresas.filter(e => e.cnpj != '31089216000107')
        }, 
        err => console.log(err), 
        () => {
          this.empresas = this.empresas.sort((a,b) => {
            if(a.nome < b.nome) { return -1; }
            if(a.nome > b.nome) { return 1; }
          })
          this.empresas = [...this.empresas, ...this.carregaEmpresasTotais(this.empresas)]
          this.empresasFiltro = this.empresas;
          this.processaDados(this.empresas)
        });

        
      }
    });
  }

  

  carregaEmpresasTotais(empresas: Empresa[]): Empresa[]{
    let empresasTotal:any[] = [];
    let empresasTotais: Empresa[] = [];

    empresas.forEach(empresa => {

      if(empresa.grupo){
        if(!empresasTotal[empresa.grupo]){
          empresasTotal[empresa.grupo] = [empresa]
        }
        else{
          empresasTotal[empresa.grupo] = [...empresasTotal[empresa.grupo], empresa]
        }
      }
    })
    
    const empresasT = Object.keys(empresasTotal).sort();

    empresasT.forEach(grupo => {
      let auxQuantidades: number[] = [];
      let auxIni = 0
      let auxFim = 0
      let auxCiclo = 0 
      let auxValor = ""
      let auxFazendas: Fazenda[] = []
      empresasTotal[grupo].forEach(empresa => {
        if( auxValor == "" && empresa.valor ){
          auxValor = empresa.valor
        }
        if( auxCiclo == 0 && empresa.inicioCiclo ){
          auxCiclo = empresa.inicioCiclo
        }
        if( auxIni == 0 && empresa.dataInicio > 0 ){
          auxIni = empresa.dataInicio
        }
        if( auxIni && empresa.dataInicio > 0 && empresa.dataInicio && empresa.dataInicio < auxIni){
          auxIni = empresa.dataInicio
        }
        if( auxFim && empresa.dataFim && empresa.dataFim > auxFim){
          auxFim = empresa.dataFim
        }
        auxFazendas = [...auxFazendas, ...empresa.fazendas];
      });

      empresasTotal[grupo].forEach(empresa => {
        let auxInicio = auxIni ? new Date(auxIni) : null
        let dataInicio = empresa.dataInicio ? new Date(empresa.dataInicio) : null
        if( empresa.quantidades && auxIni && dataInicio){
          let difM: number = this.monthDiff(auxInicio, dataInicio)
          empresa.quantidades.forEach((quantidade, i) => {
            if(quantidade == null || quantidade.toString() == ""){
              quantidade = 0;
            }
            if(auxQuantidades[i+difM]){
              auxQuantidades[i+difM] += numero(quantidade);
            }else{
              auxQuantidades[i+difM] = numero(quantidade);
            }
          });
        }
      });

      let empresaTotal: Empresa = {
        id: "",
        nome: grupo + ' Total',
        grupo: 'total',
        area: 0,
        valor: auxValor,
        logo: empresasTotal[grupo][0].logo,
        dataInicio: auxIni,
        dataFim: auxFim,
        quantidades: auxQuantidades,
        fazendas: auxFazendas,
        inicioCiclo: auxCiclo,
      }
      empresasTotais.push(empresaTotal)

    })
    return empresasTotais
  }


  processaDados(empresas: Empresa[]){
    this.mesSobrevoado = 0;
    this.mesReaisSobrevoado = 0;

    this.dadosEmpresa = []
      
    
    let dataAtual = new Date()
    let dados:any[] = [];
    this.voos = [];
    let totalEsperado = 0
    let totalSobrevoado = 0

    let totalReaisEsperado = 0
    let totalReaisSobrevoado = 0

    


    empresas.forEach(empresa=>{

      let somaArea = 0
      let somaAreatotal = 0

      let somaQuant = empresa?.quantidades ? empresa?.quantidades?.reduce((a, b) => numero(a) + numero(b), 0) : 0
      
      let dataInicio = new Date(empresa.dataInicio)

      let aux = dataInicio

      
      if(this.datas[0] && this.datas[1]){
        somaQuant = 0

        empresa.quantidades?.forEach((quantidade, i) => {
          if(quantidade == null || quantidade.toString() == ""){
            quantidade = 0
          }
          if(aux>this.datas[0] && aux<this.datas[1]){
            somaQuant += numero(quantidade) 
          }
          aux.setMonth(aux.getMonth() + 1)
        })
      }

      empresa.fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach(voo=>{
         
        if (voo.horarioLiga) {
          let aux = new Date(voo.horarioLiga)
          let area = voo.area == null ? numero(talhao.area) : numero(voo.area);
          let quant = numero(voo.quant);
          let reais =  empresa?.valor ? (area * numero(empresa?.valor)) : 0

          if(empresa.grupo != 'total' && aux.getFullYear()==dataAtual.getFullYear() && aux.getMonth()==dataAtual.getMonth()){
            this.mesSobrevoado += area;
            this.mesReaisSobrevoado += reais;
          }

          if(this.datas[0] && this.datas[1]){
            if(aux>this.datas[0] && aux<this.datas[1]){
              somaAreatotal += area;
              if(empresa.grupo != 'total'){
                somaArea += area;
                totalSobrevoado += area;
                totalReaisSobrevoado += reais;


                let mes: string = aux.toISOString().substr(0, 7);
                
              
                if (!dados[mes]) {
                  dados[mes] = [area, quant, reais];
                } else {
                  dados[mes] = [dados[mes][0] + area, dados[mes][1] + quant, dados[mes][2] + reais];
                }
              }
            }
          }else{
            somaAreatotal += area;
            if(empresa.grupo != 'total'){
              somaArea += area;
              totalSobrevoado += area;
              totalReaisSobrevoado += reais;


              let aux = new Date(voo.horarioLiga)
              let mes: string 
              
              mes = aux.toISOString().substr(0, 7);
              
              
              if (!dados[mes]) {
                dados[mes] = [area, quant, reais];
              } else {
                dados[mes] = [dados[mes][0] + area, dados[mes][1] + quant, dados[mes][2] + reais];
              }
            }
          }

        } else {
          console.log("não tem horario liga", voo);
        }
      })))


      this.dadosEmpresa.push(
        {empresaDados: empresa, 
          areaAcumulada: somaAreatotal>0 ? somaAreatotal : 0, 
          valorAcumulado: empresa?.valor&&numero(empresa?.valor)>0  ? somaAreatotal*numero(empresa.valor) : null, 
          atingimento: empresa?.area&&numero(empresa?.area)>0 ? (somaAreatotal/numero(empresa.area))*100 : null
        }
      )

      if(empresa.grupo != 'total'){
        totalEsperado += somaQuant;
        totalReaisEsperado += empresa?.valor ?  (somaQuant * numero(empresa?.valor)) : 0
      }

    })

    console.log(this.dadosEmpresa)

    let quantd = new Date(dataAtual.getFullYear(), dataAtual.getMonth()+1, 0).getDate();

    this.mediaDiaria = this.mesSobrevoado/quantd

    this.mesAtual = this.mesesCompleto[dataAtual.getMonth()]
   
    

    const dadosMes = [];
    const dadosReaisMes = [];
    const meses = Object.keys(dados).sort();

//Atualiza tabela
    this.totalValor = totalReaisSobrevoado>0 ? totalReaisSobrevoado : 0
    this.totalArea = totalSobrevoado>0 ? totalSobrevoado : 0 
    this.totalAtingimento = totalEsperado>0&&totalSobrevoado>0 ?  (totalSobrevoado/totalEsperado)*100 : 0


    this.dataSource = new MatTableDataSource<EmpresaDados>(this.dadosEmpresa)
    this.dataSource.sortingDataAccessor = (item, property) => {
      return item.empresaDados.nome;
    };
    this.dataSource.sort = this.sortDados;

//Associa dados grafico de torta com valor total acumulado
    this.dadosTortaValorTotal = [{
      "name": "Pendente",
      "value": ((((totalReaisEsperado-totalReaisSobrevoado) > 0 ? (totalReaisEsperado-totalReaisSobrevoado) : 0) + Number.EPSILON) * 100) / 100,
    }, {
      "name": "Total acumulado",
      "value": ((totalReaisSobrevoado + Number.EPSILON) * 100) / 100,
    },
    ]
    
   
  //Associa dados grafico de torta com a quant total acumulada
    this.dadosTortaQuantTotal = [{
      "name": "Área pendente",
      "value": ((((totalEsperado-totalSobrevoado) > 0 ? (totalEsperado-totalSobrevoado) : 0) + Number.EPSILON) * 100) / 100,
    }, {
      "name": "Área sobrevoada",
      "value": ((totalSobrevoado + Number.EPSILON) * 100) / 100,
    },
    ]

    
  //Associa dados dos meses ao grafico de barras
    console.log(meses);
    meses.forEach(mes => {
      let nome = this.meses[parseInt(mes.substr(5,7))-1]
      let ano = mes.substr(2,2)
      let data = (nome + "/" + ano);
      dadosMes.push({
        "name": data,
        "series": [
          {
            "name": "Área Sobrevoada",
            "value": Math.round((dados[mes][0] + Number.EPSILON) * 100) / 100
          }
        ],
      })

      dadosReaisMes.push({
        "name": data,
        "series": [
          {
            "name": "Valor em Reais Sobrevoado",
            "value": Math.round((dados[mes][2] + Number.EPSILON) * 100) / 100
          }
        ],
      })
    });
  
  
  
  
    this.dadosMes = [...dadosMes]
    this.dadosReaisMes = [...dadosReaisMes]

    this.carrega = false
    
  }


  showDadosDialog(empresa: Empresa){

    const dialogRef = this.dialog.open(DadosComponent,{data: empresa, width: "750px"});

  }

  filtraDataGrafico(i: number, evento?) {
    if (evento?.value) {
      this.datas[i] = evento.value.toDate();
    } else {
      this.datas[i] = null;
    }
    if (this.datas[0] && this.datas[1]) {
      this.processaDados(this.empresasFiltro)
    }
    if (i == 3){
      this.datas = []
      this.filtroDataGrafico.reset();
      this.processaDados(this.empresasFiltro);
    }
  }



  selecionaEmpresas(event?){
    this.empresasFiltro = event.value
    
    if(event && this.empresasFiltro.length>0){
      this.processaDados(this.empresasFiltro)
    }
    else{
      this.empresasFiltro = this.empresas
      this.processaDados(this.empresas)
    }

  }

  

 diffMeses(inicio: Date, fim: Date) {
    return fim.getMonth() - inicio.getMonth() + (12 * (fim.getFullYear() - inicio.getFullYear()));
  }

  formataNumero(a) {
    if(a!=null)
    return a.toLocaleString(undefined, { maximumFractionDigits: 2 }) + " ha"

    else
    return null
  }

  formataReais(a) {
    return " R$" + a.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits: 2 })
  }

  detalhaEmpresa(){
    if(this.detalha==false){
      this.detalha=true
    }
    else
      this.detalha=false
    

    
  }

  numero(num): number{
    return numero(num)
  }


  monthDiff(d1: Date, d2: Date): number {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
}
