import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Drone } from 'src/app/modelos/modelos';
import { DadosService } from 'src/app/servicos/dados.service';

@Component({
  selector: 'app-drone-dialog',
  templateUrl: './drone-dialog.component.html',
  styleUrls: ['./drone-dialog.component.scss']
})
export class DroneDialogComponent implements OnInit {

  drone: Drone
  drones: Drone[] = [];
  enviando = false;
  Number = Number;

  formularioDrone: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    sn: new FormControl('', Validators.required),
    modelo: new FormControl('', Validators.required),
    horasIniciais: new FormControl('', Validators.min(0)),
    observacoes: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {drones: Drone[], drone?: Drone},
    private dialogRef: MatDialogRef<any>,
    private dadosService: DadosService) { 
      this.drones = data.drones;
      this.drone = data.drone;
    }

  ngOnInit(): void {
    if(this.drone){
      this.formularioDrone.patchValue(this.drone);
    }
  }

  criarDrone() {
    if (!this.drone && this.formularioDrone.valid) {
      this.enviando = true;
      this.dadosService.criarDrone(this.formularioDrone.value).then(drone => {
        this.enviando = false;
        this.formularioDrone.reset();
        this.drones.push(drone);
        this.dialogRef.close(this.drones);
      });
    }
    else if (this.drone && this.formularioDrone.valid) {
      this.drone.nome = this.formularioDrone.get('nome').value
      this.drone.sn = this.formularioDrone.get('sn').value
      this.drone.modelo = this.formularioDrone.get('modelo').value
      this.drone.horasIniciais = this.formularioDrone.get('horasIniciais').value
      this.drone.observacoes = this.formularioDrone.get('observacoes').value
      this.enviando = true;
      this.dadosService.atualizarDrone(this.drone).then(drone => {
        this.drones[this.drones.findIndex(d => d.id === this.drone.id)] = this.drone;
        this.enviando = false;
        this.formularioDrone.reset();
        this.dialogRef.close(this.drones);
      });
    }
  }

}
