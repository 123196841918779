import { Component, OnInit } from '@angular/core';
import { AutenticaService } from '../../servicos/autentica.service';
import { DadosUsuario } from '../../modelos/modelos';
import { Router } from '@angular/router';

@Component({
  selector: 'app-barra',
  templateUrl: './barra.component.html',
  styleUrls: ['./barra.component.scss']
})
export class BarraComponent implements OnInit {
  usuario: DadosUsuario = null;
  permissoes = DadosUsuario.Permissoes;
  carregando = true;


  constructor(private autenticador: AutenticaService, public router: Router) { }

  ngOnInit(): void {
    this.autenticador.usuarioObservavel().subscribe(u => {this.usuario = u; this.carregando = false});
  }

  sair() {
    this.autenticador.sair().then(u => this.router.navigate(['/entrar']));
  }

}
