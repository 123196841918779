import { LimitesDrone, LimitesBateria } from './../modelos/modelos';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { Empresa, Fazenda, Talhao, Voo, DadosUsuario, Drone, Dispenser, Bateria } from '../modelos/modelos';
import { SwUpdate } from '@angular/service-worker';

import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DadosService {
  SERVER_URL: string = "https://aeroagro.ddns.net";

  constructor(private bd: AngularFirestore, private httpClient: HttpClient, private st: AngularFireStorage, private swUpdate: SwUpdate, private snackbar: MatSnackBar) {
    this.swUpdate.available.subscribe(evt => {
      this.bd = null;
      const snack = this.snackbar.open('Atualização disponível', 'Atualizar', { duration: 20000 });
      setTimeout(() => {
        window.location.reload();
      }, 20000);
      snack.onAction().subscribe(() => {
        window.location.reload();
      });
    });
  }

  criarEmpresa(empresa: Empresa, logo: File): Promise<Empresa> {
    return new Promise(c => {
      if (empresa.quantidades) {
        empresa.quantidades = empresa.quantidades.map(d => numero(d));
      }
      if (logo) {
        this.salvarArquivo('logo/' + logo.name, logo).then(url => {
          empresa.logo = url;
          this.bd.collection('empresas').add(empresa).then(a => c(adi(empresa, a)));
        });
      } else {
        delete empresa.logo;
        this.bd.collection('empresas').add(empresa).then(a => c(adi(empresa, a)));
      }
    });
  }

  criarFazenda(empresa: Empresa, fazenda: Fazenda): Promise<string> {
    return new Promise(c => this.bd.collection(empresa.id + '/fazendas').add(dep(fazenda)).then(a => c(a.path)));
  }

  criarTalhao(fazenda: Fazenda, talhao: Talhao): Promise<string> {
    return new Promise(c => this.bd.collection(fazenda.id + '/talhoes').add(dep(talhao)).then(a => c(a.path)));
  }

  criarVoo(talhao: Talhao, voo: Voo): Promise<string> {
    return new Promise(c => this.bd.collection(talhao.id + '/voos').add(dep(voo)).then(a => {
      if(voo.droneID){
        this.bd.collection('drones').doc(voo.droneID).get().subscribe(drone => {
          let vooRef = { voo: a.path, horario: voo.horarioVoo };
          let motHel = { voo: a.path, horarioVoo: voo.horarioVoo, horarioFim: voo.horarioFim}
          if (drone.exists) {
            this.bd.collection('drones').doc(voo.droneID).collection('voos').doc(a.id).set(vooRef).then();
            this.bd.collection('drones').doc(voo.droneID).collection('voosHelice').doc(a.id).set(motHel).then();
            this.bd.collection('drones').doc(voo.droneID).collection('voosMotor').doc(a.id).set(motHel).then();
          } else {
            this.bd.collection('drones').doc(voo.droneID).set({ sn: voo.droneID, modelo: voo.droneTipo }).then(() => {
              this.bd.collection('drones').doc(voo.droneID).collection('voos').doc(a.id).set(vooRef).then();
              this.bd.collection('drones').doc(voo.droneID).collection('voosHelice').doc(a.id).set(motHel).then();
              this.bd.collection('drones').doc(voo.droneID).collection('voosMotor').doc(a.id).set(motHel).then();
            })
          }
        });
      }
      
      if(voo.baterias?.length){
        voo.baterias.forEach(bateria => {
          this.bd.collection('baterias').doc(bateria.id).get().subscribe(b => {
            let vooRef = { voo: a.path, horario: voo.horarioVoo, soh: bateria.soh };
            if (b.exists) {
              let bultimo = b.data();
              if (!bultimo.ultimoHorarioVoo || bultimo.ultimoHorarioVoo < voo.horarioVoo) {
                bultimo.ultimoHorarioVoo = voo.horarioVoo;
                bultimo.soh = bateria.soh;
                this.bd.collection('baterias').doc(bateria.id).set(bultimo).then();
              }
              this.bd.collection('baterias').doc(bateria.id).collection('voos').doc(a.id).set(vooRef).then();
            } else {
              this.bd.collection('baterias').doc(bateria.id).set({ sn: bateria.id, ultimoHorarioVoo: voo.horarioVoo, soh: bateria.soh }).then(() =>
                this.bd.collection('baterias').doc(bateria.id).collection('voos').doc(a.id).set(vooRef).then())
            }
          });
        });
      }
      
      c(a.path);
    }));
  }

  recriarVoo(voo: Voo): Promise<Voo> {
    return new Promise(c => this.bd.doc(voo.id).set(dep(voo)).then(a => {
      this.bd.collection('drones').doc(voo.droneID).get().subscribe(drone => {
        let vooRef = { voo: voo.id, horario: voo.horarioVoo };
        let motHel = { voo: voo.id, horarioVoo: voo.horarioVoo, horarioFim: voo.horarioFim}
        if (drone.exists) {
          this.bd.collection('drones').doc(voo.droneID).collection('voos').doc(this.bd.doc(voo.id).ref.id).set(vooRef).then();
          this.bd.collection('drones').doc(voo.droneID).collection('voosHelice').doc(this.bd.doc(voo.id).ref.id).set(motHel).then();
          this.bd.collection('drones').doc(voo.droneID).collection('voosMotor').doc(this.bd.doc(voo.id).ref.id).set(motHel).then();
        } else {
          this.bd.collection('drones').doc(voo.droneID).set({ sn: voo.droneID, modelo: voo.droneTipo }).then(() =>
            this.bd.collection('drones').doc(voo.droneID).collection('voos').doc(this.bd.doc(voo.id).ref.id).set(vooRef).then())
            this.bd.collection('drones').doc(voo.droneID).collection('voosHelice').doc(this.bd.doc(voo.id).ref.id).set(motHel).then();
            this.bd.collection('drones').doc(voo.droneID).collection('voosMotor').doc(this.bd.doc(voo.id).ref.id).set(motHel).then();
        }
      });
      voo.baterias.forEach(bateria => {
        this.bd.collection('baterias').doc(bateria.id).get().subscribe(b => {
          let vooRef = { voo: voo.id, horario: voo.horarioVoo, soh: bateria.soh };
          if (b.exists) {
            let bultimo = b.data();
            if (!bultimo.ultimoHorarioVoo || bultimo.ultimoHorarioVoo < voo.horarioVoo) {
              bultimo.ultimoHorarioVoo = voo.horarioVoo;
              bultimo.soh = bateria.soh;
              this.bd.collection('baterias').doc(bateria.id).set(bultimo).then();
            }
            this.bd.collection('baterias').doc(bateria.id).collection('voos').doc(this.bd.doc(voo.id).ref.id).set(vooRef).then();
          } else {
            this.bd.collection('baterias').doc(bateria.id).set({ sn: bateria.id, ultimoHorarioVoo: voo.horarioVoo, soh: bateria.soh }).then(() =>
              this.bd.collection('baterias').doc(bateria.id).collection('voos').doc(this.bd.doc(voo.id).ref.id).set(vooRef).then())
          }
        });
      });
      c(voo);
    }));
  }

  criarDrone(drone: Drone): Promise<Drone> {
    return new Promise(c => this.bd.collection('drones').doc(drone.sn).set(drone).then(() => c(ad(drone, 'id', '/drones/' + drone.id))));
  }

  criarBateria(bateria: Bateria): Promise<Bateria> {
    return new Promise(c => this.bd.collection('baterias').doc(bateria.sn).set(bateria).then(() => c(ad(bateria, 'id', '/baterias/' + bateria.id))));
  }

  criarLimitesDrone(limitesDrone: LimitesDrone): Promise<LimitesDrone> {
    return new Promise(c => this.bd.collection('dados').doc("limiteDrone" +limitesDrone.modelo).set(limitesDrone).then(() => c(ad(limitesDrone, 'id', '/dados/' + limitesDrone.modelo))));
  }

  criarLimitesBateria(limitesBateria: LimitesBateria): Promise<LimitesBateria> {
    return new Promise(c => this.bd.collection('dados').doc("limiteBateria").set(limitesBateria).then(() => c(ad(limitesBateria, 'id', '/dados/'))));
  }  

  criarDispenser(dispenser: Dispenser): Promise<Dispenser> {
    return new Promise(c => this.bd.collection('dispensers').add(dispenser).then(a => c(adi(dispenser, a))));
  }

  listarEmpresas(): Observable<Empresa[]> {
    return this.bd.collection('empresas').get().pipe(map(query => query.docs.map(o => adp(o))));
  }

  listarDrones(): Observable<Drone[]> {
    return new Observable(observador => this.bd.collection('drones').get().subscribe(drones => observador.next(
      drones.docs.map(drone => adp(drone)))));
  }

  listarLimitesDrones(): Observable<LimitesDrone[]> {
    return new Observable(observador => this.bd.collection('dados').get().subscribe(limitesDrones => observador.next(
      limitesDrones.docs.map(limitesDrones => adp(limitesDrones)))));
  }

  listarLimiteBateria(): Observable<LimitesBateria> {
    return this.bd.doc<LimitesBateria>('dados/limiteBateria').valueChanges();
  }

  listarBaterias(): Observable<Bateria[]> {
    return new Observable(observador => this.bd.collection('baterias').get().subscribe(baterias => observador.next(
      baterias.docs.map(bateria => adp(bateria)))));
  }

  listarDispensers(): Observable<Dispenser[]> {
    return new Observable(observador => this.bd.collection('dispensers').get().subscribe(dispensers => observador.next(
      dispensers.docs.map(dispenser => adp(dispenser)))));
  }


  verEmpresa(empresaId): Promise<Empresa> {
    return new Promise(c => this.bd.doc(empresaId).get().subscribe(e => {
      let empresa = adp(e) as Empresa;
      if (empresa.quantidades) {
        empresa.quantidades = empresa.quantidades.map(q => numero(q));
      }
      c(empresa);
    }));
  }

  verFazendas(listaFazendas: Fazenda[], carregarRecursivo?: boolean): Observable<Fazenda[]> {
    return new Observable(c => {
      let fazendas = [];
      let i = 0;
      let deletada = 0;
      listaFazendas = listaFazendas.filter(f => f.id);
      listaFazendas.forEach(fi => this.bd.doc(fi.id).get().subscribe(faz => {
        if (faz.data()) {
          let fazenda: Fazenda = adp(faz);
          fazendas.push(fazenda);
          if (carregarRecursivo) {
            this.listarTalhoes(fazenda, carregarRecursivo).subscribe(
              talhoes => fazenda.talhoes = talhoes ? talhoes : [],
              err => c.error(err),
              () => {
                i++;
                if (i == listaFazendas.length - deletada) {
                  c.complete();
                }
              });
          } else {
            i++;
            if (i == listaFazendas.length - deletada) {
              c.complete();
            }
          }
          c.next(fazendas);
        } else {
          deletada++;
          if (i == listaFazendas.length - deletada) {
            c.complete();
          }
        }
      }, err => console.log(err)));
      if (listaFazendas.length === 0) {
        c.complete();
      }
    });
  }

  verFazendasComVoos(usuario: DadosUsuario): Observable<Fazenda[]> {
    return new Observable(observador => {
      let fazendasTotais: Fazenda[] = [];
      let i = 0;
      if (usuario.permissoes.includes(DadosUsuario.Permissoes.Cliente) || usuario.permissoes.includes(DadosUsuario.Permissoes.Observador)) {
        this.listarFazendas(usuario.empresa, true).subscribe(fazendas => {
          fazendasTotais = fazendasTotais.concat(fazendas.filter(f => fazendasTotais.find(fi => fi.id === f.id) === undefined));
          observador.next(fazendasTotais);
        },
          err => observador.error(err),
          () => {
            i++;
            if (i === 2) {
              observador.complete();
            }
          });
      }
      this.verFazendas(usuario.fazendas, true).subscribe(fazendas => {
        fazendasTotais = fazendasTotais.concat(fazendas.filter(f => fazendasTotais.find(fi => fi.id === f.id) === undefined));
        observador.next(fazendasTotais);
      },
        err => observador.error(err),
        () => {
          i++;
          if (i === 2) {
            observador.complete();
          }
        });
    });
  }

  listarEmpresasCompletas(carregarRecursivo?: boolean): Observable<Empresa[]> {
    return new Observable(observador => this.bd.collection('empresas').get().subscribe(query => {
      let empresas: Empresa[] = query.docs.map(o => adp(o));
      observador.next(empresas);
      if (carregarRecursivo) {
        let i = 0;
        empresas.forEach(empresa => this.listarFazendas(empresa, carregarRecursivo).subscribe(
          fazendas => empresa.fazendas = fazendas ? fazendas : [],
          err => observador.error(err),
          () => {
            i++;
            if (i === empresas.length) {
              observador.complete();
            }
          }));
          if (empresas.length === 0) {
            observador.complete();
          }
      } else {
        observador.complete();
      }
    }));
  }

  listarFazendas(empresa: Empresa, carregarRecursivo?: boolean): Observable<Fazenda[]> {
    return new Observable(observador => this.bd.collection(empresa.id + '/fazendas').get().subscribe(query => {
      let fazendas: Fazenda[] = query.docs.map(o => adp(o));
      observador.next(fazendas);
      if (carregarRecursivo) {
        let i = 0;
        fazendas.forEach(fazenda => this.listarTalhoes(fazenda, carregarRecursivo).subscribe(
          talhoes => fazenda.talhoes = talhoes ? talhoes : [],
          err => observador.error(err),
          () => {
            i++;
            if (i === fazendas.length) {
              observador.complete();
            }
          }));
          if (fazendas.length === 0) {
            observador.complete();
          }
      } else {
        observador.complete();
      }
    }));
  }

  listarTalhoes(fazenda: Fazenda, carregarRecursivo?: boolean): Observable<Talhao[]> {
    return new Observable(observador => this.bd.collection(fazenda.id + '/talhoes').get().subscribe(query => {
      let talhoes: Talhao[] = query.docs.map(o => adp(o));
      observador.next(talhoes);
      if (carregarRecursivo) {
        let i = 0;
        talhoes.forEach(talhao => this.listarVoos(talhao).subscribe(
          voos => {
            talhao.voos = voos ? voos : [];
            talhao.voos.forEach(v => v.talhao = talhao);
            talhao.fazenda = fazenda;
          },
          err => observador.error(err),
          () => {
            i++;
            if (i === talhoes.length) {
              observador.complete();
            }
          }));
          if (talhoes.length === 0) {
            observador.complete();
          }
      } else {
        observador.complete();
      }
    }));
  }

  listarVoos(talhao: Talhao): Observable<Voo[]> {
    return this.bd.collection(talhao.id + '/voos').get().pipe(map(query => query.docs.map(o => adp(o))));
  }

  excluirEmpresa(empresa: Empresa): Promise<void> {
    return this.bd.doc(empresa.id).delete();
  }

  excluirFazenda(fazenda: Fazenda): Promise<void> {
    return this.bd.doc(fazenda.id).delete();
  }

  excluirTalhao(talhao: Talhao): Promise<void> {
    return this.bd.doc(talhao.id).delete();
  }

  excluirVoo(voo: Voo): Promise<void> {
    return this.bd.doc(voo.id).delete();
  }

  excluirDrone(drone: Drone): Promise<void> {
    return this.bd.doc(drone.id).delete();
  }

  excluirOperador(operador: DadosUsuario): Promise<void> {
    return this.bd.doc(operador.id).delete();
  }

  excluirBateria(bateria: Bateria): Promise<void> {
    return this.bd.doc(bateria.id).delete();
  }

  excluirDispenser(dispenser: Dispenser): Promise<void> {
    return this.bd.doc(dispenser.id).delete();
  }

  atualizarEmpresa(empresa: Empresa, logo?: File): Promise<Empresa> {
    return new Promise(c => {
      if (logo) {
        this.salvarArquivo('logo/' + logo.name, logo).then(url => {
          empresa.logo = url;
          this.bd.doc(empresa.id).set(empresa).then(() => c(empresa));
        });
      } else {
        if (!empresa.logo) delete empresa.logo;
        this.bd.doc(empresa.id).set(empresa).then(() => c(empresa));
      }
    });
  }

  atualizarFazenda(fazenda: Fazenda): Promise<void> {
    return this.bd.doc(fazenda.id).set(dep(fazenda));
  }

  atualizarTalhao(talhao: Talhao): Promise<void> {
    return this.bd.doc(talhao.id).set(dep(talhao));
  }

  atualizarVoo(voo: Voo): Promise<void> {
    return this.bd.doc(voo.id).set(dep(voo));
  }

  atualizarDrone(drone: Drone): Promise<void> {
    return this.bd.doc(drone.id).set(dep(drone));
  }

  atualizarOperador(operador: DadosUsuario): Promise<void> {
    return this.bd.doc(operador.id).set(dep(operador));
  }

  atualizarBateria(bateria: Bateria): Promise<void> {
    return this.bd.doc(bateria.id).set(dep(bateria));
  }

  atualizarDispenser(dispenser: Dispenser): Promise<void> {
    return this.bd.doc(dispenser.id).set(dep(dispenser));
  }

  validarVoo(voo: Voo, comentarios: any): Promise<void> {
    voo.validado = true;
    voo.comentarios = comentarios;
    return this.bd.doc(voo.id).set(de(de(voo, 'id'), 'talhao'));
  }

  public upload(data) {
    let uploadURL = `${this.SERVER_URL}/uploadscript.php`;

    return this.httpClient.post<any>(uploadURL, data, {
      headers: { "ngsw-bypass": "" },
      reportProgress: true,
      observe: 'events',
      responseType: 'text' as 'json'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return { status: 'end', message: event.body };
        case HttpEventType.Sent:
        case HttpEventType.ResponseHeader:
        case HttpEventType.DownloadProgress:
          return { status: '', message: '' };
        default:
          return { status: 'error', message: `Unhandled event: ${event.type}` };
      }
    })
    );
  }

  public download(url: string, text?: boolean) {
    if (text)
      return this.httpClient.get(url, { observe: 'body', responseType: 'text' });
    return this.httpClient.get<any>(url);
  }

  public salvarArquivo(caminho, arquivo): Promise<string> {
    return new Promise(chamada => this.st.upload(caminho, arquivo).then(res => res.ref.getDownloadURL().then(url => chamada(url))));
  }

}

function adi(o, a) {
  return ad(o, 'id', a.path);
}

function adp(o) {
  return ad(o.data(), 'id', o.ref.path);
}

function dep(o) {
  return de(o, 'id');
}

function ad(o, nome, valor) {
  let clone = Object.assign({}, o);
  clone[nome] = valor;
  return clone;
}

function de(o, nome) {
  let clone = Object.assign({}, o);
  delete clone[nome];
  return clone;
}

export function numero(i) {
  if (i === undefined || i === null)
    return 0;
  if (typeof (i) === 'number')
    return i;
  let a = Number(i);
  if (a) {
    return a;
  }
  a = Number(i.replace(',', '.'));
  if (a) {
    return a;
  }
  console.log("não é número", i);
  return 0;
}
