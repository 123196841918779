<mat-card>
    <form [formGroup]="formularioDrone" (ngSubmit)="criarDrone()">
        <h1 *ngIf="drone" class="titulo"> Atualização de Drone </h1>
        <h1 *ngIf="!drone" class="titulo"> Cadastro de Drone </h1>
        <mat-form-field>
          <input type="text" matInput required placeholder="Nome Fantasia" formControlName="nome">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput required placeholder="Número de Série (SN)" formControlName="sn">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput required placeholder="Modelo do Drone" formControlName="modelo">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Horas de Voo Iniciais" formControlName="horasIniciais" [imask]="{mask: Number}">
        </mat-form-field>
        <mat-form-field>
          <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
        </mat-form-field>
        <div class="button">
            <button type="submit" *ngIf="!drone" mat-raised-button [disabled]="enviando">Cadastrar Drone</button>
            <button type="submit" *ngIf="drone" mat-raised-button [disabled]="enviando">Salvar</button>
        </div>
      </form>
  </mat-card>