<mat-card style="padding-top: 0px;">
  <h1 class="noperm" *ngIf="usuarioAtual && !usuarioAtual?.permissoes.includes(p.Admin) && !usuarioAtual?.permissoes.includes(p.Cliente)">Para conseguir ver os usuários, peça permissão a um administrador </h1>
  <mat-tab-group *ngIf="usuarioAtual?.permissoes.includes(p.Admin) || usuarioAtual?.permissoes.includes(p.Cliente)">
  <mat-tab *ngIf="usuarioAtual?.permissoes.includes(p.Admin)" label="Empresas">
  <mat-card *ngIf="usuarioAtual?.permissoes.includes(p.Admin)">

    <mat-card-title>Empresas</mat-card-title>
    <mat-horizontal-stepper [linear]="true" #stepper>

      <mat-step label="Empresas" state="primeiro">

          <div class="tabela-container mat-elevation-z8">
            <table #tabEmpresa mat-table [dataSource]="dataSourceEmpresas" matSort>
    
              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Empresa</b></th>
                <td mat-cell class="mat-tab-row" *matCellDef="let empresa" (click)="carregarFazendas(empresa, stepper)">{{empresa.nome}}</td>
              </ng-container>
  
              <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef><b>CNPJ</b></th>
                <td mat-cell *matCellDef="let empresa">{{mascaraCnpj.resolve(empresa.cnpj)}}</td>
              </ng-container>

              <ng-container matColumnDef="opcoes">
                <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
                <td mat-cell *matCellDef="let empresa">
                  <button mat-icon-button (click)="excluirEmpresa(empresa)">
                      <mat-icon color="warn">delete</mat-icon>
                  </button>
    
                  <button mat-icon-button style="color: rgb(0, 180, 0);" (click)="editarEmpresa(empresa)" >
                      <mat-icon>create</mat-icon>
                  </button>
                </td>
              </ng-container>
      
              <tr mat-header-row *matHeaderRowDef="displayedColumnsEmpresas;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsEmpresas;"  ></tr>
            </table>
          </div>

          
          <app-editar-empresa></app-editar-empresa>
      </mat-step>

      <mat-step label="Fazendas" state="segundo">
        <h2 *ngIf="!empresaSelec">Selecione uma empresa</h2>

        <img class="imagem" *ngIf="empresaSelec && empresaSelec.logo" [src]="empresaSelec.logo">

        <div *ngIf="empresaSelec" class="tabela-container mat-elevation-z8">
          <table #tabFazenda mat-table [dataSource]="dataSourceFazendas" matSort>
  
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Fazenda</b></th>
              <td mat-cell  class="mat-tab-row" (click)="carregarTalhoes(fazenda, stepper)" *matCellDef="let fazenda">{{fazenda.nome}}</td>
            </ng-container>

            <ng-container matColumnDef="descri">
              <th mat-header-cell *matHeaderCellDef><b>Descrição</b></th>
              <td mat-cell *matCellDef="let fazenda">{{fazenda.descri}}</td>
            </ng-container>

            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
              <td mat-cell *matCellDef="let fazenda">
                <button mat-icon-button (click)="excluirFazenda(fazenda, empresaSelec)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsFazendas;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsFazendas;"  ></tr>
          </table>
        </div>

        <form *ngIf="empresaSelec" [formGroup]="fazendaForm" (ngSubmit)="criarFazenda(empresaSelec)">
          <mat-form-field>
            <input type="text" matInput required placeholder="Nome da Fazenda" formControlName="nome">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Descrição" formControlName="descri">
          </mat-form-field>
          <div class="button">
            <button mat-button matStepperPrevious>Voltar</button>
            <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Fazenda</button>
          </div>
        </form>
        
      </mat-step>

      <mat-step label="Talhões" state="terceiro">

        <h2 *ngIf="!fazendaSelec">Selecione uma fazenda</h2>

        <h2 *ngIf="fazendaSelec">Talhões da Fazenda {{fazendaSelec.nome}}</h2>

        <div *ngIf="fazendaSelec" class="tabela-container mat-elevation-z8">
          <table #tabTalhao mat-table [dataSource]="dataSourceTalhoes" matSort>
  
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Talhao</b></th>
              <td mat-cell *matCellDef="let talhao">{{talhao.nome}}</td>
            </ng-container>

            <ng-container matColumnDef="descri">
              <th mat-header-cell *matHeaderCellDef><b>Descrição</b></th>
              <td mat-cell *matCellDef="let talhao">{{talhao.descri}}</td>
            </ng-container>

            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
              <td mat-cell *matCellDef="let talhao">
                <button mat-icon-button (click)="excluirTalhao(talhao, fazendaSelec)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTalhoes;"></tr>
            <tr mat-row  *matRowDef="let row; columns: displayedColumnsTalhoes;"></tr>
          </table>
        </div>

        <form *ngIf="fazendaSelec" [formGroup]="talhaoForm" (ngSubmit)="criarTalhao(fazendaSelec)">
          <mat-form-field>
            <input type="text" matInput required placeholder="Nome do Talhão" formControlName="nome">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Descrição" formControlName="descri">
          </mat-form-field>
          <mat-form-field>
            <ngx-mat-file-input formControlName="arquivo" placeholder="Arquivo KML" accept=".kml"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
          </mat-form-field>
          <div class="button">
            <button mat-button matStepperPrevious>Voltar</button>
            <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Talhao</button>
          </div>
        </form>
      </mat-step>

      
        
      <ng-template matStepperIcon="primeiro" matStepLabel>
        <mat-icon>business</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="segundo" matStepLabel >
        <mat-icon>grass</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="terceiro" matStepLabel>
        <mat-icon>view_comfy</mat-icon>
      </ng-template>

    </mat-horizontal-stepper>

    <!--<mat-accordion>
      <mat-expansion-panel *ngFor="let empresa of empresas" (opened)="carregarFazendas(empresa)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{empresa.nome}}
          </mat-panel-title>
          <mat-panel-description>
            {{mascaraCnpj.resolve(empresa.cnpj)}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <img *ngIf="empresa.logo" [src]="empresa.logo">

        <h2>Fazendas</h2>
        <mat-expansion-panel *ngFor="let fazenda of fazendas[empresa.id]" (opened)="carregarTalhoes(fazenda)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{fazenda.nome}}
            </mat-panel-title>
            <mat-panel-description>
              {{ fazenda.descri }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <h3>Talhões</h3>
          <mat-expansion-panel *ngFor="let talhao of talhoes[fazenda.id]">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{talhao.nome}}
              </mat-panel-title>
              <mat-panel-description>
                {{ talhao.descri }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="excluir">
              <button type="submit" mat-raised-button (click)="excluirTalhao(talhao, fazenda)">Excluir talhão {{talhao.nome}}</button>
            </div>
          </mat-expansion-panel>

          <form [formGroup]="talhaoForm" (ngSubmit)="criarTalhao(fazenda)">
            <mat-form-field>
              <input type="text" matInput required placeholder="Nome do Talhão" formControlName="nome">
            </mat-form-field>
            <mat-form-field>
              <input type="text" matInput placeholder="Descrição" formControlName="descri">
            </mat-form-field>
            <mat-form-field>
              <ngx-mat-file-input formControlName="arquivo" placeholder="Arquivo KML" accept=".kml"></ngx-mat-file-input>
              <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
            <div class="button">
              <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Talhao</button>
            </div>
          </form>

          <div class="excluir">
            <button type="submit" mat-raised-button (click)="excluirFazenda(fazenda, empresaSelec)">Excluir fazenda {{fazenda.nome}}</button>
          </div>
        </mat-expansion-panel>

        <form [formGroup]="fazendaForm" (ngSubmit)="criarFazenda(empresa)">
          <mat-form-field>
            <input type="text" matInput required placeholder="Nome da Fazenda" formControlName="nome">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Descrição" formControlName="descri">
          </mat-form-field>
          <div class="button">
            <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Fazenda</button>
          </div>
        </form>
        <div class="excluir">
          <button type="submit" mat-raised-button (click)="editarEmpresa(empresa)">Editar</button>
          <button type="submit" mat-raised-button (click)="excluirEmpresa(empresa)">Excluir empresa {{empresa.nome}}</button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>-->
    <p>
    </p>
  </mat-card>
  </mat-tab>

  <mat-tab label="Usuários">
  <mat-card>
    <mat-card-title>Usuários</mat-card-title>
    <table #tabUsuario mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{nomes[column]}} </th>
        <td mat-cell *matCellDef="let element"> {{imprimirAtributo(element, column)}} </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div>
              <mat-checkbox *ngFor="let permissao of permissoes" [checked]="element.permissoes.includes(p[permissao])" (change)="mudarPermissao($event, element, permissao)">{{permissao}}</mat-checkbox>
            </div>
            <span class="example-spacer"></span>
            <!--
            <div *ngIf="element.permissoes.includes(p.Piloto)">
              Piloto:
              <mat-chip-list>
                <mat-chip *ngFor="let fazenda of element.fazendas">
                  {{fazenda.nome}} <mat-icon matChipRemove (click)="removerFazenda(element, fazenda.id)">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
              <mat-form-field>
                <mat-select matNativeControl placeholder="Adicionar fazenda" (selectionChange)="adicionarFazenda(element, $event)">
                  <mat-option *ngFor="let faz of fazendasNovas(element)" [value]="faz.id">{{ faz.nome }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            -->
            <div *ngIf="element.permissoes.includes(p.Piloto)">
            <button mat-icon-button style="color: rgb(0, 180, 0);" (click)="dialogUsuario(element)">
              <mat-icon>create</mat-icon>
            </button>
            </div>
            <div>
            <button mat-icon-button (click)="excluirUsuario(element)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

  </mat-card>
  </mat-tab>
  </mat-tab-group>
</mat-card>
