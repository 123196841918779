import { TalhaoUsuarioDialogComponent } from './Dialogs/talhao-usuario-dialog/talhao-usuario-dialog.component';
import { Component, OnInit, EventEmitter, Inject, ViewChild } from '@angular/core';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DadosUsuario, Empresa, Fazenda, Talhao } from 'src/app/modelos/modelos';
import { DadosService } from 'src/app/servicos/dados.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as IMask from 'imask';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class PerfilUsuarioComponent implements OnInit {

  empresaSelec: Empresa;
  fazendaSelec: Fazenda;
  usuarioAtual: DadosUsuario;
  usuarios: DadosUsuario[];
  empresas: Empresa[];
  fazendas: Record<string, Fazenda[]> = {};
  talhoes: Record<string, Talhao[]> = {};
  enviando = false;
  p = DadosUsuario.Permissoes;
  permissoes = [this.p[DadosUsuario.Permissoes.Cliente], this.p[DadosUsuario.Permissoes.Observador]];

  columnsToDisplay = ['nome', 'email', 'empresa', 'cpf'];
  nomes = { nome: 'Nome', cpf: 'CPF', email: 'E-mail', empresa: 'Empresa' };
  dataSource: MatTableDataSource<DadosUsuario>;
  @ViewChild(('tabUsuario'), {read: MatSort,  static: false }) sortUsuario: MatSort;

  expandedElement: DadosUsuario | null;
  mascaraCnpj = IMask.createMask({ mask: '00.000.000/0000-00' });
  Number = Number;

  formulario: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    cnpj: new FormControl('', Validators.minLength(14)),
    endereco: new FormControl(''),
    responsavel: new FormControl(''),
    culturas: new FormControl(''),
    telefone: new FormControl(''),
    area: new FormControl(''),
    areaTipo: new FormControl(''),
    duracao: new FormControl(''),
    safra: new FormControl(''),
    doses: new FormControl(''),
    valor: new FormControl(''),
    logo: new FormControl('')
  });

  fazendaForm: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    descri: new FormControl(''),
  });

  talhaoForm: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    descri: new FormControl(''),
    arquivo: new FormControl('', Validators.required)
  });


  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  displayedColumnsEmpresas: string[] = ["nome", "cnpj", "opcoes"];
  dataSourceEmpresas: MatTableDataSource<Empresa>;
  @ViewChild(('tabEmpresa'), {read: MatSort,  static: false }) sortEmpresas: MatSort;

  displayedColumnsFazendas: string[] = ["nome", "descri", "opcoes"];
  dataSourceFazendas: MatTableDataSource<Fazenda>;
  @ViewChild(('tabFazenda'), {read: MatSort,  static: false }) sortFazendas: MatSort;

  displayedColumnsTalhoes: string[] = ["nome", "descri", "opcoes"];
  dataSourceTalhoes: MatTableDataSource<Talhao>;
  @ViewChild(('tabTalhao'), {read: MatSort,  static: false }) sortTalhoes: MatSort;

  constructor(
    private autenticador: AutenticaService, 
    private dadosService: DadosService,
    private router: Router, 
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) { }


  ngOnInit(): void {
    this.autenticador.usuarioAtual.then(u => {
      this.usuarioAtual = u
      if (this.usuarioAtual.permissoes.includes(DadosUsuario.Permissoes.Admin) || this.usuarioAtual.permissoes.includes(DadosUsuario.Permissoes.Cliente)) {
        this.autenticador.listarUsuarios().subscribe(usuarios => {
          this.usuarios = usuarios;
          this.atualizaTabUsuarios(usuarios)
        this.dataSource.sort = this.sortUsuario;
          if (!this.usuarioAtual.permissoes.includes(DadosUsuario.Permissoes.Admin)) {
            this.usuarios = this.usuarios.filter(ui => ui.empresa.id === this.usuarioAtual.empresa.id);
            this.atualizaTabUsuarios(this.usuarios)
          }if(this.usuarioAtual.cpf == '25963565843' || this.usuarioAtual.cpf =='09623571623'){
            this.permissoes = DadosUsuario.PermissoesArray;
          }
          else {
            this.permissoes = DadosUsuario.PermissoesArray.filter(p => p!='Monitoramento');
          }
          this.usuarios.forEach(u => this.dadosService.verEmpresa(u.empresa.id).then(e => u.empresa = e));
          this.usuarios.forEach(u => this.dadosService.verFazendas(u.fazendas).subscribe(fs => u.fazendas = fs));
        });
      }
      if (this.usuarioAtual.permissoes.includes(DadosUsuario.Permissoes.Admin)) {
        this.dadosService.listarEmpresasCompletas().subscribe(empresas => {
          this.empresas = empresas;
          this.atualizaTabEmpresas(empresas)
          this.empresas.forEach(e => this.carregarFazendas(e));
        });
      }
    });
  }


  atualizaTabUsuarios(usuarios: DadosUsuario[]) {
    this.dataSource = new MatTableDataSource<DadosUsuario>(usuarios)     
    this.dataSource.sort = this.sortUsuario;
    this.dataSource.sortingDataAccessor = (item, property) => {
      if(property == 'nome'){
        return item.nome;
      }
      else if(property == 'email'){
        return item.email;
      }
      else if(property == 'empresa'){
        return item.empresa.nome;
      }
      else if(property == 'cpf'){
        return item.cpf;
      }
    };
  }

  atualizaTabEmpresas(empresas: Empresa[]) {
    this.dataSourceEmpresas = new MatTableDataSource<Empresa>(empresas)
    this.dataSourceEmpresas.sortingDataAccessor = (item, property) => {
      return item.nome
    };
    this.dataSourceEmpresas.sort = this.sortEmpresas;
  }

  atualizaTabFazendas(fazendas: Fazenda[]) {
    this.dataSourceFazendas = new MatTableDataSource<Fazenda>(fazendas)
    this.dataSourceFazendas.sortingDataAccessor = (item, property) => {
      return item.nome;
    };
    this.dataSourceFazendas.sort = this.sortFazendas;
  }

  atualizaTabTalhoes(talhoes: Talhao[]) {
    this.dataSourceTalhoes = new MatTableDataSource<Talhao>(talhoes)
    this.dataSourceTalhoes.sortingDataAccessor = (item, property) => {
      return item.nome;
    };
    this.dataSourceTalhoes.sort = this.sortTalhoes;
  }


  excluirUsuario(usuario: DadosUsuario) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.autenticador.excluirUsuario(usuario.id);
        this.usuarios = this.usuarios.filter(u => u.id !== usuario.id);
        this.atualizaTabUsuarios(this.usuarios)
        if (this.usuarioAtual.id === usuario.id) {
          this.autenticador.sair();
        }
      }
    });
  }

  mudarPermissao(e: MatCheckboxChange, usuario: DadosUsuario, permissao: string) {
    usuario.permissoes = e.checked ? usuario.permissoes.concat([this.p[permissao]]).sort() : usuario.permissoes.filter(a => a !== this.p[permissao])
    this.autenticador.atualizaUsuario(usuario);
  }

  carregarFazendas(empresa: Empresa, stepper?: MatStepper){
    if(stepper){
      stepper.next();
      this.empresaSelec = empresa
    }
    this.dadosService.listarFazendas(empresa).subscribe(r => {
      this.fazendas[empresa.id] = r;

      if(stepper){
        this.atualizaTabFazendas(r)
      }
    });
  }

  carregarTalhoes(fazenda: Fazenda, stepper?: MatStepper) {
    this.fazendaSelec = fazenda;
    if(stepper)
      stepper.next();

    this.dadosService.listarTalhoes(fazenda).subscribe(r => {
      this.talhoes[fazenda.id] = r
      this.atualizaTabTalhoes(r)
    });
  }

  criarFazenda(empresa: Empresa) {
    if (this.fazendaForm.valid) {
      this.enviando = true;
      this.dadosService.criarFazenda(empresa, this.fazendaForm.value).then(a => {
        this.enviando = false;
        this.fazendaForm.reset();
        this.dadosService.listarFazendas(empresa).subscribe(r => {
          this.fazendas[empresa.id] = r;
          this.atualizaTabFazendas(r) 
        });
      })
    }
  }

  adicionarFazenda(u: DadosUsuario, e: MatSelectChange) {
    let faz = [].concat.apply([], Object.values(this.fazendas)).find(f => f.id === e.value);
    if (!faz) return;
    u.fazendas.push(faz);
    this.autenticador.atualizaUsuario(u);
  }

  removerFazenda(u: DadosUsuario, fid: string) {
    u.fazendas = u.fazendas.filter(f => f.id !== fid);
    this.autenticador.atualizaUsuario(u);
  }

  criarTalhao(fazenda: Fazenda) {
    if (this.talhaoForm.valid) {
      this.enviando = true;
      const arquivo = this.talhaoForm.get('arquivo').value.files[0];
      const leitor = new FileReader();
      let kml: Document;
      leitor.readAsText(arquivo);
      leitor.onloadend = () => {
        kml = new DOMParser().parseFromString(leitor.result.toString(), "text/xml");
        let coords = kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => new google.maps.LatLng(+t.split(',')[1], +t.split(',')[0]));
        let area = google.maps.geometry.spherical.computeArea(coords) / 10000;

        let novo = Object.assign({}, this.talhaoForm.value);
        this.dadosService.salvarArquivo('kmls/' + new Date().getTime() + '.kml', arquivo).then(url => {
          novo['arquivo'] = url;
          novo['area'] = area;
          this.dadosService.criarTalhao(fazenda, novo).then(a => {
            this.enviando = false;
            this.talhaoForm.reset();
            this.carregarTalhoes(fazenda)
          }
          );
        });
      }
    }
  }

  imprimirAtributo(usuario: DadosUsuario, atributo: string): string {
    switch (atributo) {
      case "cpf": return IMask.createMask({ mask: '000.000.000-00' }).resolve(usuario.cpf);
      case "empresa": return usuario.empresa.nome;
      default: return usuario[atributo];
    }
  }

  excluirEmpresa(empresa: Empresa) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirEmpresa(empresa).then(a => {
          this.empresas = this.empresas.filter(e => e.id !== empresa.id)
          this.atualizaTabEmpresas(this.empresas)
      })
      }
    });
  }

  editarEmpresa(empresa: Empresa) {
    const dialogRef = this.dialog.open(EditarPopUp, {data: empresa});
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        console.log('confirmou', confirmou);
        this.dadosService.listarEmpresasCompletas().subscribe(empresas => {
          this.empresas = empresas;
          this.atualizaTabEmpresas(empresas)
        });
      }
    });
  }

  excluirFazenda(fazenda: Fazenda, empresa: Empresa) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirFazenda(fazenda).then(a => {
          this.fazendas[empresa.id] = this.fazendas[empresa.id].filter(e => e.id !== fazenda.id)
          this.atualizaTabFazendas(this.fazendas[empresa.id])
        });
      }
    });
  }

  excluirTalhao(talhao: Talhao, fazenda: Fazenda) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirTalhao(talhao).then(a => {
          this.talhoes[fazenda.id] = this.talhoes[fazenda.id].filter(e => e.id !== talhao.id)
          this.atualizaTabTalhoes(this.talhoes[fazenda.id])
        });
      }
    });
  }

  fazendasNovas(usuario: DadosUsuario) {
    return [].concat.apply([], Object.values(this.fazendas)).filter(f => usuario.fazendas.find(fi => fi.id === f.id)===undefined).sort((a, b) => a.nome.localeCompare(b.nome))
  }

  dialogUsuario(usuario: DadosUsuario){
    console.log("entrou")
    console.log(usuario)
    this.dialog.open(TalhaoUsuarioDialogComponent,
      {data: {usuario: usuario, fazendas: this.fazendas},
       width: "750px"})
  }

}





@Component({
  selector: 'excluir-popup',
  template: `<h2 mat-dialog-title>Confirma a exclusão?</h2>
  <mat-dialog-content>A exclusão não pode ser revertida e os dados associados serão perdidos.</mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Voltar</button>
    <button mat-button [mat-dialog-close]="true">Excluir</button>
  </mat-dialog-actions>`,
})
export class ExcluirPopUp { }


@Component({
  selector: 'editar-empresa-popup',
  template: `
  <h2 mat-dialog-title>{{empresa.nome}}</h2>
  <mat-dialog-content>
  <app-editar-empresa [empresa]=empresa (empresaNova)=retornarEmpresa($event)></app-editar-empresa>
  </mat-dialog-content>
  `
})
export class EditarPopUp {
  Number = Number;

  constructor(@Inject(MAT_DIALOG_DATA) public empresa: Empresa, private dialogRef: MatDialogRef<EditarPopUp>) {
  }

  retornarEmpresa(emp) {
    this.dialogRef.close(emp);
  }

}
