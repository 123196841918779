import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-entrar',
  templateUrl: './entrar.component.html',
  styleUrls: ['./entrar.component.scss']
})
export class EntrarComponent implements OnInit {
  erro = '';

  formulario: FormGroup = new FormGroup({
    email: new FormControl('', Validators.email),
    senha: new FormControl(''),
  });
  
  @Input() error: string | null;

  constructor(public autenticador:AutenticaService, public router: Router, private _snackBar: MatSnackBar) {}

  ngOnInit () {

  }

  entrar() {
    if (this.formulario.valid) {
      this.autenticador.entrar(this.formulario.value)
      .then(res => {
        this.router.navigate(['/usuario']);
      }, err => {
        this.erro = err.message;
        this._snackBar.open(this.erro, "Ok", {duration: 7000});
      })
    }
  }

}
