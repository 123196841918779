import { Component, OnInit } from '@angular/core';
import { DadosService } from 'src/app/servicos/dados.service';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { DadosUsuario, Fazenda, Talhao, Voo, Empresa } from 'src/app/modelos/modelos';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExcluirPopUp } from '../perfil-usuario/perfil-usuario.component';
import { CamadaTexto } from '../cliente/cliente.component';
import pointOnFeature from '@turf/point-on-feature';
import centerOfMass from '@turf/center-of-mass';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['../../app.component.scss']
})
export class DebugComponent implements OnInit {
  pilotos: DadosUsuario[] = [];
  empresas: Empresa[] = [];
  fazendas: Fazenda[] = [];
  talhoes: Talhao[] = [];
  voos = [];

  constructor(private dadosService: DadosService, private autenticaService: AutenticaService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.autenticaService.listarOperadores().subscribe(pilotos => {
      this.pilotos = pilotos
      this.dadosService.listarEmpresasCompletas().subscribe(empresas => {
        this.empresas = empresas;
        //console.log(empresas);
        empresas.forEach(empresa => this.dadosService.listarFazendas(empresa).subscribe(fazendas => {
          this.fazendas = this.fazendas.concat(fazendas);
          //console.log(fazendas);
          fazendas.forEach(fazenda => this.dadosService.listarTalhoes(fazenda).subscribe(talhoes => {
            this.talhoes = this.talhoes.concat(talhoes);
            //console.log(talhoes);
            talhoes.forEach(talhao => this.dadosService.listarVoos(talhao).subscribe(voos => {
              this.voos = this.voos.concat(voos);
              voos.forEach(voo => {
                if (!voo.horarioLiga) {
                  console.log("Consertar ...")
                  console.log(voo);
                  /*
                  let nome = voo.arquivo.split('/')[4].split('.')[0]
                  this.dadosService.download('https://drones.gamdev.com.br/arqs/' + nome + '.json').subscribe(o => {
                    let url = 'https://drones.gamdev.com.br/arqs/' + nome + '.geojson';
                    let v = voo;
                    if (!voo.status) {
                      v = Object.assign(v, {
                        piloto: pilotos[2],
                        operador: pilotos[2],
                        area: talhao.area,
                        status: 'completada',
                        tipoVoo: 'real',
                        tipoMissao: 'cotésia',
                        cultura: 'Cana',
                        dadosConfirmados: false
                      });
                    }
                    v = Object.assign(v, o);
                    this.dadosService.recriarVoo(v).then(a => console.log(v))
                  });*******************************/
                }
              });
              //console.log(voos);
            }));
          }));
        }));
      });
    });
  }

  logar() {
    console.log(this.pilotos);
    console.log(this.empresas);
    console.log(this.fazendas);
    console.log(this.talhoes);
    console.log(this.voos);
  }

  consertarPilotos() {
    this.voos.forEach(voo => {
      if (voo.piloto && typeof voo.piloto === "string") {
        let piloto = this.pilotos.find(p => p.apelido = voo.piloto);
        let operador = this.pilotos.find(p => p.apelido = voo.operador);
        voo.piloto = piloto;
        voo.operador = operador;
        this.dadosService.atualizarVoo(voo).then(() => console.log(voo));
      }
    });
  }

  tid(index, item) {
    if (!item) return null;
    return item.id;
  }

}