import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { DadosUsuario, Empresa } from '../modelos/modelos';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutenticaService {

  private _usuario: firebase.User;

  constructor(
    private autenticador: AngularFireAuth, 
    private bd: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.autenticador.auth.onAuthStateChanged(u => this._usuario = u);
  }

  registrar(objeto) {
    return new Promise<any>((resolve, reject) => {
      this.autenticador.auth.createUserWithEmailAndPassword(objeto.email, objeto.senha)
        .then(res => {
          if (res) {
            this._usuario = res.user;
            this._usuario.sendEmailVerification();
            this.bd.collection('usuarios').doc(res.user.uid).set({
              email: objeto.email,
              nome: objeto.nome,
              cpf: objeto.cpf,
              empresa: this.bd.doc(objeto.empresa).ref,
              permissoes: []
            }).then((s) => resolve(res));
          }
        }, err => reject(err))
    })
  }

  entrar(objeto) {
    return new Promise<any>((resolve, reject) => {
      this.autenticador.auth.signInWithEmailAndPassword(objeto.email, objeto.senha)
        .then(res => {
          this._usuario = res? res.user : null;
          resolve(res);
        }, err => reject(err))
    })
  }

  sair() {
    return new Promise((resolve, reject) => {
      if (this.autenticador.auth.currentUser) {
        this.autenticador.auth.signOut();
        this._usuario = null;
        resolve(()=>{});
      }
      else {
        reject();
      }
    });
  }

  confirmaEmail() {
    return this._usuario.sendEmailVerification();
  }

  emailConfirmado(): Promise<boolean> {
    if (this._usuario === undefined)
      return new Promise<boolean>(a => {let au = this.autenticador.auth.onAuthStateChanged(u => {a(u.emailVerified); au()})});
    return new Promise(c => c(this._usuario.emailVerified));
  }

  estaAutenticado(): Promise<boolean> {
    if (this._usuario === undefined)
      return new Promise<boolean>(a => {let au = this.autenticador.auth.onAuthStateChanged(u => {a(u != null); au()})});
    return new Promise<boolean>(a => a(this._usuario != null));
  }

  get usuarioAtual(): Promise<DadosUsuario> {
    return new Promise<DadosUsuario>(resp => this.estaAutenticado().then(autenticado => {
      if (autenticado) {
        this.bd.collection('usuarios').doc(this._usuario.uid).get().toPromise().then(a => resp(this.construirDadosUsuario(a.id, a.data())))
      } else {
        resp(null);
      }
    }));
  }

  usuarioObservavel(): Observable<DadosUsuario> {
    return new Observable(obs => this.autenticador.auth.onAuthStateChanged(u => {
      let subs = null;
      if (u == null) {
        if (subs) subs.unsubscribe();
        obs.next(null);
      } else {
        subs = this.bd.collection('usuarios').doc(u.uid).get().subscribe(a => obs.next(this.construirDadosUsuario(a.id, a.data())));
      }
    }));
  }

  excluirUsuario(id: string) {
    this.bd.collection('usuarios').doc(id).delete().then();
  }

  atualizaUsuario(usuario: DadosUsuario) {
    if(usuario.permissoes.includes(DadosUsuario.Permissoes.Piloto)){
      usuario.numeroPiloto = usuario.numeroPiloto ? usuario.numeroPiloto : 0,
      usuario.horasIniciais = usuario.numeroPiloto ? usuario.horasIniciais : 0
    }
    this.bd.collection('usuarios').doc(usuario.id).set(this.exportarDadosUsuario(usuario)).then(()=> {
      console.log("terminou")
    }
    );
  }

  listarUsuarios(): Observable<DadosUsuario[]> {
    return new Observable(observador => this.bd.collection('usuarios').get().subscribe(usuarios => observador.next(
      usuarios.docs.map(usuario => this.construirDadosUsuario(usuario.id, usuario.data())))));
  }

  listarOperadores(): Observable<DadosUsuario[]> {
    return new Observable(observador => this.bd.collection('usuarios').get().subscribe(usuarios => observador.next(
      usuarios.docs.filter(usuario => usuario.get('permissoes').includes(DadosUsuario.Permissoes.Piloto))
                   .map(usuario => this.construirDadosUsuario(usuario.id, usuario.data())))));
  }

  private construirDadosUsuario(id: string, dado: any): DadosUsuario {
    return {
      id: id,
      email: dado.email,
      nome: dado.nome,
      apelido: 'apelido' in dado? dado.apelido : dado.nome.split(' ')[0] + " " + dado.nome.split(' ')[1],
      cpf: dado.cpf,
      numeroPiloto: dado.numeroPiloto,
      horasIniciais: dado.horasIniciais,
      empresa: {id: dado.empresa.path} as Empresa,
      fazendas: 'fazendas' in dado? dado.fazendas.map(f => ({id: f.path})) : [],
      permissoes: 'permissoes' in dado? dado.permissoes : []
    };
  }

  private exportarDadosUsuario (usuario: DadosUsuario) {
    let dados = {
      email: usuario.email,
      nome: usuario.nome,
      cpf: usuario.cpf,
      empresa: this.bd.doc(usuario.empresa.id).ref
    }
    if (usuario.apelido !== usuario.nome.split(' ')[0])
      dados['apelido'] = usuario.apelido;
    if (usuario.numeroPiloto !== undefined && usuario.numeroPiloto !== null)
      dados['numeroPiloto'] = usuario.numeroPiloto;
    if (usuario.horasIniciais !== undefined && usuario.horasIniciais !== null)
      dados['horasIniciais'] = usuario.horasIniciais;
    if (usuario.fazendas)
      dados['fazendas'] = usuario.fazendas.map(f => this.bd.doc(f.id).ref);
    if (usuario.permissoes)
      dados['permissoes'] = usuario.permissoes;
    return dados;
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail) {
    return this.autenticador.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      this.snackBar.open('E-mail de redefinição de senha enviado, verifique sua caixa de entrada.', 'Fechar', { duration: 5000 });
    }).catch((error) => {
      this.snackBar.open(error.message, 'Fechar', { duration: 5000 });
    })
  }

}

