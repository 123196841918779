<img class="imagem-fundo" *ngIf="empresa?.logo" [src]="empresa.logo">

<div *ngIf="!(empresa && !carregando)" style="height: 300px;"><mat-spinner style="margin: auto;"></mat-spinner></div>
<h1 class="titulo" *ngIf="voos.length==0 && empresa.quantidades.length==0"><b>Não há voos ou quantidades cadastradas</b></h1>
<div *ngIf="voos.length>0 || empresa.quantidades.length>0" class="centralizar"> 
    <h1 *ngIf="empresa.valor" class="titulo1" ><b>Total acumulado: R${{acumuladoReais | number:'1.2-2':'pt-br'}}</b><br><b>Valor total do contrato: R${{totalReais | number:'1.2-2':'pt-br'}}</b></h1>
    <h1 *ngIf="empresa.valor" class="titulo1" ></h1>

    <div *ngIf="empresa.valor" class="texto-torta">
      
      <h1 class="tituloTorta"><b>Total em Reais Acumulado</b></h1>
      <div class="graficoTorta">
        <ngx-charts-advanced-pie-chart 
          [animations]="false" 
          [valueFormatting]="formataReais" 
          [scheme]="coresPizza"
          [results]="dadosTortaValor" 
          gradient="true"
          [view]="[600, 250]">
        </ngx-charts-advanced-pie-chart>
      </div>
    </div>

    <div *ngIf="empresa.quantidades && dadosTortaTotal" class="texto-torta">
      <h1 class="tituloTorta"><b>Total do Contrato</b></h1>
      <div class="graficoTorta">
        <ngx-charts-advanced-pie-chart 
          [animations]="false" 
          [valueFormatting]="formataNumero" 
          [scheme]="coresPizza"
          [results]="dadosTortaTotal" 
          gradient="true"
          [view]="[600, 250]">
        </ngx-charts-advanced-pie-chart>
      </div>
    </div>

    <div *ngIf="empresa.quantidades && dadosTorta.values()" class="texto-torta">
      <h1 class="tituloTorta"><b>Total do mês</b></h1>
      <div class="graficoTorta">
        <div>
        <ngx-charts-advanced-pie-chart 
          [animations]="false" 
          [valueFormatting]="formataNumero" 
          [scheme]="coresPizza"
          [results]="dadosTorta" 
          gradient="true"
          [view]="[600, 250]">
        </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>


    <div class="graficoBarras" *ngIf="dadosMes.values()">
      <h1 class="titulo"><b>Área de voo por mês</b></h1>
      <div class="grafico">
        <div #containerRef class="spectre-line-chart">
          <ngx-charts-bar-vertical-2d 
            [results]="dadosMes" 
            xAxis="true" yAxis="true" 
            yAxisLabel="Quantidade (ha)"
            showYAxisLabel="true" 
            [scheme]="coresBarra" 
            [view]="[containerRef.offsetWidth, 400]"
            >
          </ngx-charts-bar-vertical-2d>
        </div>
      </div>
      <div class="legenda">
        <b class="colorLegenda">⠀</b>
        <b class="colorLegenda">⠀</b>
        <b class="colorLegenda">⠀</b>
        <b>⠀Área Sobrevoada ⠀</b>
        <b class="colorLegenda2">⠀</b>
        <b class="colorLegenda2">⠀</b>
        <b class="colorLegenda2">⠀</b>
        <b>⠀Área Prevista</b>
      </div>
    </div>
</div>