<mat-toolbar  *ngIf="['/entrar', '/registrar', '/forgot-password'].indexOf(router.url) === -1">
  <img src="/assets/favicon.png">
  <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
    <mat-icon class="logo_name">menu</mat-icon>
  </button>
  <span class="logo_name">Aeroagro</span>
  <span class="espaco"></span>
  <mat-spinner diameter="20" *ngIf="carregando"></mat-spinner>
  <mat-button-toggle-group fxShow="true" fxHide.lt-md="true">
    <mat-button-toggle routerLink="/cliente" *ngIf="usuario?.permissoes.includes(permissoes.Cliente) || usuario?.permissoes.includes(permissoes.Observador)">Cliente</mat-button-toggle>
    <mat-button-toggle routerLink="/piloto" *ngIf="usuario?.permissoes.includes(permissoes.Piloto)">Piloto</mat-button-toggle>
    <mat-button-toggle routerLink="/logistica" *ngIf="usuario?.permissoes.includes(permissoes.Gerente)">Logística</mat-button-toggle>
    <mat-button-toggle routerLink="/usuario">Perfil</mat-button-toggle>
    <mat-button-toggle routerLink="/monitoramento" *ngIf="usuario?.permissoes.includes(permissoes.Monitoramento)">Monitoramento</mat-button-toggle>
  </mat-button-toggle-group>
  <button mat-raised-button (click)="this.sair()">Sair</button>
</mat-toolbar>

<mat-sidenav-container [hidden]="sidenav">
  <mat-sidenav #sidenav>
    <mat-nav-list *ngIf="['/entrar', '/registrar', '/forgot-password'].indexOf(router.url) === -1">
      <a mat-list-item routerLink="/cliente" *ngIf="usuario?.permissoes.includes(permissoes.Cliente) || usuario?.permissoes.includes(permissoes.Observador)">Cliente</a>
      <a mat-list-item routerLink="/piloto" *ngIf="usuario?.permissoes.includes(permissoes.Piloto)">Piloto</a>
      <a mat-list-item routerLink="/logistica" *ngIf="usuario?.permissoes.includes(permissoes.Gerente)">Logística</a>
      <a mat-list-item routerLink="/usuario">Perfil</a>
      <a mat-list-item routerLink="/monitoramento" *ngIf="usuario?.permissoes.includes(permissoes.Monitoramento)">Monitoramento</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="background-color: white !important;" role="main">
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
