import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DadosService } from './../../../../servicos/dados.service'
import { Voo, DadosUsuario, Talhao } from './../../../../modelos/modelos';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-cadastro-manual-dialog',
  templateUrl: './cadastro-manual-dialog.component.html',
  styleUrls: ['./cadastro-manual-dialog.component.scss']
})
export class CadastroManualDialogComponent implements OnInit {

  dadosVooForm: FormGroup = new FormGroup({
    nome: new FormControl(''),
    piloto: new FormControl(''),
    operador: new FormControl(''),
    cultura: new FormControl(''),
    area: new FormControl(''),
    droneID: new FormControl(''),
    droneTipo: new FormControl(''),
    baterias: new FormControl(''),
    quant: new FormControl(''),
    doseRequisitada: new FormControl(''),
    horarioLiga: new FormControl(''),
    horarioVoo: new FormControl(''),
    horarioFim: new FormControl(''),
    temperaturaMedia: new FormControl(''),
    umidade: new FormControl(),
    vento: new FormControl(),
    status: new FormControl('completada'),
    tipoVoo: new FormControl('real'),
    tipoMissao: new FormControl('Cana'),
    observacoes: new FormControl(),
    relatosSeguranca: new FormControl()
  });

  vooConfirmando = null;
  pilotos: DadosUsuario[] = [];
  voos: Voo[];
  Number = Number;
  talhao: Talhao;
  usuario: DadosUsuario;


  constructor(
      @Inject(MAT_DIALOG_DATA) data: {pilotos: DadosUsuario[], voos: Voo[], talhao: Talhao, usuario: DadosUsuario},
      private dadosService: DadosService,
      private dialogRef: MatDialogRef<any>, 
      private _adapter: DateAdapter<any>
  ) {
    this._adapter.setLocale('pt');
    this.pilotos = data.pilotos
    this.voos = data.voos
    this.talhao = data.talhao
    let v : Voo = {
      nome: '',
      id: '',
      piloto: data.usuario,
      operador: data.usuario,
      area: data.talhao.area,
      status: 'completada',
      tipoVoo: 'real',
      tipoMissao: 'cotésia',
      cultura: 'Cana',
      dadosConfirmados: false,
      arquivo: ''
    } 

    this.questionarVoo(v);
   }

  ngOnInit(): void {
  }

  questionarVoo(voo: Voo) {
    this.dadosVooForm.patchValue(voo);
    this.dadosVooForm.patchValue({"piloto": voo.piloto.id, "operador": voo.operador.id});
  }


  criarVoo() {
    if (this.dadosVooForm.invalid)
      return;
    
      
    let v = this.dadosVooForm.value;

    

    if(v.horarioLiga){
      let aux = new Date(v.horarioLiga);
      if(v.horarioVoo){
        let init = v.horarioVoo.split(":");
        aux.setHours(init[0]);
        aux.setMinutes(init[1]);
        v.horarioVoo = aux.getTime();
      }
      v.horarioLiga = aux.getTime();
      if(v.horarioFim){
        let fim = v.horarioFim.split(":");
        let hfim = new Date(v.horarioLiga);
        hfim.setHours(fim[0]);
        hfim.setMinutes(fim[1]);
        v.horarioFim = hfim.getTime();
      };
  
    }


    if (v.piloto) {
      v.piloto = this.pilotos.find(p => p.id === v.piloto);
    }

    if (v.operador) {
      v.operador = this.pilotos.find(p => p.id === v.operador);
    }


    console.log(v);
    

    this.dadosService.criarVoo(this.talhao, v).then(a => {
      v.id = a;
      this.voos.push(v);
      this.dadosVooForm.reset();
      this.dialogRef.close(this.voos);
    })
        
      
    
  }

  tid (index, item) {
    if (!item) return null;
    return index.id;
  }

}
