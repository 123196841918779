import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DadosUsuario } from './modelos/modelos';
import { AutenticaService } from './servicos/autentica.service';

@Injectable({
  providedIn: 'root'
})
export class AutenticaGuard implements CanActivate {

  usuario: DadosUsuario = null;
  permissoes = DadosUsuario.Permissoes;

  constructor(private autenticador: AutenticaService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resp => this.autenticador.estaAutenticado().then(autenticado => {
      if (autenticado) {
        this.autenticador.usuarioAtual.then(u => {
          if (u.permissoes.length > 0) {
            resp(this.obterDados(next));
          } else {
            resp(this.router.parseUrl('/permissoes'));
          }
        });
      } else {
        resp(this.router.parseUrl('/entrar'));
      }
    }));
  }

  async obterDados(next: ActivatedRouteSnapshot): Promise<boolean> {  
    this.usuario = await this.autenticador.usuarioAtual;

    if (this.usuario && 
      ((next.url.toString() == 'monitoramento') && !this.usuario.permissoes.includes(this.permissoes.Monitoramento) || 
      (next.url.toString() == 'cliente') && !(this.usuario.permissoes.includes(this.permissoes.Cliente) || this.usuario.permissoes.includes(this.permissoes.Observador)) || 
      (next.url.toString() == 'piloto') && !this.usuario.permissoes.includes(this.permissoes.Piloto) ||
      (next.url.toString() == 'logistica') && !this.usuario.permissoes.includes(this.permissoes.Gerente))){
      //console.log('Foi')
      return false;
    }
    else{
      //console.log('N Foi')
      return true;
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class JaLogadoGuard implements CanActivate {

  constructor(private autenticador: AutenticaService, private router: Router) {}

  canActivate(): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resp => this.autenticador.estaAutenticado().then(autenticado => {
      if (autenticado) {
        resp(this.router.parseUrl('/usuario'));
      } else {
        resp(true);
      }
    }));
  }

}
