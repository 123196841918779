import { DadosService, numero } from './../../../servicos/dados.service';
import { Empresa, Fazenda, Talhao, Voo } from 'src/app/modelos/modelos';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-detalhamento',
  templateUrl: './detalhamento.component.html',
  styleUrls: ['./detalhamento.component.scss']
})
export class DetalhamentoComponent implements OnInit {

  empresa: Empresa
  fazendas: Fazenda[] = []
  voos: Voo[] = []
  carregando = true;
  meses =  ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET',  'OUT',  'NOV',  'DEZ']
  dadosGlobal: {};
  anosGlobal: {};
  totalReais: number
  acumuladoReais: number

  auxinit: number;

  datas: Date[] = [null, null];
  datasFiltroGrafico: Date[] = [null, null];

  dadosMes = [];
  dadosTorta = [];
  dadosTortaTotal = [];
  dadosTortaValor = [];

  filtroDataGrafico: FormGroup = new FormGroup({
    dataInicioFiltroGrafico: new FormControl(),
    dataFimFiltroGrafico: new FormControl()
  })


  view: any[] = [900,400];
  coresBarra = {
    domain: ['#004B51', '#5F903F' , '#ADC737', '#ECEBEB']
  };
  coresPizza = {
    domain: ['#F7F773', '#004B51', '#ADC737', '#AAAAAA']
  };


  constructor(
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        map(() => window.history.state)
      )
      .subscribe(state => {
        if(state.data != undefined){
          let empresa = state.data
          this.empresa = empresa;
          console.log(this.empresa)
          this.carregando=true
          let fazendas = empresa.fazendas
          this.processaFazendas(fazendas)
        }
      })
  }

  processaFazendas(fazendas){
    this.fazendas = fazendas;
    this.processaDados(fazendas)
  }
  
  processaDados(fazendas: Fazenda[]){
    console.log(this.fazendas)
    let auxinit = this.empresa.inicioCiclo
    let dados:any[] = [];
    this.voos = [];
    fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach( voo=>{
      this.voos.push(voo)
      if (voo.horarioLiga) {
        let aux = new Date(voo.horarioLiga)
        let mes: string 

        if(auxinit <= aux.getDate() && auxinit != undefined && auxinit != null && auxinit>0){
          console.log(aux)
          aux.setMonth(aux.getMonth()+1)
          mes = aux.toISOString().substr(0, 7);
          console.log(aux)
        } 
        else{
          mes = aux.toISOString().substr(0, 7);
        }

        let area = voo.area == null ? numero(talhao.area) : numero(voo.area);
        let quant = numero(voo.quant);

        if (!dados[mes]) {
          dados[mes] = [area, quant];
        } else {
          dados[mes] = [dados[mes][0] + area, dados[mes][1] + quant];
        }
      } else {
        console.log("não tem horario liga", voo);
      }
    })));

    if(this.voos.length==0){
      this.carregando=false
    }
    

    console.log(this.voos);

    let c = 0;
    let dataInicio = new Date(this.empresa.dataInicio)

    if(this.empresa.quantidades && this.empresa.quantidades.length >= 1){
      this.empresa.quantidades.forEach(quantidade => {
        if(quantidade == null || quantidade.toString() == ""){
          quantidade = 0
        }
        if (dados[dataInicio.toISOString().substr(0, 7)]){
          dados[dataInicio.toISOString().substr(0, 7)] = [dados[dataInicio.toISOString().substr(0, 7)][0], dados[dataInicio.toISOString().substr(0, 7)][1], numero(quantidade)]
        }
        else{
          dados[dataInicio.toISOString().substr(0, 7)] = [0, 0, numero(quantidade)]
        }
        dataInicio.setMonth(dataInicio.getMonth() + 1)
  
      })
    }
    else{
      console.log("Entrou else")
      Object.keys(dados).sort().forEach(mes => {
        console.log(mes)
        dados[mes][2] = 0;
      })
    }

    let anos: any[] = []

    Object.keys(dados).sort().forEach(mes => {
      let ano = mes.substr(0,4)
      let area = dados[mes][0];
      let quant = dados[mes][1];
      let pend = dados[mes][2];
      console.log(pend)
      if (!anos[ano]) {
        anos[ano] = [area, quant, pend];
      } else {
        anos[ano] = [anos[ano][0] + area, anos[ano][1] + quant, anos[ano][2] + pend];
      }
    })
    console.log(anos)


    this.dadosGlobal = dados;
    this.anosGlobal = anos;
    
    
    const meses = Object.keys(dados).sort();
    if (meses.length === 0) return;
    const dadosMes = [];
    let quant = this.empresa?.quantidades ? numero(this.empresa.quantidades[0]) : 0;
    quant -= dados[meses[0]][0];
    quant = quant > 0 ? quant : 0;
    console.log(quant);
    console.log((dados[meses[0]][0]));
    let total = this.empresa?.quantidades?.reduce((a, b) => numero(a) + numero(b), 0);
    console.log(this.empresa.quantidades);
    total = total ? total : 0;
    let somaRealizado = Object.keys(dados).map(d => dados[d][0]).reduce((a, b) => a + b, 0) + numero(this.empresa?.areaPriori);
    somaRealizado = somaRealizado ? somaRealizado : 0;
    let diff = total - somaRealizado;
    diff = diff > 0 ? diff : 0;

    if(this.empresa.valor){
      this.totalReais = total*parseInt(this.empresa.valor)
      this.acumuladoReais = somaRealizado*parseInt(this.empresa.valor)

      this.dadosTortaValor = [{
        "name": "Faltante",
        "value": ((((this.totalReais-this.acumuladoReais) > 0 ? (this.totalReais-this.acumuladoReais) : 0) + Number.EPSILON) * 100) / 100,
      }, {
        "name": "Total acumulado",
        "value": ((this.acumuladoReais + Number.EPSILON) * 100) / 100,
      },
      ]

    }


    const dadosTortaTotal = [{
      "name": "Área pendente",
      "value": ((diff + Number.EPSILON) * 100) / 100,
    }, {
      "name": "Área sobrevoada",
      "value": ((somaRealizado + Number.EPSILON) * 100) / 100,
    },
    ]
    
    console.log(dados);
    meses.forEach(mes => {
      let nome = this.meses[parseInt(mes.substr(5,7))-1]
      let ano = mes.substr(2,2)
      let data = (nome + "/" + ano);
      dadosMes.push({
        "name": data,
        "series": [
          {
            "name": "Área Sobrevoada",
            "value": Math.round((dados[mes][0] + Number.EPSILON) * 100) / 100
          },
          {
            "name": "Área Prevista",
            "value": Math.round((dados[mes][2] + Number.EPSILON) * 100) / 100
          }
        ],
      })
    });



    


    this.dadosMes = [...dadosMes];
    this.dadosTortaTotal = dadosTortaTotal;
    this.auxinit = auxinit

    //Calcula torta
    this.filtraDataGrafico(3)
    //this.flagSelecao = true;


    this.carregando = false;
  }



  filtraDataGrafico(i: number, evento?) {
    console.log("ta chamando");
    if (evento?.value) {
      this.datasFiltroGrafico[i] = evento.value.toDate();
      this.datas[i] = evento.value.toDate();
    } else {
      this.datasFiltroGrafico[i] = null;
      this.datas[i] = null;
    }
    if (this.datasFiltroGrafico[0] && this.datasFiltroGrafico[1]) {
      if(this.datasFiltroGrafico[1].getDate() > this.auxinit && this.auxinit){
        this.datasFiltroGrafico[1].setMonth(this.datasFiltroGrafico[1].getMonth()+1)
      }
      if(this.datasFiltroGrafico[0].getDate() < this.auxinit && this.auxinit){
        this.datasFiltroGrafico[0].setMonth(this.datasFiltroGrafico[0].getMonth()-1)
        this.datasFiltroGrafico[0].setDate(this.auxinit)
        this.datasFiltroGrafico[1].setDate(this.auxinit-1);
      }
      else if(this.datasFiltroGrafico[0].getDate() >= this.auxinit && this.auxinit){
        this.datasFiltroGrafico[0].setDate(this.auxinit)
        this.datasFiltroGrafico[1].setDate(this.auxinit-1);
      }
      else if(!this.auxinit || this.auxinit == undefined){
        this.datasFiltroGrafico[0].setDate(1)
        this.datasFiltroGrafico[1].setDate(28);
      }
      let month = this.datasFiltroGrafico[1].getMonth();
      this.datasFiltroGrafico[1].setMonth(month);
      let mesesString = [];
      this.recalculaTorta();
      let mesEscolhido1 = this.datasFiltroGrafico[0].getMonth();
      let mesEscolhido2 = this.datasFiltroGrafico[1].getMonth();
      let anoEscolhido1 = this.datasFiltroGrafico[0].getFullYear();
      let anoEscolhido2 = this.datasFiltroGrafico[1].getFullYear();
      let dataEscolhida1 = mesEscolhido1 + anoEscolhido1;
      let dataEscolhida2 = mesEscolhido2 + anoEscolhido2;

      Object.keys(this.dadosGlobal).sort().forEach(meses => {
        let ano = parseInt(meses.substr(0,4))
        let mes = parseInt(meses.substr(5,7))
        let data = new Date
        data.setFullYear(ano);
        data.setMonth(mes-1);
        console.log(data);
        console.log(this.datasFiltroGrafico[0]);
        console.log(this.datasFiltroGrafico[1]);
        if(data >= this.datasFiltroGrafico[0] && data <= this.datasFiltroGrafico[1]){
          mesesString.push(meses);
        }
      })

      let anos: any[] = []

      mesesString.sort().forEach(mes => {
        let ano = mes.substr(0,4)
        let area = this.dadosGlobal[mes][0];
        let quant = this.dadosGlobal[mes][1];
        let pend = parseInt(this.dadosGlobal[mes][2]);
        console.log(pend)
        if (!anos[ano]) {
          anos[ano] = [area, quant, pend];
        } else {
          anos[ano] = [anos[ano][0] + area, anos[ano][1] + quant, anos[ano][2] + pend];
        }
      })
      
      console.log(anos);

      this.dadosMes = [];
      
      mesesString.forEach(mes => {
        let nome = this.meses[parseInt(mes.substr(5,7))-1]
        this.dadosMes.push({
          "name": nome,
          "series": [
            {
              "name": "Área Sobrevoada",
              "value": Math.round((this.dadosGlobal[mes][0] + Number.EPSILON) * 100) / 100
            },
            {
              "name": "Área Prevista",
              "value": Math.round((parseInt(this.dadosGlobal[mes][2]) + Number.EPSILON) * 100) / 100
            }
          ],
        })
      })
      
      console.log(this.dadosMes);
    }
    if (i == 3){
      this.datas[0] = new Date();
      this.datas[1] = new Date();
      if( this.datas[0].getDate() < this.auxinit && this.auxinit != undefined && this.auxinit != null){
        this.datas[0].setDate(this.auxinit);
        this.datas[0].setMonth(this.datas[0].getMonth()-1)
      }
      else if(this.auxinit != undefined && this.auxinit != null){
        this.datas[0].setDate(this.auxinit);
      }
      else{
        this.datas[0].setDate(1);
      }
      this.datas[0].setHours(0);
      this.datas[0].setMinutes(0);
      this.datas[0].setSeconds(1);
      this.recalculaTorta();
      this.datas = [];
      this.datasFiltroGrafico = [];
      this.filtroDataGrafico.reset();
      console.log("Entrou limpar")
      this.dadosMes = [];
      console.log(this.dadosGlobal)
      Object.keys(this.dadosGlobal).sort().forEach(mes => {
        let nome = this.meses[parseInt(mes.substr(5,7))-1]
        this.dadosMes.push({
          "name": nome,
          "series": [
            {
              "name": "Área Sobrevoada",
              "value": Math.round((this.dadosGlobal[mes][0] + Number.EPSILON) * 100) / 100
            },
            {
              "name": "Área Prevista",
              "value": Math.round((parseInt(this.dadosGlobal[mes][2]) + Number.EPSILON) * 100) / 100
            }
          ],
        })
      })

      
    }
  }


  private recalculaTorta() {
    let realizado = 0;
    console.log(this.datas)
    this.fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach(voo => {
      if (voo.horarioLiga > this.datas[0].valueOf() && voo.horarioLiga < this.datas[1].valueOf() + 1000 * 60 * 60 * 24) {
        realizado += numero(voo.area);
      }
    })));
    let contratado = 0;
    if (this.empresa?.dataInicio && this.empresa.quantidades) {
      let i = this.diffMeses(new Date(this.empresa.dataInicio), this.datas[0]);
      i = i > 0 ? i : 0;
      if(this.datas[0].getDate() >= this.auxinit && this.auxinit){
        i++
      }
      let f = this.diffMeses(this.datas[1], new Date(this.empresa.dataFim));
      f = f > 0 ? f : 0;
      if(this.datas[1].getDate() >= this.auxinit && this.auxinit){
        f--
      }
      f = this.empresa.quantidades.length - f;
      while (i < f) {
        contratado += numero(this.empresa.quantidades[i]);
        i++;
      }
    }
    let diff = contratado - realizado;
    diff = diff > 0 ? diff : 0;
    this.dadosTorta = [{
      "name": "Área pendente",
      "value": Math.round(((diff + Number.EPSILON) * 100) / 100),
    }, {
      "name": "Área sobrevoada",
      "value": Math.round(((realizado + Number.EPSILON) * 100) / 100),
    },
    ]
  }

 diffMeses(inicio: Date, fim: Date) {
    return fim.getMonth() - inicio.getMonth() + (12 * (fim.getFullYear() - inicio.getFullYear()));
  }

  formataNumero(a) {
    return a.toLocaleString(undefined, { maximumFractionDigits: 2 }) + " ha"
  }

  formataReais(a) {
    return " R$" + a.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits: 2 })
  }


  

}
