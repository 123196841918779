import { AutenticaService } from './../../../../../servicos/autentica.service';
import { DadosService } from 'src/app/servicos/dados.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DadosUsuario } from 'src/app/modelos/modelos';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-operador-dialog',
  templateUrl: './operador-dialog.component.html',
  styleUrls: ['./operador-dialog.component.scss']
})
export class OperadorDialogComponent implements OnInit {

  operador: DadosUsuario
  operadores: DadosUsuario[] = [];
  enviando = false;
  Number = Number;

  formularioOperador: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    apelido: new FormControl('', Validators.required),
    numeroPiloto: new FormControl('', Validators.required),
    horasIniciais: new FormControl('', Validators.min(0)),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {operadores: DadosUsuario[], operador?: DadosUsuario},
    private dialogRef: MatDialogRef<any>,
    private dadosService: DadosService,
    private autenticaService: AutenticaService) { 
      this.operadores = data.operadores
      this.operador = data.operador
    }

  ngOnInit(): void {
    if(this.operador){
      this.formularioOperador.patchValue(this.operador);
    }
  }

  criarOperador() {
    if (this.operador && this.formularioOperador.valid) {
      console.log("entrou")
      this.operador.nome = this.formularioOperador.get('nome').value
      this.operador.apelido = this.formularioOperador.get('apelido').value
      this.operador.numeroPiloto = this.formularioOperador.get('numeroPiloto').value
      this.operador.horasIniciais = this.formularioOperador.get('horasIniciais').value
      console.log(this.operador)
      this.autenticaService.atualizaUsuario(this.operador)
    }

  }

}
