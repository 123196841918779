<form [formGroup]="formulario" (ngSubmit)="criarEditarEmpresa()">
  <mat-form-field>
    <input type="text" matInput required placeholder="Razão Social" formControlName="nome">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput required placeholder="Grupo" formControlName="grupo">
  </mat-form-field>
  <mat-form-field>
    <input [imask]="{mask: '00.000.000/0000-00'}" [unmask]="true" type="text" matInput required placeholder="CNPJ"
      formControlName="cnpj">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Endereço" formControlName="endereco">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Responsável Técnico" formControlName="responsavel">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Culturas contratadas" formControlName="culturas">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Telefone de contato" formControlName="telefone">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Contato direto" formControlName="contato">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Telefone direto" formControlName="telefoneDireto">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Área voada a priori" formControlName="areaPriori" [imask]="{mask: Number, scale: 3}">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Embalagens lançadas a priori" formControlName="dosesPriori" [imask]="{mask: Number, scale: 0}">
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Área contratada" formControlName="area" [imask]="{mask: Number, scale: 3}">
  </mat-form-field>
  <mat-radio-group aria-label="Área contratada" formControlName="areaTipo">
    <mat-radio-button value="total">Total ⠀</mat-radio-button>
    <mat-radio-button value="mensal">Mensal</mat-radio-button>
  </mat-radio-group>
  <mat-form-field>
    <input type="text" matInput placeholder="Safra" formControlName="safra">
  </mat-form-field>
  <mat-form-field>
    <mat-select matNativeControl placeholder="Embalagens recomendadas" formControlName="doses">
      <mat-option value="4">4 embalagens/ha</mat-option>
      <mat-option value="6">6 embalagens/ha</mat-option>
      <mat-option value="8">8 embalagens/ha</mat-option>
      <mat-option value="10">10 embalagens/ha</mat-option>
      <mat-option value="12">12 embalagens/ha</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Valor contratado/ha" formControlName="valor">
  </mat-form-field>
  <mat-form-field>
    <ngx-mat-file-input formControlName="logo" placeholder="Logo do cliente" accept="image/*"></ngx-mat-file-input>
    <mat-icon matSuffix>folder</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Duração do Contrato</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="dataInicio" placeholder="Data Inicial">
      <input matEndDate formControlName="dataFim" placeholder="Data Final">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error *ngIf="formulario.controls.dataInicio.hasError('matStartDateInvalid')">Data inicial inválida</mat-error>
    <mat-error *ngIf="formulario.controls.dataFim.hasError('matEndDateInvalid')">Data final inválida</mat-error>
  </mat-form-field>
  <mat-form-field>
    <input type="text" matInput placeholder="Início do ciclo" formControlName="inicioCiclo" [imask]="{mask: Number, scale: 0}">
  </mat-form-field>

  <table formArrayName="quantidades" style="max-width: 200px">
    <tr *ngIf="quantidades.length > 0"><th>Quantidades Contratadas</th></tr>
    <tr *ngFor="let _ of quantidades.controls ; index as i">
      <td>
        <mat-form-field>
          <input type="text" matInput [placeholder]="nomeMes(i)" [formControlName]="i">
        </mat-form-field>
      </td>
    </tr>
  </table>

  <div class="button">
    <button type="submit" mat-raised-button [disabled]="enviando">{{acao}}</button>
  </div>
</form>