<div class="corpo">   
  <mat-card>
    <mat-card-title>Registro</mat-card-title>
    <mat-card-content>
      <form #registroForm="ngForm" [formGroup]="formulario" (ngSubmit)="registrar()">
        <p>
          <mat-form-field>
            <input type="text" matInput required placeholder="E-mail" formControlName="email">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="text" matInput required placeholder="Nome completo" formControlName="nome">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input [imask]="{mask: '000.000.000-00'}" [unmask]="true" type="text" matInput required placeholder="CPF" formControlName="cpf">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <mat-label>Empresa</mat-label>
          <mat-select matNativeControl required formControlName="empresa">
            <mat-option *ngFor="let empresa of empresas" [value]="empresa.id" >{{ empresa.nome }}</mat-option>
          </mat-select>
        </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Senha" formControlName="senha">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Confirmação da Senha" formControlName="senhaConfirm" [errorStateMatcher]="matcher">
            <mat-error *ngIf="formulario.hasError('notSame')">
              Senhas não são iguais
            </mat-error>
          </mat-form-field>
        </p>

        <div class="button">
          <button type="submit" mat-raised-button [disabled]="registroForm.invalid">Registrar</button>
        </div>
      </form>
    </mat-card-content>
    <div><button type="submit" mat-button [disabled]="enviando" (click)="voltar()">Voltar</button></div>
  </mat-card>
</div>