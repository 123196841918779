
<mat-card style="padding-top: 0px;">
  <mat-tab-group>
    <mat-tab label="Drones">
      <mat-card>
        <mat-card-title>Drones  <button mat-raised-button (click)="showDroneDialog()">Cadastrar Drone</button></mat-card-title>
        

        <div *ngIf="drones && drones.length > 0" class="tabela-container mat-elevation-z8">
          <table #tabDrone mat-table [dataSource]="dataSourceDrone" matSort>
        
            <ng-container matColumnDef="sn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nº de Série</b></th>
              <td mat-cell *matCellDef="let drone">{{drone.sn}}</td>
            </ng-container>
  
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nome</b></th>
              <td mat-cell *matCellDef="let drone">{{drone.nome}}</td>
            </ng-container>

            <ng-container matColumnDef="modelo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Modelo</b></th>
              <td mat-cell *matCellDef="let drone">{{drone.modelo}}</td>
            </ng-container>

            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
              <td mat-cell *matCellDef="let drone">
                <button mat-icon-button (click)="excluirDrone(drone)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
  
                <button mat-icon-button style="color: rgb(0, 180, 0);" (click)="showDroneDialog(drone)" >
                    <mat-icon>create</mat-icon>
                </button>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDrone;"></tr>
            <tr mat-row class="talhao" *matRowDef="let row; columns: displayedColumnsDrone;"></tr>
          </table>
        </div>

        <!--<form [formGroup]="formularioDrone" (ngSubmit)="criarDrone()">
          <mat-form-field>
            <input type="text" matInput required placeholder="Nome Fantasia" formControlName="nome">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput required placeholder="Número de Série (SN)" formControlName="sn">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput required placeholder="Modelo do Drone" formControlName="modelo">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Horas de Voo Iniciais" formControlName="horasIniciais" [imask]="{mask: Number}">
          </mat-form-field>
          <mat-form-field>
            <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
          </mat-form-field>
          <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Drone</button>
        </form>-->
      </mat-card>
    </mat-tab>


    <mat-tab label="Baterias"> 
      <mat-card>
        <mat-card-title>Baterias  <button mat-raised-button (click)="showBateriaDialog()">Cadastrar Bateria</button></mat-card-title>
        
        <div *ngIf="baterias.length>0" class="tabela-container mat-elevation-z8">
          <table #tabBateria mat-table [dataSource]="dataSourceBateria" matSort>
        
            <ng-container matColumnDef="sn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nº de Série</b></th>
              <td mat-cell *matCellDef="let bateria">{{bateria.sn}}</td>
            </ng-container>
  
            <ng-container matColumnDef="modelo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Modelo</b></th>
              <td mat-cell *matCellDef="let bateria">{{bateria.modelo}}</td>
            </ng-container>

            <ng-container matColumnDef="soh">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>SoH</b></th>
              <td mat-cell *matCellDef="let bateria">{{bateria.soh}}</td>
            </ng-container>

            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
              <td mat-cell *matCellDef="let bateria">
                <button mat-icon-button (click)="excluirBateria(bateria)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
              </td>
          </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsBateria;"></tr>
            <tr mat-row  *matRowDef="let row; columns: displayedColumnsBateria;"></tr>
          </table>
        </div>
        
        
        <!--<form [formGroup]="formularioBateria" (ngSubmit)="criarBateria()">
          <mat-form-field>
            <input type="text" matInput required placeholder="Número de Série (SN)" formControlName="sn">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Modelo da Bateria" formControlName="modelo">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Ciclos Iniciais" formControlName="ciclosIniciais" [imask]="{mask: Number, scale: 0}">
          </mat-form-field>
          <mat-form-field>
            <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
          </mat-form-field>
          <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Bateria</button>
        </form>-->
      </mat-card>
    </mat-tab>


    <mat-tab label="Dispensers">
      <mat-card>
        <mat-card-title>Dispenser <button mat-raised-button (click)="showDispenserDialog()">Cadastrar Dispenser</button></mat-card-title>
        
        <div *ngIf="dispensers.length>0" class="tabela-container mat-elevation-z8">
          <table #tabDispenser mat-table [dataSource]="dataSourceDispenser" matSort>
        
            <ng-container matColumnDef="modelo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Modelo</b></th>
              <td mat-cell *matCellDef="let dispenser">{{dispenser.modelo}}</td>
            </ng-container>
  
            <ng-container matColumnDef="doses">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nº embalagens por voo</b></th>
              <td mat-cell *matCellDef="let dispenser">{{dispenser.doses}}</td>
            </ng-container>

            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
              <td mat-cell *matCellDef="let dispenser">
                <button mat-icon-button (click)="excluirDispenser(dispenser)">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumnsDispenser;"></tr>
            <tr mat-row  *matRowDef="let row; columns: displayedColumnsDispenser;"></tr>
          </table>
        </div>
        
        <!--<form [formGroup]="formularioDispenser" (ngSubmit)="criarDispenser()">
          <mat-form-field>
            <input type="text" matInput placeholder="Modelo" formControlName="modelo">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Nº embalagens por voo" formControlName="doses" [imask]="{mask: Number, scale: 0}">
          </mat-form-field>
          <mat-form-field>
            <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
          </mat-form-field>
          <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Dispenser</button>
        </form>-->
      </mat-card>
    </mat-tab>

    
    <mat-tab label="Operadores"> 
      <mat-card>
        <mat-card-title>Operadores</mat-card-title>
        <div *ngIf="operadoresMaster.length==0" style="height: 300px;">
          <mat-spinner style="margin: auto;"></mat-spinner>
        </div>
        <div class="tabela-container mat-elevation-z8" *ngIf="operadoresMaster.length>0">
          <table #tabOperador mat-table [dataSource]="dataSourceOperador" matSort>
        
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nome</b></th>
              <td mat-cell *matCellDef="let operadorMaster">{{operadorMaster.operador.nome}}</td>
            </ng-container>

            <ng-container matColumnDef="apelido">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nome de Guerra</b></th>
              <td mat-cell *matCellDef="let operadorMaster">{{operadorMaster.operador.apelido}}</td>
            </ng-container>

            <ng-container matColumnDef="numeroPiloto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Número de Guerra</b></th>
              <td mat-cell *matCellDef="let operadorMaster">{{operadorMaster.operador.numeroPiloto}}</td>
            </ng-container>

            <ng-container matColumnDef="horasVoo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Horas Sobrevoadas</b></th>
              <td mat-cell *matCellDef="let operadorMaster">{{operadorMaster.horasSobrevoadas | number:'1.1-2':'pt-br'}}</td>
            </ng-container>

            <ng-container matColumnDef="areaVoo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Área total Levantada [ha] </b></th>
              <td mat-cell *matCellDef="let operadorMaster">{{operadorMaster.areaSobrevoada | number:'1.1-2':'pt-br'}}</td>
            </ng-container>

            <ng-container matColumnDef="quantVoo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Nº de Voos</b></th>
              <td mat-cell *matCellDef="let operadorMaster">{{operadorMaster.nVoos}}</td>
            </ng-container>

            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef><b>Ações</b></th>
              <td mat-cell *matCellDef="let operadorMaster">
                <button mat-icon-button style="color: rgb(0, 180, 0);" (click)="showOperadorDialog(operadorMaster.operador)" >
                    <mat-icon>create</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsOperador;"></tr>
            <tr mat-row  *matRowDef="let row; columns: displayedColumnsOperador;"></tr>
          </table>
        </div>
      </mat-card>
    </mat-tab>


    <!--<mat-tab label="Manutenção">
      <mat-card>
        <mat-card-title>Manutenção</mat-card-title>
        <mat-card>
          <h2>Drones</h2>
          <div style="margin-bottom: 30px;" *ngFor="let limite of limitesDrone">
            <h3 class="linear">Modelo {{limite.modelo}}</h3>
            <button (click)="showManutencaoDialog(limite)" mat-raised-button>Configurar</button>
            <br>
            <h4 class="linear">Limite Horas do Drone: {{limite.limHoras}}<span *ngIf="!limite.limHoras">Não definido</span></h4>
            <h4 class="linear">Limite Voos do Drone: {{limite.limVoos}}<span *ngIf="!limite.limVoos">Não definido</span></h4>
            <br>
            <h4 class="linear">Limite Horas da Hélice: {{limite.limHelice}}<span *ngIf="!limite.limHelice">Não definido</span></h4>
            <h4 class="linear">Limite Horas do Motor: {{limite.limMotor}}<span *ngIf="!limite.limMotor">Não definido</span></h4>
          </div>
        </mat-card>

        <mat-card>
          <h2>Baterias</h2>
          <mat-form-field>
            <input type="number" matInput required placeholder="Limite de voos" name="limiteVoosBat" #input value="{{limitesVoosBateria.limVoosBateria}}">
          </mat-form-field>
          <button (click)="createlimiteVoosBateria(input.value)" mat-raised-button>Salvar</button>
        </mat-card>
      </mat-card>
    </mat-tab> -->
  </mat-tab-group>

  
  
  
  
</mat-card>
