import { Empresa } from 'src/app/modelos/modelos';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { numero } from 'src/app/servicos/dados.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-dados',
  templateUrl: './dados.component.html',
  styleUrls: ['./dados.component.scss']
})
export class DadosComponent implements OnInit {

  empresa: Empresa
  meses =  ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO',  'OUTUBRO',  'NOVEMBRO',  'DEZEMBRO']
  dadosG: {mes: string, areaVoada: number, valor: number}[] = []

  
  displayedColumns: string[] = ["mes", "area", "valor"];
  dataSource: MatTableDataSource<{mes: string, areaVoada: number, valor: number}>;

  constructor(@Inject(MAT_DIALOG_DATA) data: Empresa) { 
    this.empresa = data
  }

  ngOnInit(): void {

    let dados:any[] = [];

    this.empresa.fazendas?.forEach(fazenda => fazenda.talhoes?.forEach(talhao => talhao.voos?.forEach(voo => {

      if(voo.horarioLiga) {

        let area = voo.area == null ? numero(talhao.area) : numero(voo.area);
        let reais =  this.empresa?.valor ? (area * numero(this.empresa?.valor)) : 0

        let aux = new Date(voo.horarioLiga)
        let mes: string = aux.toISOString().substr(0, 7);
        
        if (!dados[mes]) {
          dados[mes] = [area, reais];
        } else {
          dados[mes] = [dados[mes][0] + area, dados[mes][1] + reais];
        }

      }

    }))) 


    const meses = Object.keys(dados).sort();

    meses.forEach((mes, i) => {
      this.dadosG[i] = {
        mes: this.meses[parseInt(mes.substr(5,7))-1] +"/"+mes.substr(2,2), 
        areaVoada: dados[mes][0], 
        valor: dados[mes][1]
      }
    })

    console.log(this.dadosG)


    this.dataSource = new MatTableDataSource<{mes: string, areaVoada: number, valor: number}>(this.dadosG)

  }

}
