import { DetalhamentoComponent } from './paginas/monitoramento/detalhamento/detalhamento.component';
import { ForgotPasswordComponent } from './paginas/entrar/forgot-password/forgot-password.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntrarComponent } from './paginas/entrar/entrar.component';
import { RegistrarComponent } from './paginas/registrar/registrar.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { AutenticaGuard, JaLogadoGuard } from './autentica.guard';
import { ClienteComponent } from './paginas/cliente/cliente.component';
import { PilotoComponent } from './paginas/piloto/piloto.component';
import { LogisticaComponent } from './paginas/logistica/logistica.component';
import { PermissoesComponent } from './paginas/permissoes/permissoes.component';
import { DebugComponent } from './paginas/debug/debug.component';
import { MonitoramentoComponent } from './paginas/monitoramento/monitoramento.component';

const routes: Routes = [
  { path: '', redirectTo: 'entrar', pathMatch: 'full' },
  { path: 'entrar', component: EntrarComponent, canActivate: [JaLogadoGuard]},
  { path: 'registrar', component: RegistrarComponent, canActivate: [JaLogadoGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [JaLogadoGuard]},
  { path: 'usuario', component: PerfilUsuarioComponent, canActivate: [AutenticaGuard]},
  { path: 'cliente', component: ClienteComponent, canActivate: [AutenticaGuard]},
  { path: 'piloto', component: PilotoComponent, canActivate: [AutenticaGuard]},
  { path: 'logistica', component: LogisticaComponent, canActivate: [AutenticaGuard]},
  { path: 'debug', component: DebugComponent, canActivate: [AutenticaGuard]},
  { path: 'monitoramento', component: MonitoramentoComponent, canActivate: [AutenticaGuard], children: [
    { path: 'detalhamento', component: DetalhamentoComponent, canActivate: [AutenticaGuard]}
    
  ]},
  { path: 'permissoes', component: PermissoesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
