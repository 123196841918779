<mat-card>
    <form [formGroup]="formularioOperador" (ngSubmit)="criarOperador()">
        <h1 *ngIf="operador" class="titulo"> Atualização do Operador </h1>
        <h1 *ngIf="!operador" class="titulo"> Cadastro do Operador </h1>
        <mat-form-field>
          <input type="text" matInput required placeholder="Nome" formControlName="nome">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Nome de Guerra" formControlName="apelido">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Número de Guerra" formControlName="numeroPiloto">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Horas Iniciais" formControlName="horasIniciais" [imask]="{mask: Number}">
        </mat-form-field>
        <div class="button">
            <button type="submit" *ngIf="operador" mat-raised-button>Salvar</button>
        </div>
      </form>
  </mat-card>