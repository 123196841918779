<mat-card>
    <form [formGroup]="formularioBateria" (ngSubmit)="criarBateria()">
        <h1 class="titulo"> Cadastro de Bateria </h1>
        <mat-form-field>
            <input type="text" matInput required placeholder="Número de Série (SN)" formControlName="sn">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Modelo da Bateria" formControlName="modelo">
          </mat-form-field>
          <mat-form-field>
            <input type="text" matInput placeholder="Ciclos Iniciais" formControlName="ciclosIniciais" [imask]="{mask: Number, scale: 0}">
          </mat-form-field>
          <mat-form-field>
            <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
          </mat-form-field>
          <div class="button">
            <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Bateria</button>
          </div>
        </form>
  </mat-card>