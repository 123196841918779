<mat-card>
  <div style="overflow: auto;">
    <div style="float: left; width: 80%;" *ngIf="usuario">
  <p *ngIf="usuario.fazendas.length === 0">Não há fazendas associadas ao piloto.</p>

  <mat-form-field  [style.width.px]=280>
    <mat-label>Fazenda</mat-label>
    <input 
      type="text"
      aria-label="Fazenda"
      matInput
      [formControl]="controlaFazenda"
      [matAutocomplete]="auto">
        <mat-icon matSuffix (click)="limpaAutocompleteFazenda()">clear</mat-icon>
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selecionaFazenda($event.option.value)"
        [displayWith]="pegarNome">
          <ng-container *ngFor="let fazenda of usuario.fazendas">
            <mat-option *ngIf="flagAutoComplete" [value]="fazenda">
              {{fazenda.nome}}
            </mat-option>
          </ng-container> 
          <mat-option *ngFor="let fazenda of filteredOptions" [value]="fazenda">
           {{fazenda.nome}}
          </mat-option>
        </mat-autocomplete>
  </mat-form-field>

  <span class="espaco2"></span>

  <mat-form-field  [style.width.px]=280>
    <mat-label>Talhão</mat-label>
    <input 
      matInput
      [matAutocomplete]="auto2"
      type="text"
      aria-label="Talhao"
      [formControl]="controlaTalhao">
      <mat-icon matSuffix (click)="limpaAutocompleteTalhao()">clear</mat-icon>
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selecionaTalhao($event.option.value)"
      [displayWith]="pegarNome">
        <ng-container *ngFor="let talhao of talhoes">
          <mat-option *ngIf="flagAutoComplete2" [value]="talhao">
            {{talhao.nome}}
          </mat-option>
        </ng-container> 
        <mat-option *ngFor="let talhao of filteredOptionsTalhao" [value]="talhao">
          {{talhao.nome}}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>

  <button type="submit" mat-raised-button (click)="showTalhaoDialog()">Cadastrar Talhao</button>
  <h4 class="erro" *ngIf="flagMensagemErro">Selecione uma fazenda para poder cadastrar um talhão.</h4>
  <!--
  <button mat-raised-button *ngFor="let fazenda of usuario.fazendas" (click)="selecionaFazenda(fazenda)" 
  [color]="fazendaSelecionada.id===fazenda.id?'primary':''"
   [disabled]="vooConfirmando!==null && fazendaSelecionada.id!==fazenda.id || carregando || enviando">
   {{fazenda.nome}}
  </button>
  -->

  <br>
  <br>
  
  <mat-card *ngIf="!fazendaSelecionada" class="inicio">

  </mat-card>


  <mat-card *ngIf="fazendaSelecionada">

    <!--
    <button mat-raised-button *ngFor="let talhao of talhoes" (click)="selecionaTalhao(talhao)" 
    [color]="talhaoSelecionado?.id===talhao.id?'primary':''" 
    [disabled]="vooConfirmando!==null && talhaoSelecionado?.id!==talhao.id || carregando || enviando">
    {{talhao.nome}}
    </button>
    -->

      <mat-card *ngIf="talhaoSelecionado">
      <mat-spinner *ngIf="carregando"></mat-spinner>
      <mat-card *ngFor="let voo of voos">
        <mat-card-title>{{voo.nome}}</mat-card-title>
        <mat-card-content>
          <br>
          <span class="espacavel">{{voo.descri}} {{voo.arquivo}}; Área: {{voo.area}} ha
          <span class="espaco"></span>
          <button mat-stroked-button (click)="questionarVoo(voo)" (click)="showDetalhesDialog()">{{voo.dadosConfirmados? 'Editar' : 'Verificar Dados'}}</button>
          &nbsp;
          <button mat-stroked-button (click)="excluirVoo(voo)">Excluir</button></span>
          <div *ngIf="voo.dadosConfirmados">
          <br>
          <p>
          Registraram-se {{voo.quant}} lançamentos na cultura de {{voo.cultura}} <span *ngIf="voo.doseRequisitada">considerando-se a quantidade requisitada de {{voo.doseRequisitada}} embalagens lançadas</span>.
          A missão {{voo.tipoVoo}} de {{voo.tipoMissao}} foi {{voo.status}} no drone SN {{voo.droneID}} com duração de {{(voo.horarioFim - voo.horarioVoo)/60000 | number:'1.0-0'}} minutos no dia {{voo.horarioLiga | date:'dd/MM/yyyy'}}.
          <span *ngIf="voo.piloto?.id === voo.operador?.id">{{voo.piloto?.apelido}} foi o operador e piloto dessa missão.</span>
          <span *ngIf="voo.piloto?.id !== voo.operador?.id">{{voo.piloto?.apelido}} e {{voo.operador?.apelido}} foram os respectivos piloto e operador.</span>
          </p>
          <p>Utilizaram-se as baterias <span *ngFor="let b of voo.baterias">{{b.id}} <span *ngIf="b.soh>-1">({{b.soh}}% SoH), </span></span></p>
          <p *ngIf="voo.observacoes">Observações: {{voo.observacoes}}.</p>
          <p *ngIf="voo.relatosSeguranca">Relatos de segurança: {{voo.relatosSeguranca}}.</p>
          <p *ngIf="voo.comentarios">Validação do cliente: {{voo.comentarios}}.</p>
          </div>
        </mat-card-content>
      </mat-card>
      <form [formGroup]="vooForm" (ngSubmit)="criarVoo()">
        <mat-form-field>
          <input type="text" matInput required placeholder="Nome do Voo" formControlName="nome">
        </mat-form-field>
        <mat-form-field>
          <ngx-mat-file-input formControlName="arquivo" placeholder="Arquivo .DAT" accept=".dat"></ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
        <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Voo</button>
        <div *ngIf="error">
          {{ error.message }}
        </div>
        <div *ngIf="uploadResponse.status === 'error'">
          {{ uploadResponse.message }}
        </div>
        <div *ngIf="uploadResponse.status === 'progress'">
          <mat-progress-bar [mode]="uploadResponse.message == '100' ? 'indeterminate' : 'determinate'"
            [value]="uploadResponse.message"></mat-progress-bar>
          <p>{{ uploadResponse.message == '100' ? 'Processando .DAT' : 'Enviando'}}</p>
        </div>
      </form>

      <button (click)="showCadastroManualDialog()" mat-raised-button [disabled]="enviando">Cadastro Manual</button>
      
      <span class="espacavel"><span class="espaco"></span><button mat-stroked-button (click)="excluirTalhao()">Excluir Talhão</button></span>
    </mat-card>
    <!--
    <form [formGroup]="talhaoForm" (ngSubmit)="criarTalhao()">
      <mat-form-field>
        <input type="text" matInput required placeholder="Nome do Talhão" formControlName="nome">
      </mat-form-field>
      <mat-form-field>
        <input type="text" matInput placeholder="Descrição" formControlName="descri">
      </mat-form-field>
      <mat-form-field>
        <ngx-mat-file-input formControlName="arquivo" placeholder="Arquivo KML" accept=".kml"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
      </mat-form-field>
      <div class="button">
        <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Talhao</button>
      </div>
    </form>
      -->
  </mat-card>
</div>
<div style="float: right; width: 20%;">
    
<div id="mapaP"></div>
</div>
</div>
</mat-card>
