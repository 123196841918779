import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bateria } from 'src/app/modelos/modelos';
import { DadosService } from 'src/app/servicos/dados.service';

@Component({
  selector: 'app-bateria-dialog',
  templateUrl: './bateria-dialog.component.html',
  styleUrls: ['./bateria-dialog.component.scss']
})
export class BateriaDialogComponent implements OnInit {

  enviando = false;
  baterias: Bateria[] = [];
  Number = Number;


  
  formularioBateria: FormGroup = new FormGroup({
    sn: new FormControl('', Validators.required),
    modelo: new FormControl('', Validators.required),
    ciclosIniciais: new FormControl('', [Validators.min(0), Validators.pattern("[0-9]*")]),
    observacoes: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {baterias: Bateria[]},
    private dialogRef: MatDialogRef<any>,
    private dadosService: DadosService) { 
      this.baterias = data.baterias;
    }

  ngOnInit(): void {
  }


  criarBateria() {
    if (this.formularioBateria.valid) {
      this.enviando = true;
      this.dadosService.criarBateria(this.formularioBateria.value).then(bateria => {
        this.enviando = false;
        this.formularioBateria.reset();
        this.baterias.push(bateria);
        this.dialogRef.close(this.baterias);
      });
    }
  }

}
