import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators, FormGroupDirective, NgForm} from '@angular/forms';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DadosService } from 'src/app/servicos/dados.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Location } from '@angular/common';
import { Empresa } from 'src/app/modelos/modelos';

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss']
})
export class RegistrarComponent implements OnInit {
  erro = '';
  empresas: Empresa[] = [];
  matcher = new MyErrorStateMatcher();
  enviando = false;

  formulario: FormGroup = new FormGroup({
    email: new FormControl('', Validators.email),
    nome: new FormControl('', Validators.required),
    cpf: new FormControl('', Validators.minLength(11)),
    empresa: new FormControl('', Validators.required),
    senha: new FormControl('', Validators.required),
    senhaConfirm: new FormControl('', Validators.required),
  }, this.checkPasswords);
  
  @Input() error: string | null;

  constructor(public autenticador:AutenticaService, public router: Router, private _snackBar: MatSnackBar, public dadosService: DadosService, private location: Location) {}

  ngOnInit () {
    this.dadosService.listarEmpresas().subscribe(empresas => this.empresas = empresas);
  }

  registrar() {
    this.enviando = true;
    if (this.formulario.valid) {
      this.autenticador.registrar(this.formulario.value)
      .then(res => {
        this.router.navigate(['/entrar']);
      }, err => {
        this.erro = err.message;
        this._snackBar.open(this.erro, "Ok", {duration: 7000});
        this.enviando = false;
      })
    }
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('senha').value;
    let confirmPass = group.get('senhaConfirm').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  voltar() {
    this.location.back();
  }

}

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}