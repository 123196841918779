import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list"
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';

import { IMaskModule } from 'angular-imask';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { EntrarComponent } from './paginas/entrar/entrar.component';
import { RegistrarComponent } from './paginas/registrar/registrar.component';
import { PerfilUsuarioComponent, ExcluirPopUp, EditarPopUp } from './paginas/perfil-usuario/perfil-usuario.component';
import { AutenticaService } from './servicos/autentica.service';
import { ClienteComponent } from './paginas/cliente/cliente.component';
import { BarraComponent } from './partes/barra/barra.component';
import { PilotoComponent } from './paginas/piloto/piloto.component';
import { LogisticaComponent } from './paginas/logistica/logistica.component';
import { PermissoesComponent } from './paginas/permissoes/permissoes.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IdPipe } from './partes/pipes/id.pipe';
import { DebugComponent } from './paginas/debug/debug.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { EditarEmpresaComponent } from './paginas/perfil-usuario/editar-empresa.component';
import { ForgotPasswordComponent } from './paginas/entrar/forgot-password/forgot-password.component';
import { TalhaoDialogComponent } from './paginas/piloto/Dialogs/talhao-dialog/talhao-dialog/talhao-dialog.component';
import { DetalhesDialogComponent } from './paginas/piloto/Dialogs/talhao-dialog/talhao-dialog/detalhes-dialog/detalhes-dialog/detalhes-dialog.component';
import { DroneDialogComponent } from './paginas/logistica/Dialogs/drone-dialog/drone-dialog.component';
import { BateriaDialogComponent } from './paginas/logistica/Dialogs/bateria-dialog/bateria-dialog.component';
import { DispenserDialogComponent } from './paginas/logistica/Dialogs/dispenser-dialog/dispenser-dialog.component';
import { TalhaoUsuarioDialogComponent } from './paginas/perfil-usuario/Dialogs/talhao-usuario-dialog/talhao-usuario-dialog.component';
import { OperadorDialogComponent } from './paginas/logistica/Dialogs/operador-dialog/operador-dialog/operador-dialog.component';
import { ManutencaoDialogComponent } from './paginas/logistica/Dialogs/manutencao-dialog/manutencao-dialog.component';
import { MonitoramentoComponent } from './paginas/monitoramento/monitoramento.component';
import { DetalhamentoComponent } from './paginas/monitoramento/detalhamento/detalhamento.component';
import { DadosComponent } from './paginas/monitoramento/dados/dados.component';
import { CadastroManualDialogComponent } from './paginas/piloto/Dialogs/cadastro-manual-dialog/cadastro-manual-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    EntrarComponent,
    RegistrarComponent,
    PerfilUsuarioComponent,
    ClienteComponent,
    BarraComponent,
    PilotoComponent,
    LogisticaComponent,
    PermissoesComponent,
    ExcluirPopUp,
    DebugComponent,
    IdPipe,
    EditarPopUp,
    EditarEmpresaComponent,
    ForgotPasswordComponent,
    TalhaoDialogComponent,
    DetalhesDialogComponent,
    DroneDialogComponent,
    BateriaDialogComponent,
    DispenserDialogComponent,
    TalhaoUsuarioDialogComponent,
    OperadorDialogComponent,
    ManutencaoDialogComponent,
    MonitoramentoComponent,
    DetalhamentoComponent,
    DadosComponent,
    CadastroManualDialogComponent
  ],
  entryComponents: [
    TalhaoDialogComponent,
    DetalhesDialogComponent,
    DroneDialogComponent,
    BateriaDialogComponent,
    DispenserDialogComponent,
    TalhaoUsuarioDialogComponent,
    OperadorDialogComponent,
    ManutencaoDialogComponent,
    CadastroManualDialogComponent,
    DadosComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule, BrowserAnimationsModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase), AngularFirestoreModule, AngularFireStorageModule, AngularFireAuthModule,
    FormsModule, ReactiveFormsModule,
    MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatListModule, MatIconModule, MatInputModule, MatPasswordStrengthModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatSelectModule, MatSidenavModule, MatSortModule, MatSnackBarModule, MatTableModule, MatToolbarModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule,
    GoogleMapsModule, IMaskModule, MaterialFileInputModule, NgxChartsModule, MatMomentDateModule,
    MatStepperModule ,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AutenticaService, {provide: {MAT_DATE_LOCALE, LOCALE_ID}, useValue: 'pt-br'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
