<div class="corpo"> 
  <mat-card>
    <img src="assets/Logo-vazado.png" class="center" width="220">
    <mat-card-content>
      <form [formGroup]="formulario" (ngSubmit)="entrar()">
        <p>
          <mat-form-field>
            <input type="text" matInput placeholder="E-mail" formControlName="email">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field>
            <input type="password" matInput placeholder="Senha" formControlName="senha">
          </mat-form-field>
        </p>

        <div class="button">
          <button type="submit" mat-raised-button>Entrar</button>
        </div>
      </form>

    </mat-card-content>

    <p class="options"> Esqueceu sua senha? <a routerLink="/forgot-password">Mudar senha</a></p>
    <p class="options"> Não possui uma conta? <a routerLink="/registrar">Registre-se</a></p>
    
    

  </mat-card>
</div> 