import { DetalhesDialogComponent } from './Dialogs/talhao-dialog/talhao-dialog/detalhes-dialog/detalhes-dialog/detalhes-dialog.component';
import { TalhaoDialogComponent } from './Dialogs/talhao-dialog/talhao-dialog/talhao-dialog.component';
import { startWith, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DadosService } from 'src/app/servicos/dados.service';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { DadosUsuario, Fazenda, Talhao, Voo } from 'src/app/modelos/modelos';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExcluirPopUp } from '../perfil-usuario/perfil-usuario.component';
import { CamadaTexto } from '../cliente/cliente.component';
import pointOnFeature from '@turf/point-on-feature';
import centerOfMass from '@turf/center-of-mass';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';
import { Observable } from 'rxjs';
import { CadastroManualDialogComponent } from './Dialogs/cadastro-manual-dialog/cadastro-manual-dialog.component';

@Component({
  selector: 'app-piloto',
  templateUrl: './piloto.component.html',
  styleUrls: ['./piloto.component.scss']
})
export class PilotoComponent implements OnInit {
  controlaFazenda = new FormControl();
  controlaTalhao = new FormControl();
  enviando = false;
  usuario: DadosUsuario;
  fazendaSelecionada: Fazenda;
  flagAutoComplete: boolean = true;
  flagfazendaSelecionada: boolean = false;
  flagAutoComplete2: boolean = true;
  flagMensagemErro: boolean = false;
  talhaoSelecionado: Talhao;
  talhoes: Talhao[];
  voos: Voo[];
  carregando = true;
  vooConfirmando = null;
  pilotos: DadosUsuario[] = [];
  bounds = new google.maps.LatLngBounds();
  Number = Number;
  autoCompleteControl = new FormControl({value: '', disabled: false}, Validators.required);
  error: any;
  uploadResponse = { status: '', message: '' };

  mapa: google.maps.Map;
  display?: google.maps.LatLngLiteral;
  camadasTalhao: Record<string, google.maps.Polygon> = {};
  camadasTalhaoTexto: Record<string, CamadaTexto> = {};

  vooForm: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    arquivo: new FormControl('', Validators.required)
  });

  dadosVooForm: FormGroup = new FormGroup({
    nome: new FormControl({ value: '' }, Validators.required),
    piloto: new FormControl(''),
    operador: new FormControl(''),
    cultura: new FormControl(''),
    area: new FormControl(''),
    droneID: new FormControl({ value: '', disabled: true }),
    baterias: new FormControl({ value: '', disabled: true }),
    quant: new FormControl(''),
    doseRequisitada: new FormControl(''),
    horarioLiga: new FormControl({ value: '', disabled: true }),
    horarioVoo: new FormControl({ value: '', disabled: true }),
    horarioFim: new FormControl({ value: '', disabled: true }),
    temperaturaMedia: new FormControl({ value: ''}),
    umidade: new FormControl(),
    vento: new FormControl(),
    status: new FormControl(),
    tipoVoo: new FormControl(),
    tipoMissao: new FormControl(),
    observacoes: new FormControl(),
    relatosSeguranca: new FormControl()
  });

  talhaoForm: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    descri: new FormControl(''),
    arquivo: new FormControl('', Validators.required)
  });

  //filteredOptions: Observable<string[]>;
  filteredOptions: string[];
  filteredOptionsTalhao: string[];
  fazendas: string[] = [];
  farms: string[] = [];
  fazendasFiltradas: Observable<Fazenda[]>;

  constructor(private dadosService: DadosService, 
              private autenticaService: AutenticaService, 
              public dialog: MatDialog) { }

  ngOnInit(): void {

    /*
    this.filteredOptions = this.controlaFazenda.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)));*/

    /*
    this.autoCompleteControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    ).subscribe(autoFilter => {this.filteredOptions = autoFilter; });*/
    
    
    //this.autoCompleteControl.disable()
    /*
    this.autoCompleteControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    ).subscribe(autoFilter => {this.usuario.fazendas = autoFilter; });

    this.autoCompleteControl.valueChanges.subscribe(valor => this.usuario.fazendas = valor);*/

    this.autenticaService.usuarioAtual.then(u => {
      this.usuario = u;
      this.dadosService.verFazendas(u.fazendas).subscribe(
        fs => u.fazendas = fs
      );
      if (this.usuario.fazendas.length > 0) {
        //this.selecionaFazenda(this.usuario.fazendas[0]);
        this.mapa = new google.maps.Map(document.getElementById('mapaP'), {
          center: new google.maps.LatLng(-19.257753, 146.823688),
          zoom: 2,
          mapTypeId: 'satellite',
          disableDefaultUI: true
        });
        this.mapa.addListener('mousemove', pos => this.display = pos.latLng.toJSON());
      }
    });

    this.autenticaService.listarOperadores().subscribe(us => this.pilotos = us);

    this.controlaFazenda.valueChanges
    .pipe(
        startWith(''),
        map((value) => {
          if (this.usuario) {
            return this._filter(value.toString());
          }
        })
      ).subscribe((valor) => {
        this.filteredOptions = valor;
    });

    this.controlaTalhao.valueChanges
    .pipe(
        startWith(''),
        map((value) => {
          if (this.usuario) {
            return this._filter2(value.toString());
          }
        })
      ).subscribe((valor) => {
        this.filteredOptionsTalhao = valor;
    });

  }

  processarFazendas(fazendas) {
    this.fazendas = fazendas;
    this.fazendasFiltradas = this.controlaFazenda.valueChanges.pipe(startWith(''),
      map(value => this._filter3(this.fazendas, value))
    );
  }

  selecionaFazenda(fazenda: Fazenda) {
    this.flagMensagemErro = false;
    console.log(this.flagfazendaSelecionada);
    /*
    if (this.vooConfirmando !== null) {
      return;
    }*/
    this.flagfazendaSelecionada = true;
    this.fazendaSelecionada = fazenda;
    this.talhoes = [];
    this.camadaTalhoes(this.fazendaSelecionada);
    this.dadosService.listarTalhoes(fazenda).subscribe(t => {
      this.talhoes = t;
      if (this.talhoes.length > 0)
        this.selecionaTalhao(this.talhoes[0])
    });
  }

  pegarNome(o) {
    if (o) {
      return o.nome;
    }
    return "";
  }

  selecionaTalhao(talhao: Talhao) {
    /*
    if (this.vooConfirmando !== null) {
      return;
    }*/
    if (this.talhaoSelecionado && this.camadasTalhao[this.talhaoSelecionado.id]) {
      this.camadasTalhao[this.talhaoSelecionado.id].setOptions({ fillColor: '#11BB11' })
    }
    this.talhaoSelecionado = talhao;
    if (!this.talhaoSelecionado) {
      this.voos = [];
      return;
    }
    this.carregando = true;
    this.dadosService.listarVoos(talhao).subscribe(v => {
      this.voos = v;
      console.log(this.voos);
      this.carregando = false;
    });
    if (this.camadasTalhao[talhao.id]) {
      this.camadasTalhao[talhao.id].setOptions({ fillColor: '#FFBB11' })
    }
  }

  criarVoo() {
    if (this.vooForm.invalid)
      return;

    this.enviando = true;
    const arquivo = this.vooForm.get('arquivo').value.files[0];
    const formData = new FormData();
    formData.append('myfile', arquivo);
    let nome = new Date().getTime() + '';
    formData.append('filename', nome);
    this.dadosService.upload(formData).subscribe(
      (res) => this.uploadResponse = res,
      (err) => this.error = err,
      () => {
        this.uploadResponse = { status: '', message: '' };
        this.dadosService.download('https://aeroagro.ddns.net/arqs/' + nome + '.json').subscribe(o => {
          let url = 'https://aeroagro.ddns.net/arqs/' + nome + '.geojson';
          let v = {
            id: '',
            nome: this.vooForm.get('nome').value,
            piloto: this.usuario,
            operador: this.usuario,
            area: this.talhaoSelecionado.area,
            status: 'completada',
            tipoVoo: 'real',
            tipoMissao: 'cotésia',
            cultura: 'Cana',
            dadosConfirmados: false,
            arquivo: url
          }
          v = Object.assign(v, o);
          this.dadosService.criarVoo(this.talhaoSelecionado, v).then(a => {
            this.enviando = false;
            v.id = a;
            this.voos.push(v);
            this.questionarVoo(v);
            this.vooForm.reset();
            
            this.dialog.open(DetalhesDialogComponent,
              {data: {pilotos: this.pilotos, voos: this.voos, dadosVooForm: this.dadosVooForm, vooConfirmando: this.vooConfirmando},
               width: "1200px"})
          })
        });
      }
    );
  }

  tid (index, item) {
    if (!item) return null;
    return index.id;
  }

  questionarVoo(voo: Voo) {
    this.vooConfirmando = voo;
    this.dadosVooForm.patchValue(voo);
    this.dadosVooForm.patchValue({"piloto": voo.piloto.id, "operador": voo.operador.id});
  }

  confirmarVoo() {
    if (this.dadosVooForm.valid) {
      this.vooConfirmando = Object.assign(this.vooConfirmando, this.dadosVooForm.value);
      if (!this.vooConfirmando.piloto.id) {
        this.vooConfirmando.piloto = this.pilotos.filter(p => p.id === this.vooConfirmando.piloto)[0];
      }
      if (!this.vooConfirmando.operador.id) {
        this.vooConfirmando.operador = this.pilotos.filter(p => p.id === this.vooConfirmando.operador)[0];
      }
      this.vooConfirmando['dadosConfirmados'] = true;
      this.dadosService.atualizarVoo(this.vooConfirmando).then(a => {
        this.voos[this.voos.findIndex(v => v.id === this.vooConfirmando.id)] = this.vooConfirmando;
        this.vooConfirmando = null;
        this.dadosVooForm.reset();
      });
    }
  }


  criarTalhao() {
    if (this.talhaoForm.valid) {
      this.enviando = true;
      const arquivo = this.talhaoForm.get('arquivo').value.files[0];
      const leitor = new FileReader();
      let kml: Document;
      leitor.readAsText(arquivo);
      leitor.onloadend = () => {
        kml = new DOMParser().parseFromString(leitor.result.toString(), "text/xml");
        let coords = kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => new google.maps.LatLng(+t.split(',')[1], +t.split(',')[0]));
        let area = google.maps.geometry.spherical.computeArea(coords) / 10000;

        let novo = Object.assign({}, this.talhaoForm.value);
        this.dadosService.salvarArquivo('kmls/' + new Date().getTime() + '.kml', arquivo).then(url => {
          novo['arquivo'] = url;
          novo['area'] = area;
          this.dadosService.criarTalhao(this.fazendaSelecionada, novo).then(a => {
            this.enviando = false;
            this.talhaoForm.reset();
            novo.id = a;
            this.talhoes.push(novo);
            this.renderizaTalhao(novo, kml);
          }
          );
        });
      }
    }
  }

  private renderizaTalhao(t: Talhao, kml: Document) {
    let poligono = polygon([kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => [+t.split(',')[0], +t.split(',')[1]])]);
    poligono.geometry.coordinates[0].forEach(p => this.bounds.extend(new google.maps.LatLng(p[1], p[0])));
    this.mapa.fitBounds(this.bounds);
    let ponto = centerOfMass(poligono);
    if (!booleanPointInPolygon(ponto, poligono)) {
      ponto = pointOnFeature(poligono);
    }
    let novo = new google.maps.Polygon({
      paths: poligono.geometry.coordinates[0].map(p => new google.maps.LatLng(p[1], p[0])),
      strokeColor: '#008800',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#11BB11',
      fillOpacity: 0.35
    });
    novo.setMap(this.mapa);
    novo.addListener('click', a => { this.selecionaTalhao(t) });
    this.camadasTalhao[t.id] = novo;
    if (this.talhaoSelecionado && this.talhaoSelecionado.id == t.id) {
      this.camadasTalhao[this.talhaoSelecionado.id].setOptions({ fillColor: '#FFBB11' })
    }
    this.camadasTalhaoTexto[t.id] = (new CamadaTexto(this.mapa, "", new google.maps.LatLng(ponto.geometry.coordinates[1], ponto.geometry.coordinates[0])));
  }

  excluirVoo(voo: Voo) {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.dadosService.excluirVoo(voo).then(() => this.voos = this.voos.filter(v => v.id !== voo.id));
      }
    });
  }

  public camadaTalhoes(fazenda: Fazenda) {
    this.fazendaSelecionada = fazenda;
    this.talhaoSelecionado = null;
    Object.values(this.camadasTalhao).forEach(c => c.setMap(null));
    Object.values(this.camadasTalhaoTexto).forEach(c => c.setMap(null));
    this.dadosService.listarTalhoes(fazenda).subscribe(ts => {
      this.talhoes[fazenda.id] = ts;
      this.bounds = new google.maps.LatLngBounds();
      ts.forEach(t => {

        this.dadosService.download(t.arquivo, true).subscribe(d => {
          if (this.talhoes.find(a => a.id === t.id)) {
            let kml = new DOMParser().parseFromString(d, "text/xml");
            this.renderizaTalhao(t, kml);
          }
        });
      });
    });
  }



  excluirTalhao() {
    const dialogRef = this.dialog.open(ExcluirPopUp);
    const talhao = this.talhaoSelecionado;

    dialogRef.afterClosed().subscribe(confirmou => {
      if (confirmou) {
        this.carregando = true;
        this.dadosService.excluirTalhao(talhao).then(() => {
          this.talhoes = this.talhoes.filter(t => t.id !== talhao.id);
          this.selecionaTalhao(this.talhoes[0]);
          if (this.camadasTalhao[talhao.id]) {
            this.camadasTalhao[talhao.id].setMap(null);
          }
          if (this.camadasTalhaoTexto[talhao.id]) {
            this.camadasTalhaoTexto[talhao.id].setMap(null);
          }
          this.carregando = false;
        });
      }
    });
  }

  private _filter(value: string): any[] {
    this.flagAutoComplete = false;
    if(value == ""){
      this.flagAutoComplete = true;
      this.fazendaSelecionada = null;
      this.talhoes = null;
    }
    if(value != undefined && value.length != 0){
    const filterValue = value.toLowerCase();
    console.log(filterValue);
    return this.usuario.fazendas.filter(option => option.nome.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  private _filter2(value: string): any[] {
    this.flagAutoComplete2 = false;
    if(value == ""){
      this.flagAutoComplete2 = true;
    }
    if(value != undefined && value.length != 0){
    const filterValue = value.toLowerCase();
    return this.talhoes.filter(option => option.nome.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  showTalhaoDialog(): void{
    if(!this.fazendaSelecionada){
      this.flagMensagemErro = true;
      return
    }
    this.flagMensagemErro = false;
    this.dialog.open(TalhaoDialogComponent,
      {data: {fazendaSelecionada: this.fazendaSelecionada, talhaoSelecionado: this.talhaoSelecionado, talhoes: this.talhoes, mapa: this.mapa, bounds: this.bounds},
       width: "750px"})
  }

  showDetalhesDialog(): void{
    this.dialog.open(DetalhesDialogComponent,
      {data: {pilotos: this.pilotos, voos: this.voos, dadosVooForm: this.dadosVooForm, vooConfirmando: this.vooConfirmando},
       width: "1200px"})
  }

  showCadastroManualDialog(): void{
    this.dialog.open(CadastroManualDialogComponent,
      {data: {pilotos: this.pilotos, voos: this.voos, talhao: this.talhaoSelecionado, usuario: this.usuario},
       width: "1200px"}).afterClosed().subscribe(voos => {this.voos = voos ? voos : this.voos})
  }


  getOptionText(option) {
    console.log(this.filteredOptions)
    //return this.usuario.fazendas;
  }

  private _filter3(options, value: string): any[] {
    if (typeof value !== 'string') {
      return options;
    }
    const filterValue = value.toLowerCase();
    return options.filter(option => option.nome.toLowerCase().includes(filterValue));
  }

  limpaAutocompleteFazenda(){
    this.flagAutoComplete = true;
    this.fazendaSelecionada = null;
    this.talhoes = null;
  }

  limpaAutocompleteTalhao(){
    this.flagAutoComplete2 = true;
  }

}
