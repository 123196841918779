<div class="corpo">
  <mat-card>
    <mat-card-title>Mudar senha</mat-card-title>
    <mat-card-content>
      <form #senhaForm="ngForm" [formGroup]="formulario" (ngSubmit)="autenticaService.forgotPassword(passwordResetEmail.value)"> 
        <p class="text-center">Entre com seu endereço de email para trocar sua senha</p>
        <p>
          <mat-form-field>
            <input type="text" formControlName="email" matInput placeholder="Endereço de Email" #passwordResetEmail required>
          </mat-form-field>
        </p>
  
        <div class="button">
            <button mat-raised-button type="submit" [disabled]="senhaForm.invalid">Enviar</button>
        </div>
      </form>
    </mat-card-content>
    <div><button mat-button type="submit" routerLink="/entrar">Voltar</button></div>
  </mat-card>
</div>  