// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  /*firebase: {
    apiKey: "AIzaSyB4aVjBawB1MnaBV2swgC5pG8_acEoZa-M",
    authDomain: "sardrone20.firebaseapp.com",
    databaseURL: "https://sardrone20.firebaseio.com",
    projectId: "sardrone20",
    storageBucket: "sardrone20.appspot.com",
    messagingSenderId: "849555275313",
    appId: "1:849555275313:web:c3864a3a17302f76355f10",
    measurementId: "G-6GTF9B5RC9"
  }*/
  firebase: {
    apiKey: "AIzaSyD_cANRfTXyFFw34mhq6Ql9FaJZxRq7KPU",
    authDomain: "sardrone-dev.firebaseapp.com",
    databaseURL: "https://sardrone-dev.firebaseio.com",
    projectId: "sardrone-dev",
    storageBucket: "sardrone-dev.appspot.com",
    messagingSenderId: "883411083226",
    appId: "1:883411083226:web:eae8e37ab2ca497a69aae3",
    measurementId: "G-L0G7NQJ2TT"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
