import { AutenticaService } from './../../../../servicos/autentica.service';
import { MatSelectChange } from '@angular/material/select';
import { DadosUsuario, Fazenda } from './../../../../modelos/modelos';
import { DadosService } from './../../../../servicos/dados.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-talhao-usuario-dialog',
  templateUrl: './talhao-usuario-dialog.component.html',
  styleUrls: ['./talhao-usuario-dialog.component.scss']
})
export class TalhaoUsuarioDialogComponent implements OnInit {

  element: DadosUsuario;
  fazendas: Record<string, Fazenda[]> = {};

  constructor(@Inject(MAT_DIALOG_DATA) data: {usuario: DadosUsuario, fazendas:Record<string, Fazenda[]>},
    private dadosService: DadosService,
    private dialogRef: MatDialogRef<any>,
    private autenticador: AutenticaService
    ) {
      this.element = data.usuario,
      this.fazendas = data.fazendas
    }

ngOnInit(): void {
}

removerFazenda(u: DadosUsuario, fid: string) {
  u.fazendas = u.fazendas.filter(f => f.id !== fid);
  this.autenticador.atualizaUsuario(u);
}

fazendasNovas(usuario: DadosUsuario) {
  return [].concat.apply([], Object.values(this.fazendas)).filter(f => usuario.fazendas.find(fi => fi.id === f.id)===undefined).sort((a, b) => a.nome.localeCompare(b.nome))
}

adicionarFazenda(u: DadosUsuario, e: MatSelectChange) {
  let faz = [].concat.apply([], Object.values(this.fazendas)).find(f => f.id === e.value);
  if (!faz) return;
  u.fazendas.push(faz);
  this.autenticador.atualizaUsuario(u);
}


}
