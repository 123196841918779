<mat-card style="text-align: center;">
    <form [formGroup]="formularioManutencao" (ngSubmit)="atualizaDados()">
        <h2> Limites/Manutenção Drone modelo {{modelo}} </h2>
        <mat-form-field>
          <input type="number" matInput required placeholder="Limite de Voos" formControlName="limVoos">
        </mat-form-field>
        <mat-form-field>
          <input type="number" matInput required placeholder="Limite de Horas" formControlName="limHoras">
        </mat-form-field>
        <h2> Limites/Manutenção Hélice/Motor </h2>
        <mat-form-field>
          <input type="number" matInput required placeholder="Limite de Horas Hélice" formControlName="limHelice">
        </mat-form-field>
        <mat-form-field>
          <input type="number" matInput required placeholder="Limite de Horas Motor" formControlName="limMotor">
        </mat-form-field>
        <div>
            <button type="submit" mat-raised-button>Salvar</button>
        </div>
      </form>
  </mat-card>
