import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Fazenda, DadosUsuario, Talhao, Voo, Empresa, Drone } from 'src/app/modelos/modelos';
import { DadosService, numero } from 'src/app/servicos/dados.service';
import { AutenticaService } from 'src/app/servicos/autentica.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import pointOnFeature from '@turf/point-on-feature';
import centerOfMass from '@turf/center-of-mass';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as saveAs from 'file-saver';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSort } from '@angular/material/sort';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {

  voosDiasMes: number;
  mediaMes: number = 0;
  auxinit: number;
  dadosGlobal: {};
  public demo1TabIndex = 0;
  anosGlobal: {};
  dataSource: MatTableDataSource<Voo>;
  flagSelecao: boolean = true;
  flagGraficoEspelhado: boolean = false;
  @ViewChild(MatSort, { static: false }) sortVoo: MatSort;
  displayedColumns: string[] = ["fazenda", "talhao", "nome", "data"];
  icons: string[] = ['drone', 'wind', 'moisture', 'thermometer', 'sustainable', 'area'];
  meses =  ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET',  'OUT',  'NOV',  'DEZ']
  mapa: google.maps.Map;
  camada: google.maps.KmlLayer;
  display?: google.maps.LatLngLiteral;
  fazendas: Fazenda[];
  talhoes: Talhao[] = [];
  drones: Drone[] = [];
  droneSelecionado: Drone
  fazendaSelecionada: Fazenda;
  fazendasTotais: Fazenda[];
  talhoesTotais: Talhao[];
  voosTotais: Voo[];
  talhaoSelecionado: Talhao;
  vooSelecionado: Voo;
  vooFiltro: Voo;
  camadasTalhao: Record<string, google.maps.Polygon> = {};
  camadasTalhaoTexto: Record<string, CamadaTexto> = {};
  camadaTraje: google.maps.Data; //KmlLayer;
  usuario: DadosUsuario;
  carregando = true;
  progresso = { total: 0, fazendo: 0, feito: 0, pfeito: 0, pfazendo: 0 };
  dadosMes = [];
  dadosAno = [];
  dadosTorta = [];
  dadosTortaTotal = [];
  empresa: Empresa;
  datas: Date[] = [null, null];
  datasFiltro: Date[] = [null, null];
  datasFiltroGrafico: Date[] = [null, null];
  anoGlobal: string[];
  mesGlobal: string[];
  view: any[] = [900,400];
  coresBarra = {
    domain: ['#40a040', '#ADC737', '#C7B42C', '#ECEBEB']
  };
  coresPizza = {
    domain: ['#ADC737', '#40a040', '#C7B42C', '#AAAAAA']
  };
  enviando = false;
  vooForm: FormGroup = new FormGroup({
    comentarios: new FormControl('')
  });
  voosAValidar: Voo[] = []

  p = DadosUsuario.Permissoes;
  formulario: FormGroup = new FormGroup({
    dataInicio: new FormControl(),
    dataFim: new FormControl()
  });

  filtroData: FormGroup = new FormGroup({
    dataInicioFiltro: new FormControl(),
    dataFimFiltro: new FormControl()
  });

  filtroDataGrafico: FormGroup = new FormGroup({
    dataInicioFiltroGrafico: new FormControl(),
    dataFimFiltroGrafico: new FormControl()
  })

  formataNumero(a) {
    return a.toLocaleString(undefined, { maximumFractionDigits: 2 }) + " ha"
  }

  controlaFazenda = new FormControl();
  fazendasFiltradas: Observable<Fazenda[]>;
  controlaTalhao = new FormControl();
  talhoesFiltrados: Observable<Fazenda[]>;
  controlaValidacao = new FormControl();
  empresas: Empresa[] = [];
  voos: Voo[] =[];
  voosFiltrados: Voo[] =[];
  validados: string;
  n_validados: string;
  update$: Subject<any> = new Subject();

  constructor(private dadosService: DadosService, private autenticaService: AutenticaService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private _adapter: DateAdapter<any>) {


    this._adapter.setLocale('pt');
    this.icons.forEach(icon =>{
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/'+icon+'.svg')
      );
    })
  }

  ngOnInit(): void {

    console.log(this.meses)
    this.autenticaService.usuarioAtual.then(u => {
      this.usuario = u;
      if (this.usuario.permissoes.includes(DadosUsuario.Permissoes.Admin) || this.usuario.permissoes.includes(DadosUsuario.Permissoes.Gerente)) {
        this.dadosService.listarEmpresasCompletas().subscribe(empresas => { this.empresas = empresas; this.carregando = false });
      } else if(this.usuario.cpf == '39568595873'){
        this.dadosService.listarEmpresasCompletas().subscribe(empresas => { 
          this.empresas = empresas.filter(empresa => empresa.cnpj == '08006300000190'); 
          this.carregando = false 
        });
      } else {
        this.dadosService.verEmpresa(this.usuario.empresa.id).then(e => this.empresa = e);
        this.dadosService.verFazendasComVoos(this.usuario).subscribe(fazendas => this.processarFazendas(fazendas), err => console.log(err), () => this.processaDados());
      }
      this.dadosService.listarDrones().subscribe(drones =>{
        this.drones = drones;
      })
    });
  }

  processarFazendas(fazendas) {
    this.fazendas = fazendas;
    this.fazendasFiltradas = this.controlaFazenda.valueChanges.pipe(startWith(''),
      map(value => this._filter(this.fazendas, value))
    );
    this.dadosService.listarFazendas(this.empresas[0]).subscribe(fazendas =>{ 
      this.fazendasTotais = fazendas
        this.dadosService.listarTalhoes(this.fazendasTotais[0]).subscribe(talhoes =>{
        this.talhoesTotais = talhoes
          this.dadosService.listarVoos(this.talhoesTotais[0]).subscribe(voos => {
            this.voosTotais = voos
          })
        })
    })
  }

  filtraVoos(){
    console.log("Filtrando")
    this.voosFiltrados = this.voos
    if(this.controlaValidacao.value == "validados"){
      this.voosFiltrados = this.voosFiltrados.filter(voo => 
        voo.validado
      );
    } else if(this.controlaValidacao.value == "n_validados"){
      this.voosFiltrados = this.voosFiltrados.filter(voo => 
        !voo.validado
      );
    }
    if (this.datasFiltro[0] && this.datasFiltro[1]) {
      this.voosFiltrados = this.voosFiltrados.filter(voo => voo.horarioLiga <= this.datasFiltro[1].getTime() && voo.horarioLiga >= this.datasFiltro[0].getTime());
    }
    if(this.controlaFazenda.value){
      this.voosFiltrados = this.voosFiltrados.filter(voo => voo.talhao.fazenda == this.controlaFazenda.value);
      if(this.controlaTalhao.value){
        this.voosFiltrados = this.voosFiltrados.filter(voo => voo.talhao == this.controlaTalhao.value);
      }
    }
    else{
      this.talhoesFiltrados = this.controlaTalhao.valueChanges.pipe(startWith(''),
      map(value => this._filter(this.fazendas.map(f => f.talhoes).reduce((t, c) => t.concat(c), []), value))
      );
    }

    this.dataSource = new MatTableDataSource<Voo>(this.voosFiltrados)
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'fazenda') {
        return item.talhao.fazenda.nome;
      } else if(property === 'talhao') {
        return item.talhao.nome;
      }
        else if(property === 'nome'){
          return item.nome;
      }
        else if(property === 'data'){
          return item.horarioLiga;
        }
    };
    this.dataSource.sort = this.sortVoo;
  }  
  

  verEmpresa(empresa) {
    console.log(empresa);
    this.empresa = empresa;
    this.carregando = true;
    this.fazendas = [];
    this.dadosService.listarFazendas(this.empresa, true).subscribe(fazendas => this.processarFazendas(fazendas), err => console.log(err), () => this.processaDados());
  }

  private _filter(options, value: string): any[] {
    if (typeof value !== 'string') {
      return options;
    }
    const filterValue = value.toLowerCase();
    return options.filter(option => option.nome.toLowerCase().includes(filterValue));
  }

  pegarNome(o) {
    if (o) {
      return o.nome;
    }
    return "";
  }

  baixarCSV() {
    if (this.datas[0] && this.datas[1]) {
      let csv = `\uFEFFTalhão; Voo; Data; Hora; Área (ha); Embalagens Lançadas; Responsável; Temperatura; Vento; Umidade\n`;
      if (this.empresa.areaPriori && this.datas[0].getTime() - numero(this.empresa.dataInicio) < 1000 * 60 * 60 * 24 * 30) {
        csv += `A priori; A priori; ; ; ${numero(this.empresa.areaPriori).toLocaleString()}; ${numero(this.empresa.dosesPriori).toLocaleString()}; ; ; ; \n`
      }
      this.fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach(voo => {
        if (voo.horarioLiga > this.datas[0].valueOf() && voo.horarioLiga < this.datas[1].valueOf() + 1000 * 60 * 60 * 24) {
          csv += `"${talhao.nome}"; "${voo.nome}"; "${moment(voo.horarioVoo).format('L')}"; "${moment(voo.horarioVoo).format('LT')}"; ${numero(voo.area).toLocaleString()}; ${numero(voo.quant).toLocaleString()}; "${voo.piloto?.nome}"; ${numero(voo.temperaturaMedia).toLocaleString()}; "${voo.vento}"; "${voo.umidade}"\n`;
        }
      })));
      let arquivo = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      saveAs(arquivo, `voos_${this.datas[0].toISOString().substr(0, 7)}_${this.datas[1].toISOString().substr(0, 7)}.csv`)
    }
  }

  processaDados() {
    
    this.dadosMes = [];
    this.dadosAno = [];
    this.dadosTorta = [];
    this.dadosTortaTotal = [];
    /*if (this.fazendas.length > 0) {
      this.selecionaFazenda(this.fazendas[0]);
    }*/
    let auxinit = this.empresa.inicioCiclo

    this.demo1TabIndex = 1;
    this.controlaFazenda.reset()
    this.controlaTalhao.reset()
    this.talhoesFiltrados = this.controlaTalhao.valueChanges.pipe(startWith(''),
      map(value => this._filter(this.fazendas.map(f => f.talhoes).reduce((t, c) => t.concat(c), []), value))
    );
    
    this.carregando = false;
    let dados:any[] = [];
    this.voosAValidar = [];
    this.voos = [];
    console.log(this.fazendas);
    this.fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach(voo => {
        this.voos.push(voo)
        if (!voo.validado) {
          this.voosAValidar.push(voo);
        }
        if (voo.horarioLiga) {
          let aux = new Date(voo.horarioLiga);
          let mes: string; 

          if(auxinit <= aux.getDate() && auxinit != undefined && auxinit != null){
            console.log(aux)
            aux.setMonth(aux.getMonth()+1)
            mes = aux.toISOString().substr(0, 7);
            console.log(aux)
          } 
          else{
            mes = aux.toISOString().substr(0, 7);
          }

          let area = voo.area == null ? numero(talhao.area) : numero(voo.area);
          let quant = numero(voo.quant);

          if (!dados[mes]) {
            dados[mes] = [area, quant];
          } else {
            dados[mes] = [dados[mes][0] + area, dados[mes][1] + quant];
          }
        } else {
          console.log("não tem horario liga", voo);
        }
    })));
    console.log(this.voos);

    let c = 0;
    let dataInicio = new Date(this.empresa.dataInicio)

    if(this.empresa.quantidades && this.empresa.quantidades.length >= 1){
      this.empresa.quantidades.forEach(quantidade => {
        if(quantidade?.toString() == ""){
          quantidade = 0
        }
        if (dados[dataInicio.toISOString().substr(0, 7)]){
          dados[dataInicio.toISOString().substr(0, 7)] = [dados[dataInicio.toISOString().substr(0, 7)][0], dados[dataInicio.toISOString().substr(0, 7)][1], numero(quantidade)]
        }
        else{
          dados[dataInicio.toISOString().substr(0, 7)] = [0, 0, numero(quantidade)]
        }
        dataInicio.setMonth(dataInicio.getMonth() + 1)
  
      })
    }
    else{
      console.log("Entrou else")
      Object.keys(dados).sort().forEach(mes => {
        console.log(mes)
        dados[mes][2] = 0;
      })
    }

    let anos: any[] = []

    Object.keys(dados).sort().forEach(mes => {
      let ano = mes.substr(0,4)
      let area = dados[mes][0];
      let quant = dados[mes][1];
      let pend = dados[mes][2];
      console.log(pend)
      if (!anos[ano]) {
        anos[ano] = [area, quant, pend];
      } else {
        anos[ano] = [anos[ano][0] + area, anos[ano][1] + quant, anos[ano][2] + pend];
      }
    })
    console.log(anos)


    this.dadosGlobal = dados;
    this.anosGlobal = anos;
    this.dataSource = new MatTableDataSource<Voo>(this.voos);
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'fazenda') {
        return item.talhao.fazenda.nome;
      } else if(property === 'talhao') {
        return item.talhao.nome;
      }
        else if(property === 'nome'){
          return item.nome;
      }
        else if(property === 'data'){
          return item.horarioLiga;
        }
    };
    this.dataSource.sort = this.sortVoo;
    const meses = Object.keys(dados).sort();
    if (meses.length === 0) return;
    const dadosMes = [];
    const dadosAno = [];
    let quant = this.empresa?.quantidades ? numero(this.empresa.quantidades[0]) : 0;
    quant -= dados[meses[0]][0];
    quant = quant > 0 ? quant : 0;
    console.log(quant);
    console.log((dados[meses[0]][0]));
    let total = this.empresa?.quantidades?.reduce((a, b) => numero(a) + numero(b), 0);
    console.log(this.empresa.quantidades);
    total = total ? total : 0;
    let somaRealizado = Object.keys(dados).map(d => dados[d][0]).reduce((a, b) => a + b, 0) + numero(this.empresa?.areaPriori);
    somaRealizado = somaRealizado ? somaRealizado : 0;
    let diff = total - somaRealizado;
    diff = diff > 0 ? diff : 0;
    const dadosTortaTotal = [{
      "name": "Área pendente",
      "value": Math.round(((diff + Number.EPSILON) * 100) / 100),
    }, {
      "name": "Área sobrevoada",
      "value": Math.round(((somaRealizado + Number.EPSILON) * 100) / 100),
    },
    ]
    
    console.log(dados);
    meses.forEach(mes => {
     // console.log(mes.substr(5,7))
     // let nome = this.meses[parseInt(mes.substr(5,7))-1]
      let nome = this.meses[parseInt(mes.substr(5,7))-1]
      let ano = mes.substr(2,2)
      let data = (nome + "/" + ano);
      dadosMes.push({
        "name": data,
        "series": [
          {
            "name": "Área Sobrevoada",
            "value": Math.round((dados[mes][0] + Number.EPSILON) * 100) / 100
          },
          {
            "name": "Área Prevista",
            "value": Math.round((dados[mes][2] + Number.EPSILON) * 100) / 100
          }
        ],
      })
    });



    Object.keys(anos).sort().forEach(ano =>{
      dadosAno.push({
        "name": ano,
        "series": [
          {
            "name": "Área Sobrevoada",
            "value": Math.round((anos[ano][0] + Number.EPSILON) * 100) / 100
          },
          {
            "name": "Área Prevista",
            "value": Math.round((anos[ano][2] + Number.EPSILON) * 100) / 100
          }
        ],
      })
    })


    this.dadosMes = [...dadosMes];
    this.dadosAno = [...dadosAno];
    this.dadosTortaTotal = dadosTortaTotal;
    this.auxinit = auxinit

    //Calcula torta
    this.filtraDataGrafico(3)
    this.flagSelecao = true;


    //Carrega o mapa
    this.mapa = new google.maps.Map(document.getElementById('mapaG'), {
      center: new google.maps.LatLng(-19.257753, 146.823688),
      zoom: 2,
      mapTypeId: 'satellite'
    });
    this.mapa.addListener('mousemove', pos => this.display = pos.latLng.toJSON());
    this.mapa.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(document.getElementById('latlong'));
  }

  public selecionaData(i, dataEvento) {
    if (dataEvento?.value) {
      this.datas[i] = dataEvento.value.toDate();
    } else {
      this.datas[i] = null;
    }
    if (this.datas[0] && this.datas[1]) {
      this.recalculaTorta();
    }
  }

  filtraData(i: number, evento?) {
    if (evento?.value) {
      this.datasFiltro[i] = evento.value.toDate();
    } else {
      this.datasFiltro[i] = null;
    }
    if (i == 3){
      this.datasFiltro = [];
      this.filtroData.reset();
      this.filtraVoos()
    }
    if (this.datasFiltro[0] && this.datasFiltro[1]) {
      this.datasFiltro[1].setDate((this.datasFiltro[1].getDate()+1))
      this.filtraVoos()
      console.log(this.controlaFazenda.value)


      if(!this.controlaFazenda.value || this.controlaFazenda.value == undefined ){ 
        console.log("Redefinindo mapa")
      /*-------Coloca voos por data no mapa-------------------------------------------------------------*/
        Object.values(this.camadasTalhao).forEach(c => c.setMap(null));
        Object.values(this.camadasTalhaoTexto).forEach(c => c.setMap(null));
        if (this.camadaTraje) this.camadaTraje.setMap(null);
        let bounds = new google.maps.LatLngBounds();
        this.voos.filter(voo => voo.horarioLiga <= this.datasFiltro[1].getTime() && voo.horarioLiga >= this.datasFiltro[0].getTime()).forEach(v => {
          if (this.talhoes.find(talhao => v.talhao === talhao) === undefined) {
            let t = v.talhao
            this.dadosService.download(t.arquivo, true).subscribe(d => {
              let kml = new DOMParser().parseFromString(d, "text/xml");
              let poligono = polygon([kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => [+t.split(',')[0], +t.split(',')[1]])]);
              poligono.geometry.coordinates[0].forEach(p => bounds.extend(new google.maps.LatLng(p[1], p[0])));
              this.mapa.fitBounds(bounds);
              let ponto = centerOfMass(poligono);
              if (!booleanPointInPolygon(ponto, poligono)) {
                ponto = pointOnFeature(poligono);
              }
              let novo = new google.maps.Polygon({
                paths: poligono.geometry.coordinates[0].map(p => new google.maps.LatLng(p[1], p[0])),
                strokeColor: '#008800',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#111111',
                fillOpacity: 0.35
              });
              const o = t.voos;
              if (o.length > 0) {
                novo.setOptions({ fillColor: '#55FF55' });
                o.forEach(v => {
                  if (v.dadosConfirmados) {
                    novo.setOptions({ fillColor: '#ADC737' });
                  }
                });
              } else {
                novo.setOptions({ fillColor: '#FFBB11' });
              }
              novo.setMap(this.mapa);
              novo.addListener('click', a => { this.detalhaTalhao(t) });
              this.camadasTalhao[t.id] = novo;
              this.camadasTalhaoTexto[t.id] = (new CamadaTexto(this.mapa, '', new google.maps.LatLng(ponto.geometry.coordinates[1], ponto.geometry.coordinates[0])));
            });
          }
        })
        /*-------FIM Coloca voos por data no mapa-------------------------------------------------------------*/ 
      }
    }
  }

  filtraDataGrafico(i: number, evento?) {
    console.log("ta chamando");
    if (evento?.value) {
      this.datasFiltroGrafico[i] = evento.value.toDate();
      this.datas[i] = evento.value.toDate();
    } else {
      this.datasFiltroGrafico[i] = null;
      this.datas[i] = null;
    }
    if (this.datasFiltroGrafico[0] && this.datasFiltroGrafico[1]) {
      if(this.datasFiltroGrafico[1].getDate() > this.auxinit && this.auxinit){
        this.datasFiltroGrafico[1].setMonth(this.datasFiltroGrafico[1].getMonth()+1)
      }
      if(this.datasFiltroGrafico[0].getDate() < this.auxinit && this.auxinit){
        this.datasFiltroGrafico[0].setMonth(this.datasFiltroGrafico[0].getMonth()-1)
        this.datasFiltroGrafico[0].setDate(this.auxinit)
        this.datasFiltroGrafico[1].setDate(this.auxinit-1);
      }
      else if(this.datasFiltroGrafico[0].getDate() >= this.auxinit && this.auxinit){
        this.datasFiltroGrafico[0].setDate(this.auxinit)
        this.datasFiltroGrafico[1].setDate(this.auxinit-1);
      }
      else if(!this.auxinit || this.auxinit == undefined){
        this.datasFiltroGrafico[0].setDate(1)
        this.datasFiltroGrafico[1].setDate(28);
      }
      let month = this.datasFiltroGrafico[1].getMonth();
      this.datasFiltroGrafico[1].setMonth(month);
      let mesesString = [];
      this.recalculaTorta();
      this.flagSelecao = false;
      let mesEscolhido1 = this.datasFiltroGrafico[0].getMonth();
      let mesEscolhido2 = this.datasFiltroGrafico[1].getMonth();
      let anoEscolhido1 = this.datasFiltroGrafico[0].getFullYear();
      let anoEscolhido2 = this.datasFiltroGrafico[1].getFullYear();
      let dataEscolhida1 = mesEscolhido1 + anoEscolhido1;
      let dataEscolhida2 = mesEscolhido2 + anoEscolhido2;
      if(dataEscolhida1 == dataEscolhida2){
        this.flagGraficoEspelhado = true;
      }
      else{
        this.flagGraficoEspelhado = false;
      }
      console.log(this.flagGraficoEspelhado);
      Object.keys(this.dadosGlobal).sort().forEach(meses => {
        let ano = parseInt(meses.substr(0,4))
        let mes = parseInt(meses.substr(5,7))
        let data = new Date
        data.setFullYear(ano);
        data.setMonth(mes-1);
        console.log(data);
        console.log(this.datasFiltroGrafico[0]);
        console.log(this.datasFiltroGrafico[1]);
        if(data >= this.datasFiltroGrafico[0] && data <= this.datasFiltroGrafico[1]){
          mesesString.push(meses);
        }
      })

      let anos: any[] = []

      mesesString.sort().forEach(mes => {
        let ano = mes.substr(0,4)
        let area = this.dadosGlobal[mes][0];
        let quant = this.dadosGlobal[mes][1];
        let pend = parseInt(this.dadosGlobal[mes][2]);
        console.log(pend)
        if (!anos[ano]) {
          anos[ano] = [area, quant, pend];
        } else {
          anos[ano] = [anos[ano][0] + area, anos[ano][1] + quant, anos[ano][2] + pend];
        }
      })
      
      console.log(anos);

      this.dadosMes = [];
      this.dadosAno = [];
      
      mesesString.forEach(mes => {
        let nome = this.meses[parseInt(mes.substr(5,7))-1]
        this.dadosMes.push({
          "name": nome,
          "series": [
            {
              "name": "Área Sobrevoada",
              "value": Math.round((this.dadosGlobal[mes][0] + Number.EPSILON) * 100) / 100
            },
            {
              "name": "Área Prevista",
              "value": Math.round((parseInt(this.dadosGlobal[mes][2]) + Number.EPSILON) * 100) / 100
            }
          ],
        })
      })

      Object.keys(anos).sort().forEach(ano =>{
        this.dadosAno.push({
          "name": ano,
          "series": [
            {
              "name": "Área Sobrevoada",
              "value": Math.round((anos[ano][0] + Number.EPSILON) * 100) / 100
            },
            {
              "name": "Área Prevista",
              "value": Math.round((parseInt(anos[ano][2]) + Number.EPSILON) * 100) / 100
            }
          ],
        })
      })
      console.log(this.dadosMes);
      console.log(this.dadosAno);
    }
    if (i == 3){
      this.flagSelecao = true;
      this.datas[0] = new Date();
      this.datas[1] = new Date();
      if( this.datas[0].getDate() < this.auxinit && this.auxinit != undefined && this.auxinit != null){
        this.datas[0].setDate(this.auxinit);
        this.datas[0].setMonth(this.datas[0].getMonth()-1)
      }
      else if(this.auxinit != undefined && this.auxinit != null){
        this.datas[0].setDate(this.auxinit);
      }
      else{
        this.datas[0].setDate(1);
      }
      this.datas[0].setHours(0);
      this.datas[0].setMinutes(0);
      this.datas[0].setSeconds(1);
      this.recalculaTorta();
      this.datas = [];
      this.datasFiltroGrafico = [];
      this.filtroDataGrafico.reset();
      console.log("Entrou limpar")
      this.dadosMes = [];
      this.dadosAno = [];
      console.log(this.dadosGlobal)
      Object.keys(this.dadosGlobal).sort().forEach(mes => {
        let nome = this.meses[parseInt(mes.substr(5,7))-1]
        this.dadosMes.push({
          "name": nome,
          "series": [
            {
              "name": "Área Sobrevoada",
              "value": Math.round((this.dadosGlobal[mes][0] + Number.EPSILON) * 100) / 100
            },
            {
              "name": "Área Prevista",
              "value": Math.round((parseInt(this.dadosGlobal[mes][2]) + Number.EPSILON) * 100) / 100
            }
          ],
        })
      })

      Object.keys(this.anosGlobal).sort().forEach(ano =>{
        this.dadosAno.push({
          "name": ano,
          "series": [
            {
              "name": "Área Sobrevoada",
              "value": Math.round((this.anosGlobal[ano][0] + Number.EPSILON) * 100) / 100
            },
            {
              "name": "Área Prevista",
              "value": Math.round((parseInt(this.anosGlobal[ano][2]) + Number.EPSILON) * 100) / 100
            }
          ],
        })
      })
    }
  }


  private recalculaTorta() {
    this.voosDiasMes = 0
    this.mediaMes = 0;
    let realizado = 0;
    console.log(this.datas)
    this.fazendas.forEach(fazenda => fazenda.talhoes.forEach(talhao => talhao.voos.forEach(voo => {
      if (voo.horarioLiga > this.datas[0].valueOf() && voo.horarioLiga < this.datas[1].valueOf() + 1000 * 60 * 60 * 24) {
        realizado += numero(voo.area);
        this.voosDiasMes += 1;
      }
    })));
    let contratado = 0;
    if (this.empresa?.dataInicio && this.empresa.quantidades) {
      let i = diffMeses(new Date(this.empresa.dataInicio), this.datas[0]);
      i = i > 0 ? i : 0;
      if(this.datas[0].getDate() >= this.auxinit && this.auxinit){
        i++
      }
      let f = diffMeses(this.datas[1], new Date(this.empresa.dataFim));
      f = f > 0 ? f : 0;
      if(this.datas[1].getDate() >= this.auxinit && this.auxinit){
        f--
      }
      f = this.empresa.quantidades.length - f;
      while (i < f) {
        contratado += numero(this.empresa.quantidades[i]);
        i++;
      }
    }
    let diff = contratado - realizado;
    diff = diff > 0 ? diff : 0;
    this.mediaMes = this.voosDiasMes ? realizado/this.voosDiasMes : 0;
    this.dadosTorta = [{
      "name": "Área pendente",
      "value": Math.round(((diff + Number.EPSILON) * 100) / 100),
    }, {
      "name": "Área sobrevoada",
      "value": Math.round(((realizado + Number.EPSILON) * 100) / 100),
    },
    ]
  }

  public selecionaFazenda(fazenda: Fazenda, talhao?: Talhao, voo?: Voo, selecao?:boolean) {
    if (!selecao){
      this.controlaFazenda.setValue(fazenda);
      this.talhoesFiltrados = this.controlaTalhao.valueChanges.pipe(startWith(''),map(value => this._filter(fazenda.talhoes.reduce((t, c) => t.concat(c), []), value)));
    }
    this.filtraVoos()
    this.fazendaSelecionada = fazenda;
    this.talhaoSelecionado = null;
    this.controlaTalhao.reset()
    Object.values(this.camadasTalhao).forEach(c => c.setMap(null));
    Object.values(this.camadasTalhaoTexto).forEach(c => c.setMap(null));
    if (this.camadaTraje) this.camadaTraje.setMap(null);
    let bounds = new google.maps.LatLngBounds();
    this.fazendaSelecionada.talhoes.forEach(t => {
      this.dadosService.download(t.arquivo, true).subscribe(d => {
        let kml = new DOMParser().parseFromString(d, "text/xml");
        let poligono = polygon([kml.getElementsByTagName("coordinates")[0].textContent.trim().split(' ').map(t => [+t.split(',')[0], +t.split(',')[1]])]);
        poligono.geometry.coordinates[0].forEach(p => bounds.extend(new google.maps.LatLng(p[1], p[0])));
        this.mapa.fitBounds(bounds);
        let ponto = centerOfMass(poligono);
        if (!booleanPointInPolygon(ponto, poligono)) {
          ponto = pointOnFeature(poligono);
        }
        let novo = new google.maps.Polygon({
          paths: poligono.geometry.coordinates[0].map(p => new google.maps.LatLng(p[1], p[0])),
          strokeColor: '#008800',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#111111',
          fillOpacity: 0.35
        });
        const o = t.voos;
        this.progresso_();
        if (o.length > 0) {
          novo.setOptions({ fillColor: '#55FF55' });
          o.forEach(v => {
            if (v.dadosConfirmados) {
              novo.setOptions({ fillColor: '#ADC737' });
            }
          });
        } else {
          novo.setOptions({ fillColor: '#FFBB11' });
        }
        novo.setMap(this.mapa);
        novo.addListener('click', a => { this.detalhaTalhao(t) });
        this.camadasTalhao[t.id] = novo;
        this.camadasTalhaoTexto[t.id] = (new CamadaTexto(this.mapa, t.nome, new google.maps.LatLng(ponto.geometry.coordinates[1], ponto.geometry.coordinates[0])));
        if (talhao && selecao) {
          this.detalhaTalhao(talhao, voo, true);
        }
        else if(talhao){
          this.detalhaTalhao(talhao, voo);
        }
      });
    });

    if (!selecao){
      this.filtraVoos()
    }

  }

  progresso_() {
    let total = 0;
    let fazendo = 0;
    let feito = 0;
    if (this.fazendaSelecionada?.talhoes) {
      this.fazendaSelecionada.talhoes.forEach(t => {
        if (t.voos && t.voos.length > 0) {
          total += t.voos[0].area ? numero(t.voos[0].area) : 0;
          t.voos.forEach(v => {
            if (v.dadosConfirmados) {
              feito += v.area ? numero(v.area) : 0;
            }
            fazendo += v.area ? numero(v.area) : 0;
          });
        } else {
          total += t.area ? numero(t.area) : 0;
        }
      });
    }
    this.progresso = { total: total, fazendo: fazendo, feito: feito, pfeito: feito / total * 100, pfazendo: fazendo / total * 100 };
  }

  detalhaTalhao(talhao: Talhao, voo?: Voo, selecao?:boolean) {
    if (this.fazendaSelecionada !== talhao.fazenda) {
      if (selecao){
        this.selecionaFazenda(talhao.fazenda, talhao, voo, true);
      } else{
        this.selecionaFazenda(talhao.fazenda, talhao, voo);
      }
    } else {
      if (!selecao){
        this.controlaTalhao.setValue(talhao);
      }
      this.talhaoSelecionado = talhao;
      Object.values(this.camadasTalhao).forEach(c => { if (c !== this.camadasTalhao[talhao.id]) c.setMap(null) });
      Object.values(this.camadasTalhaoTexto).forEach(c => c.setMap(null));
      this.camadasTalhao[talhao.id].setMap(this.mapa);
      let bound = new google.maps.LatLngBounds();
      this.camadasTalhao[talhao.id].getPath().forEach(p => bound.extend(p));
      this.mapa.fitBounds(bound);
      if (this.camadaTraje) {
        this.camadaTraje.setMap(null);
      }
      if (voo) {
        this.vooSelecionado = voo;
      } else {
        this.vooSelecionado = talhao.voos[0];
      }
      if (talhao.voos.length > 0) {
        this.camadaTraje = new google.maps.Data({
          map: this.mapa
        });
        this.camadaTraje.setStyle({
          icon: { url: 'https://maps.google.com/mapfiles/kml/shapes/placemark_circle.png', size: new google.maps.Size(32, 32), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(16, 16) }
        });
        if (this.vooSelecionado.arquivo?.includes('www')){
          this.vooSelecionado.arquivo = this.vooSelecionado.arquivo.replace('www', 'drones')
        }
        this.camadaTraje.loadGeoJson(this.vooSelecionado.arquivo);
      }
    }
    if (!selecao){
      this.filtraVoos()
    }

  }

  
  selecionaVoo(voo: Voo) {
    this.droneSelecionado = this.drones.find(drone => drone.sn == voo.droneID);
    console.log(this.droneSelecionado)
    if (this.talhaoSelecionado === voo.talhao) {
      this.vooSelecionado = voo;
      if (this.vooSelecionado.arquivo.includes('www')){
        this.vooSelecionado.arquivo = this.vooSelecionado.arquivo.replace('www', 'drones')
      }
      this.camadaTraje.loadGeoJson(this.vooSelecionado.arquivo);
    } else {
      this.detalhaTalhao(voo.talhao, voo, true);
    }
  }

  validarVoo() {
    this.enviando = true;
    let voo = this.vooSelecionado;
    this.dadosService.validarVoo(voo, this.vooForm.get('comentarios').value).then(() => {
      this.enviando = false;
      this.vooForm.reset();
      this.voosAValidar.filter(v => v.id !== voo.id)
    });
  }

}


export class CamadaTexto extends google.maps.OverlayView {

  div: HTMLDivElement;

  constructor(private mapa: google.maps.Map, private texto: string, private local: google.maps.LatLng) {
    super();
    this.setMap(mapa);
    this.div = null;
  }

  onAdd() {
    this.div = document.createElement('div');
    this.div.innerText = this.texto;
    this.div.style.borderStyle = 'none';
    this.div.style.borderWidth = '0px';
    this.div.style.position = 'absolute';
    this.div.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
    this.div.style.fontSize = '1.7em';

    this.getPanes().markerLayer.appendChild(this.div);
  }

  draw() {
    let p = this.getProjection().fromLatLngToDivPixel(this.local);
    this.div.style.left = p.x - this.div.clientWidth / 2 + 'px';
    this.div.style.top = p.y - this.div.clientHeight / 2 + 'px';
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }
}

export function diffMeses(inicio: Date, fim: Date) {
  return fim.getMonth() - inicio.getMonth() + (12 * (fim.getFullYear() - inicio.getFullYear()));
}
