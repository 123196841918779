
export interface DadosUsuario {
  id: string;
  email: string;
  nome: string;
  apelido: string;
  cpf: string;
  empresa: Empresa;
  fazendas: Fazenda[];
  permissoes: DadosUsuario.Permissoes[];
  horasIniciais?: number;
  numeroPiloto?: number;
  area?: string;
  voosIndividuais?: Voo[];
  numeroVoos?: number;
}

export interface Empresa {
  id: string,
  nome: string,
  grupo?: string,
  cnpj?: string,
  endereco?: string,
  responsavel?: string,
  culturas?: string,
  telefone?: string,
  area?: number,
  areaTipo?: string,
  safra?: string,
  doses?: number,
  valor?: string,
  logo?: string,
  dataInicio?: number,
  dataFim?: number,
  quantidades?: number[],
  fazendas?: Fazenda[],
  contato?: string,
  telefoneDireto?: string,
  areaPriori?: number,
  dosesPriori?: string,
  inicioCiclo?: number,
}

export interface Fazenda {
  id: string,
  nome: string,
  descri: string,
  dosesContratadas?: number,
  talhoes?: Talhao[],
  empresa?: Empresa;
}

export interface Talhao {
  id: string,
  nome: string,
  descri: string,
  arquivo: string,
  area?: number,
  voos?: Voo[],
  fazenda?: Fazenda,
}

export interface Voo {
  id: string,
  nome: string,
  arquivo: string
  descri?: string,
  piloto?: DadosUsuario,
  area?: number,
  operador?: DadosUsuario,
  status?: string,
  tipoVoo?: string,
  tipoMissao?: string,
  cultura?: string,
  dadosConfirmados?: boolean,
  droneID?: string,
  droneTipo?: string,
  baterias?: Bateria[],
  quant?: number,
  doseRequisitada?: number,
  horarioLiga?: number,
  horarioVoo?: number,
  horarioFim?: number,
  observacoes?: string,
  temperaturaMedia?: number,
  umidade?: number,
  vento?: string,
  relatosSeguranca?: string,
  validado?: boolean,
  comentarios?: string,
  talhao?: Talhao
}

export interface Drone {
  id: string,
  sn: string,
  nome?: string,
  modelo: string,
  horasIniciais?: number,
  limiteVoos?: number,
  limiteHoras?: number,
  observacoes?: string,
  limiteVoosHelice?: number
  limiteVoosMotor?: number
}

export interface LimitesDrone {
  limHoras: number,
  limVoos: number,
  limHelice: number,
  limMotor: number,
  modelo: string
}

export interface LimitesBateria {
  limVoosBateria: number
}

export interface Dispenser {
  id: string,
  modelo: string,
  doses: number,
  observacoes?: string
}

export interface Bateria {
  id: string,
  soh?: number,
  ultimoHorarioVoo?: number,
  sn?: string,
  modelo?: string,
  ciclosIniciais?: number,
  observacoes?: string
}

export interface Notificacao {
  id: string,
  data: Date,
  titulo: string,
  mensagem: string,
  icone: string,
  status: string
}

export namespace DadosUsuario {
  export enum Permissoes {
    Monitoramento,
    Admin,
    Gerente,
    Piloto,
    Cliente,
    Observador
  }

  export const PermissoesArray = Object.keys(Permissoes).filter(a => isNaN(Number(a)));

}
