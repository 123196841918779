  <mat-card>
    <form [formGroup]="talhaoForm" (ngSubmit)="criarTalhao()">
        <h1 class="titulo"> Cadastro do Talhão </h1>
        <mat-form-field>
        <input type="text" matInput required placeholder="Nome do Talhão" formControlName="nome">
        </mat-form-field>
        <mat-form-field>
        <input type="text" matInput placeholder="Descrição" formControlName="descri">
        </mat-form-field>
        <mat-form-field>
        <ngx-mat-file-input formControlName="arquivo" placeholder="Arquivo KML" accept=".kml"></ngx-mat-file-input>
        <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
        <div class="button">
        <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Talhao</button>
        </div>
    </form>
  </mat-card>
