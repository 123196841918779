import { LimitesDrone } from './../../../../modelos/modelos';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DadosService } from 'src/app/servicos/dados.service';

@Component({
  selector: 'app-manutencao-dialog',
  templateUrl: './manutencao-dialog.component.html',
  styleUrls: ['./manutencao-dialog.component.scss']
})
export class ManutencaoDialogComponent implements OnInit {

  modelo: string

  limites: LimitesDrone = {
    limHoras: 0, 
    limVoos: 0,
    limHelice: 0,
    limMotor: 0,
    modelo: ''
  }

  limitesD: LimitesDrone


  formularioManutencao: FormGroup = new FormGroup({
    limHoras: new FormControl('', Validators.required),
    limVoos: new FormControl('', Validators.required),
    limHelice: new FormControl('', Validators.required),
    limMotor: new FormControl('', Validators.required),
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {limites: LimitesDrone},
  private dialogRef: MatDialogRef<any>,
  private dadosService: DadosService) { 
    this.modelo = data.limites.modelo;
    this.limitesD = data.limites
    console.log(this.limites)
  }

  ngOnInit(): void {
    if(this.limitesD){
      this.formularioManutencao.patchValue(this.limitesD);
    }
  }

  atualizaDados(){
    console.log(this.formularioManutencao.get('limHoras').value)
    this.limites.limHoras = this.formularioManutencao.get('limHoras').value
    this.limites.limVoos = this.formularioManutencao.get('limVoos').value
    this.limites.limHelice = this.formularioManutencao.get('limHelice').value
    this.limites.limMotor = this.formularioManutencao.get('limMotor').value
    this.limites.modelo = this.modelo
    this.dadosService.criarLimitesDrone(this.limites).then(() =>
      this.dialogRef.close(this.limites)
    )
  }

}
