<mat-card>
  <div style="text-align: center;" *ngIf="usuario?.permissoes.includes(p.Admin) || usuario?.permissoes.includes(p.Gerente) || usuario?.cpf == '39568595873'">
    <mat-form-field>
      <mat-label>Empresa</mat-label>
      <mat-select (selectionChange)="verEmpresa($event.value)">
        <mat-option *ngFor="let e of empresas" [value]="e">{{ e.nome }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="imagemNaoCarregada" *ngIf="!empresa"></div>
  <img class="imagem-fundo" *ngIf="empresa?.logo" [src]="empresa.logo">
  <mat-spinner *ngIf="carregando" style="margin: auto;"></mat-spinner>

  <mat-tab-group [(selectedIndex)]="demo1TabIndex" *ngIf="empresa">
    <mat-tab label="Histórico">

      <mat-form-field class="example-full-width">
        <input type="text" placeholder="Fazenda" aria-label="Fazenda" matInput [formControl]="controlaFazenda"
          [matAutocomplete]="autoFazenda">
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete #autoFazenda="matAutocomplete" [displayWith]="pegarNome"
        (optionSelected)="selecionaFazenda($event.option.value)">
          <mat-option>Nenhuma</mat-option>
          <mat-option *ngFor="let fazenda of fazendasFiltradas | async" [value]="fazenda">
            {{fazenda.nome}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <input type="text" placeholder="Identificação" aria-label="Identificação" matInput [formControl]="controlaTalhao"
          [matAutocomplete]="autoTalhao">
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete #autoTalhao="matAutocomplete" [displayWith]="pegarNome"
        (optionSelected)="detalhaTalhao($event.option.value)">
          <mat-option>Nenhum</mat-option>
          <mat-option *ngFor="let talhao of talhoesFiltrados | async" [value]="talhao">
            {{talhao.nome}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="example-full-width" [formGroup]="filtroData">
        <mat-label>Data</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="dataInicioFiltro" placeholder="Data Inicial" (dateChange)="filtraData(0, $event)">>
          <input matEndDate formControlName="dataFimFiltro" placeholder="Data Final" (dateChange)="filtraData(1, $event)">>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="filtraData(3)">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="filtroData.controls.dataInicioFiltro.hasError('matStartDateInvalid')">Data inicial inválida
        </mat-error>
        <mat-error *ngIf="filtroData.controls.dataFimFiltro.hasError('matEndDateInvalid')">Data final inválida</mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width">
        <mat-label>Validação</mat-label>
        <mat-select [formControl] = "controlaValidacao" (selectionChange)="filtraVoos()">
          <mat-option>Nenhuma</mat-option>
          <mat-option value="validados">Validados</mat-option>
          <mat-option value="n_validados">Não Validados</mat-option>
        </mat-select>
      </mat-form-field>
      

      <div class="tabela-container mat-elevation-z8">
        <table mat-table [dataSource]="this.dataSource" matSort>
      
          <ng-container matColumnDef="fazenda">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Fazenda</b></th>
            <td mat-cell *matCellDef="let voos">{{voos.talhao.fazenda.nome}}</td>
          </ng-container>

          <ng-container matColumnDef="talhao">
              <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Identificação</b></th>
              <td mat-cell *matCellDef="let voos">{{voos.talhao.nome}}</td>
          </ng-container>
          
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Voo</b></th>
            <td mat-cell *matCellDef="let voos">{{voos.nome}}</td>
          </ng-container>
        
          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>Data</b></th>
            <td mat-cell *matCellDef="let voos">{{voos.horarioLiga | date:'dd/MM/yyyy'}}</td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
          <tr mat-row [ngClass]="{'changeColor': row == vooSelecionado}" (click)="selecionaVoo(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

      <br/>
      <div *ngIf="fazendaSelecionada">
        <div *ngIf="progresso.total">
          <mat-progress-bar mode='buffer' [value]="progresso.pfeito" [bufferValue]="progresso.pfazendo">
          </mat-progress-bar>
          {{progresso.fazendo | number:'1.0-2'}} ha realizados de um total de {{progresso.total| number:'1.0-2'}} ha sendo
          {{progresso.feito | number:'1.0-2'}} validados na fazenda {{fazendaSelecionada.nome}}.
        </div>
      </div>
      <div *ngIf="talhaoSelecionado">
        <!-- <button mat-raised-button *ngFor="let voo of talhaoSelecionado.voos" (click)="selecionaVoo(voo)"
          [color]="vooSelecionado?.id===voo.id?'primary':''">{{voo.nome}}</button> -->
        <div *ngIf="vooSelecionado" style="overflow-x: hidden !important;">
          <mat-chip-list aria-label="Detalhes do Voo">
            <mat-chip *ngIf="vooSelecionado.horarioLiga"><mat-icon>date_range</mat-icon> {{vooSelecionado.horarioLiga | date:'dd/MM/yyyy'}}</mat-chip>
            <mat-chip *ngIf="vooSelecionado.area"><mat-icon svgIcon="area"></mat-icon> &nbsp; {{vooSelecionado.area}} ha</mat-chip>
            <mat-chip *ngIf="vooSelecionado.quant"><mat-icon svgIcon="sustainable"></mat-icon> &nbsp; {{vooSelecionado.quant}}</mat-chip>
            <mat-chip *ngIf="vooSelecionado.piloto?.nome"><mat-icon>person</mat-icon>{{vooSelecionado.piloto?.nome }}</mat-chip>
            <mat-chip *ngIf="vooSelecionado.temperaturaMedia"><mat-icon svgIcon="thermometer"></mat-icon> {{vooSelecionado.temperaturaMedia}} °C</mat-chip>
            <mat-chip *ngIf="vooSelecionado.umidade"><mat-icon svgIcon="moisture"></mat-icon> &nbsp; {{vooSelecionado.umidade}} UR</mat-chip>
            <mat-chip *ngIf="vooSelecionado.vento"><mat-icon svgIcon="wind"></mat-icon> &nbsp; {{vooSelecionado.vento}} m/s</mat-chip>
            <mat-chip *ngIf="vooSelecionado.horarioVoo"><mat-icon>timer</mat-icon> &nbsp;{{(vooSelecionado.horarioFim - vooSelecionado.horarioVoo)/60000 | number:'1.0-0'}} min</mat-chip>
            <mat-chip *ngIf="vooSelecionado.droneID"><mat-icon svgIcon="drone"></mat-icon> &nbsp; {{droneSelecionado.nome}}</mat-chip>
          </mat-chip-list>
          <p *ngIf="vooSelecionado.comentarios">{{vooSelecionado.comentarios}}</p>
          <form [formGroup]="vooForm" (ngSubmit)="validarVoo()" *ngIf="vooSelecionado && !vooSelecionado.validado">
            <mat-form-field style="width:100%; max-width:400px">
              <textarea matInput placeholder="Comentários" formControlName="comentarios"></textarea>
            </mat-form-field>
            <button type="submit" mat-raised-button [disabled]="enviando">Validar Voo</button>
          </form>

        </div>
      </div>
      <div id="mapaG"></div>
      <div id='latlong'>{{display?.lat| number:'1.0-8'}}, {{display?.lng| number:'1.0-8'}}</div>

       <!--kML
      Arquivo
      Nome
      Data requerida do Voo
      Dose recomendada-->

        <mat-card style="display: flex; flex-direction: row; flex-wrap: wrap; vertical-align: middle;">
          <div style="vertical-align: middle; display: flex;">Legenda:⠀<mat-icon>date_range</mat-icon>⠀Data,⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon svgIcon="area"></mat-icon>⠀Área, ⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon svgIcon="sustainable"></mat-icon>⠀Embalagens lançadas,⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon>person</mat-icon>Responsável,⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon svgIcon="thermometer"></mat-icon>Temperatura,⠀⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon svgIcon="moisture"></mat-icon>⠀Humidade,⠀⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon svgIcon="wind"></mat-icon>⠀Vento,⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon>timer</mat-icon>⠀Duração⠀</div>
          <div style="vertical-align: middle; display: flex;"><mat-icon svgIcon="drone"></mat-icon>⠀Drone⠀⠀</div>
        </mat-card> 
    </mat-tab>

    <mat-tab label="Gráficos">

      <mat-form-field [formGroup]="filtroDataGrafico">
        <mat-label>Data</mat-label>
        <mat-date-range-input [rangePicker]="picker1">
          <input matStartDate formControlName="dataInicioFiltroGrafico" placeholder="Data Inicial" (dateChange)="filtraDataGrafico(0, $event)">>
          <input matEndDate formControlName="dataFimFiltroGrafico" placeholder="Data Final" (dateChange)="filtraDataGrafico(1, $event)">>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="filtraDataGrafico(3)">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
        <mat-date-range-picker #picker1></mat-date-range-picker>
        <mat-error *ngIf="filtroDataGrafico.controls.dataInicioFiltroGrafico.hasError('matStartDateInvalid')">Data inicial inválida
        </mat-error>
        <mat-error *ngIf="filtroDataGrafico.controls.dataFimFiltroGrafico.hasError('matEndDateInvalid')">Data final inválida</mat-error>
      </mat-form-field>

      <!--<mat-form-field appearance="fill" [formGroup]="formulario">
        <mat-label>Seleção de voos</mat-label>
        <mat-date-range-input [rangePicker]="picker2">
          <input matStartDate formControlName="dataInicio" placeholder="Data Inicial"
            (dateChange)="selecionaData(0, $event)">
          <input matEndDate formControlName="dataFim" placeholder="Data Final" (dateChange)="selecionaData(1, $event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>
        <mat-error *ngIf="formulario.controls.dataInicio.hasError('matStartDateInvalid')">Data inicial inválida
        </mat-error>
        <mat-error *ngIf="formulario.controls.dataFim.hasError('matEndDateInvalid')">Data final inválida</mat-error>
      </mat-form-field>-->
      <button mat-raised-button (click)="baixarCSV()">Baixar CSV</button>

      <div *ngIf="empresa" class="fundo">
        <h1 class="tituloTorta" *ngIf="this.voosDiasMes">
          <b>Dias voados 
            <span *ngIf="flagSelecao">(mês)</span>
            <span *ngIf="!flagSelecao">(seleção)</span>
          : {{this.voosDiasMes}}</b><br/>
          <b>Média 
            <span *ngIf="flagSelecao">(mês)</span>
            <span *ngIf="!flagSelecao">(seleção)</span>
          : {{this.mediaMes | number:'1.2-2':'pt-br'}}ha/dia</b><br/><br/><br/>
        </h1>

        <h1 class="tituloTorta"><b>Total do Contrato</b></h1>
        <div *ngIf="dadosTorta" class="graficoTorta">
          <div #containerRef>
          <ngx-charts-advanced-pie-chart 
            [animations]="false" 
            [valueFormatting]="formataNumero" 
            [scheme]="coresPizza"
            [results]="dadosTortaTotal" 
            gradient="true"
            [view]="[containerRef.offsetWidth, 220]">
          </ngx-charts-advanced-pie-chart>
          </div>
        </div>

        <h1 class="tituloTorta"><b *ngIf="flagSelecao">Total do mês</b><b *ngIf="!flagSelecao">Total da seleção</b></h1>
        <div *ngIf="dadosTorta" class="graficoTorta">
          <div #containerRef>
          <ngx-charts-advanced-pie-chart 
            [animations]="false" 
            [valueFormatting]="formataNumero" 
            [scheme]="coresPizza"
            [results]="dadosTorta" 
            gradient="true"
            [view]="[containerRef.offsetWidth, 220]">
          </ngx-charts-advanced-pie-chart>
          </div>
        </div>
        
        <h1 class="titulo"><b>Área de voo por mês</b></h1>
        <div *ngIf="dadosMes" class="grafico">
          <div #containerRef class="spectre-line-chart">
            <ngx-charts-bar-vertical-2d 
              [results]="dadosMes" 
              xAxis="true" yAxis="true" 
              yAxisLabel="Quantidade (ha)"
              showYAxisLabel="true" 
              [scheme]="coresBarra" 
              [view]="[containerRef.offsetWidth, 400]"
              >
            </ngx-charts-bar-vertical-2d>
          </div>
        </div>
        <div class="legenda">
          <b class="colorLegenda">⠀</b>
          <b class="colorLegenda">⠀</b>
          <b class="colorLegenda">⠀</b>
          <b>⠀Área Sobrevoada ⠀</b>
          <b class="colorLegenda2">⠀</b>
          <b class="colorLegenda2">⠀</b>
          <b class="colorLegenda2">⠀</b>
          <b>⠀Área Prevista</b>
        </div>

        <!--<div *ngIf="dadosMes" style="width:100%;max-width:700px; height: 400px; float:left">
          <ngx-charts-bar-vertical-stacked
          [scheme]="coresBarra"
          [results]="dadosMes"
          legendTitle=""
          [xAxis]="true"
          [yAxis]="true"
          [legend]="true"
          [showYAxisLabel]="true"
          >
          </ngx-charts-bar-vertical-stacked>
        </div>-->
        <h1 class="titulo" *ngIf="!flagGraficoEspelhado"><b>Área de voo por ano</b></h1>
        <div *ngIf="dadosAno && !flagGraficoEspelhado" class="grafico">
          <div #containerRef class="spectre-line-chart">
          <ngx-charts-bar-vertical-2d 
            [results]="dadosAno" 
            xAxis="true" yAxis="true" 
            yAxisLabel="Quantidade (ha)"
            showYAxisLabel="true" 
            [scheme]="coresBarra" 
            [view]="[containerRef.offsetWidth, 400]"
            >
          </ngx-charts-bar-vertical-2d>
          </div>
        </div>
        <div class="legenda" *ngIf="!flagGraficoEspelhado">
          <b class="colorLegenda">⠀</b>
          <b class="colorLegenda">⠀</b>
          <b class="colorLegenda">⠀</b>
          <b>⠀Área Sobrevoada ⠀</b>
          <b class="colorLegenda2">⠀</b>
          <b class="colorLegenda2">⠀</b>
          <b class="colorLegenda2">⠀</b>
          <b>⠀Área Prevista</b>
        </div>

      </div>
     
    </mat-tab>
    
  </mat-tab-group>
   
</mat-card>