import { Component, OnInit, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DadosUsuario, Empresa, Fazenda, Talhao } from 'src/app/modelos/modelos';
import { DadosService } from 'src/app/servicos/dados.service';
import * as IMask from 'imask';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { combineLatest, zip } from 'rxjs';

import * as moment from 'moment';

@Component({
  selector: 'app-editar-empresa',
  templateUrl: './editar-empresa.component.html',
  styleUrls: ['./perfil-usuario.component.scss'],
})
export class EditarEmpresaComponent implements OnInit {

  @Input() empresa: Empresa = null;
  @Output() empresaNova = new EventEmitter<Empresa>();
  acao = "Salvar"
  enviando = false;
  mascaraCnpj = IMask.createMask({ mask: '00.000.000/0000-00' });
  Number = Number;

  formulario: FormGroup = new FormGroup({
    nome: new FormControl('', Validators.required),
    grupo: new FormControl(),
    cnpj: new FormControl('', Validators.minLength(14)),
    endereco: new FormControl(),
    responsavel: new FormControl(),
    culturas: new FormControl(),
    telefone: new FormControl(),
    contato: new FormControl(),
    telefoneDireto: new FormControl(),
    area: new FormControl(),
    areaTipo: new FormControl(),
    safra: new FormControl(),
    areaPriori: new FormControl(),
    dosesPriori: new FormControl(),
    doses: new FormControl(),
    valor: new FormControl(),
    logo: new FormControl(),
    quantidades: new FormArray([]),
    dataInicio: new FormControl(),
    dataFim: new FormControl(),
    inicioCiclo: new FormControl()
  });

  quantidades = this.formulario.get('quantidades') as FormArray;

  constructor(private dadosService: DadosService) {
  }

  ngOnInit(): void {
    combineLatest(this.formulario.get('dataInicio').valueChanges, this.formulario.get('dataFim').valueChanges).subscribe(([inicio, fim]) => {
      if (fim && inicio) {
        let quant = this.quantosMeses(inicio.toDate(), fim.toDate());
        while (this.quantidades.length !== quant) {
          if (this.quantidades.length > quant) {
            this.quantidades.removeAt(this.quantidades.length - 1);
          } else {
            this.quantidades.push(new FormControl());
          }
        }
      }
    });
    if (this.empresa) {
      const empresaEdit = { ...this.empresa, dataInicio: moment(this.empresa.dataInicio), dataFim: moment(this.empresa.dataFim) };
      if (!this.empresa.dataInicio) delete empresaEdit.dataInicio;
      if (!this.empresa.dataFim) delete empresaEdit.dataFim;
      empresaEdit.quantidades?.forEach(q => this.quantidades.push(new FormControl(q)));
      this.formulario.patchValue(empresaEdit);
    } else {
      this.acao = "Cadastrar";
    }
  }

  nomeMes(i: number) {
    if (!this.formulario.get('dataInicio').value)
      return '';
    const inicio = this.formulario.get('dataInicio').value.toDate() as Date;
    const data = new Date(inicio.getFullYear(), inicio.getMonth()+i);
    return maiusculo(data.toLocaleString('default', { month: 'long' }));
  }

  private quantosMeses(inicio: Date, fim: Date) {
    return fim.getMonth() - inicio.getMonth() + (12 * (fim.getFullYear() - inicio.getFullYear())) + 1;
  }

  criarEditarEmpresa() {
    if (this.formulario.valid) {
      this.enviando = true;
      let logo = this.formulario.get('logo').value?.files ? this.formulario.get('logo').value.files[0] : null;
      const empresaNova = this.formulario.value;
      if (empresaNova.dataInicio) empresaNova.dataInicio = empresaNova.dataInicio.valueOf();
      if (empresaNova.dataFim) empresaNova.dataFim = empresaNova.dataFim.valueOf();

      console.log(this.empresa, empresaNova);
      if (this.empresa) {
        empresaNova.id = this.empresa.id;
        if (!logo) empresaNova.logo = this.empresa.logo;
        this.dadosService.atualizarEmpresa(empresaNova, logo).then(a => {
          this.enviando = false;
          this.formulario.reset();
          this.empresaNova.emit(a);
        });
      } else {
        this.dadosService.criarEmpresa(empresaNova, logo).then(a => {
          this.enviando = false;
          this.formulario.reset();
          this.empresaNova.emit(a);
        });
      }
    }
  }

}


function maiusculo(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}