<div *ngIf="element.permissoes.includes(element.permissoes[2])">
    <div class="titulo">
    <h2>Fazendas sobrevoodas pelo piloto:</h2>
    </div>
    <div class="centro">
    <mat-form-field>
      <mat-select matNativeControl placeholder="Adicionar fazenda" (selectionChange)="adicionarFazenda(element, $event)">
        <mat-option *ngFor="let faz of fazendasNovas(element)" [value]="faz.id">{{ faz.nome }}</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <mat-chip-list>
      <mat-chip *ngFor="let fazenda of element.fazendas">
        {{fazenda.nome}} <mat-icon matChipRemove (click)="removerFazenda(element, fazenda.id)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
</div>
