<mat-card>
    <form [formGroup]="formularioDispenser" (ngSubmit)="criarDispenser()">
        <h1 class="titulo"> Cadastro de Dispenser </h1>
        <mat-form-field>
          <input type="text" matInput placeholder="Modelo" formControlName="modelo">
        </mat-form-field>
        <mat-form-field>
          <input type="text" matInput placeholder="Nº embalagens por voo" formControlName="doses" [imask]="{mask: Number, scale: 0}">
        </mat-form-field>
        <mat-form-field>
          <textarea matInput placeholder="Observações" formControlName="observacoes"></textarea>
        </mat-form-field>
        <div class="button">
          <button type="submit" mat-raised-button [disabled]="enviando">Cadastrar Dispenser</button>
        </div>
    </form>
</mat-card>
